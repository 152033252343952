import React, { Component } from 'react';
import '../../public/css/product.css'

// 언어설정을 props.lang 과 같이 바로 전달받지 못한다.
// 예전에는 NavLink를 통해서 props도 바로 전달 받았었는데... 이상하네.. 일단 주소줄에서 파싱해서 언어를 설정한다 . 

class HMI_NXD8925 extends React.Component {
  constructor(props) {
    super(props);
  }
    
  render() {
    const dstyle = {
      color: "white",
      border: '2px solid #3d52ad',
      backgroundColor: '#eee',
      // height: "1040px"  // 헤더의 높이가 차감된듯 
    }

    const h1style = {  color: "black", fontFamily: "Arial",   paddingLeft : "0px", textAlign: 'center', fontSize: "16px",fontStyle: 'italic', marginTop: '5px'  }

    const h2style = { color: "black",  fontFamily: "Arial",paddingLeft : "0px", textAlign: 'center', fontSize: "14px" }

    //주소줄로 부터 언어설정을 파싱..
    var language = this.props.location.pathname.split('/')[1];

    var prod_title = (language=='ko')? "교통카드 개집표기 / 레이턴트 / 리눅스QT 터미널  " : "Transportation Card reader / Linux QT terminal" ;
    var prod_subtitles = (language=='ko')? ["전면사진", "데모 영상"] : ["Front view", "Demo movie"]  ;
    var prod_use = (language=='ko')? 
    {
      title: "적용분야",
      desc: ["• 지하철/철도 교통카드 개집표기", "• 레이턴트", "• 교통카드 리더"]
    }:{
      title: "Application",
      desc: ["• ransportation Card reader", "• Latent", "• Industrial Linux Terminal"]
    }
    var prod_feature = (language=='ko')? 
    {
      title: "특장점",
      desc: ["• 지하철 교통카드 개집표기 터미널", "", ""]
    }:{
      title: "Key Features",
      desc: ["• Camping car monitoring, various control functionality", "• Multimedia (YouTube/Radio/Player/Mirroring)", "• Customizable AVN"]
    }
    var prod_title_specs = (language=='ko')? ["하드웨어 사양(HMI_NXD8925)", "소프트웨어 사양(HMI_NXD8925)"] : ["Hardware Specification(HMI_NXD8925)", "Software Specification(HMI_NXD8925)"]  ;

    return (
   
      <div className="prod_contents"  style={dstyle} >
        <br/>
        <div className="column">
          <div className="col_tab">
            <div className="col1">
              <h1 style={h1style}>{prod_title}</h1>
              <hr/>
              <h2 style={h2style}>{prod_subtitles[0]}</h2>
              <br/>
              <img border="0" src="/img/product/odm/nx8925-oem.png"  width="50%" />
            </div>

            <div className="col2">
              <h1 style={h1style}>{prod_title}</h1>
              <hr/>
              <h2 style={h2style}>{prod_subtitles[1]}</h2>
              <br/>
              {/* <img border="0" src="/img/product/emb/nxc3128_2.jpg"  width="70%" /> */}
          
            <video width="480" height="300" controls="controls" class="video-js vjs-default-skin" data-setup="{}" poster="/img/product/odm/nx8925-oem.png" >
              <source src="/mov/o1.mp4"  type="video/mp4" />
              </video>
            </div>
          </div>


          <div className="col3">
            <br/>

            <h1 style={h1style}>{prod_use.title}</h1>
            <br/>
            <center>
            <table border="0" cellpadding="0" cellspacing="0"   width ="60%">
                <tr bgcolor="#c8bfe">
                    <ul>
                    <li  style={{fontSize:"18px"}}>{prod_use.desc[0]}</li>
                    <li  style={{fontSize:"18px"}}>{prod_use.desc[1]}</li>
                    <li  style={{fontSize:"18px"}}>{prod_use.desc[2]} </li>
                    </ul>
                 </tr>
                
            </table>
            <br/>    
            <h1 style={h1style}>{prod_feature.title}</h1>
            <br/>    
            <table border="0" cellpadding="0" cellspacing="0"   width ="60%">
              <tr bgcolor="#c8bfe">
                  <ul>
                  <li  style={{fontSize:"18px"}}>{prod_feature.desc[0]}</li>
                  <li  style={{fontSize:"18px"}}>{prod_feature.desc[1]}</li>
                  <li  style={{fontSize:"18px"}}>{prod_feature.desc[2]} </li>
                  </ul>
                </tr>
              
            </table>
            <br/>    
     
            </center>
            <h1 style={h1style}>{prod_title_specs[0]}</h1>
            <br/>
            <center>
        
              <table border="1" cellpadding="0" cellspacing="0"   width ="60%">
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;<b>Name</b></td>
                    <td width="79%" bgcolor="#c8bfe">&nbsp;<b>Description</b></td>
                </tr>
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;CPU</td>
                    <td width="79%">&nbsp;ARM Cortex-A9 800MHz(TCC8925)</td>
                </tr>
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;Memory</td>
                    <td width="79%">&nbsp;16GB eMMC/ 2GB DDR4 DRAM </td>
                </tr>
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;Display</td>
                    <td width="79%">&nbsp;4.3 inch 480x272 24bit color</td>
                </tr>
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;Touch</td>
                    <td width="79%">&nbsp;Registitive touch</td>
                </tr>
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;Audio</td>
                    <td width="79%">&nbsp;N/A </td>
                </tr>
                <tr>
                    <td width="21%"  bgcolor="#c8bfe" rowspan="5">&nbsp;IO</td>
                    <td width="79%">&nbsp;USB Host 2.0/Function 2.0</td>
                </tr>
                <tr>
                    <td width="79%">&nbsp;SD 1 Slots</td>
                </tr>
                <tr>
                    <td width="79%">&nbsp;Serial 2 port (RS-485 1 port/ Debug 1 port)</td>
                </tr>
                <tr>
                    <td width="79%">&nbsp;13.56MHz NFC</td>
                </tr>
                <tr>
                    <td width="79%">&nbsp;External RTC with backup battery</td>
                </tr>
              </table>
            </center>

            <br/>
            <h1 style={h1style}>{prod_title_specs[1]}</h1>
            <br/>
            <center> 
            <table border="1" cellpadding="0" cellspacing="0" width ="60%">
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;<b>Name</b></td>
                    <td width="79%" bgcolor="#c8bfe">&nbsp;<b>Description</b></td>
                </tr>
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;OS</td>
                    <td width="79%">&nbsp;Linux QT 5.3.1 </td>
                </tr>
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;SDK</td>
                    <td width="79%">&nbsp; Custom SDK Support</td>
                </tr>
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;Upgrade</td>
                    <td width="79%">&nbsp; OS Upgrade</td>
                </tr>
                <tr>
                    <td width="21%" bgcolor="#c8bfe" rowspan="2">&nbsp;Device&nbsp;
                    <p/>&nbsp;</td>
                    <td width="79%">&nbsp; Display/Audio/NFC/</td>
                </tr>
                <tr>
                    <td width="79%">&nbsp; USB/SD/RTC/</td>
                </tr>
                <tr>
                    <td width="21%" bgcolor="#c8bfe" rowspan="5">&nbsp;Cutomizing</td> 
                  </tr>
                <tr>
                    <td width="79%">&nbsp; Booting Screen</td>
                </tr>
                <tr>
                    <td width="79%">&nbsp; Application Auto Lauching</td>
                </tr>
              </table>
            </center>
          </div>
          <div className="col4">
            
          </div>
          <div className="col5">
            
          </div>
          <div className="col6">
           
          </div>
          <div className="col7">
            
          </div>
        </div>
      </div>
    );
  }
}

export default HMI_NXD8925;
