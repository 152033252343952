import React, { Component } from 'react';
import { Map,InfoWindow, GoogleApiWrapper, Marker } from 'google-maps-react';

class GMap extends Component {


    constructor(props) {
        super(props);
        const { cookies } = props;
    
        this.state = {
            lang: props.lang,
            selectedPlace: {
                name : 'NEXFIELD SOLUTION Co.'
            }  
        }

        if(this.state.lang=='ko') this.state.selectedPlace.name = '(주)넥스필드솔루션';
       
        
      }
    
   
    render() {
        
            const mapStyles = {
                position: 'relative',  
                width: '99%',
                height: '99%'      
            };
        
      
          return (
           
            <Map
                  google={this.props.google} 
                  zoom={17}
                  style={mapStyles}
                  initialCenter={{ lat: 37.485415, lng: 126.969842}}
       
            >
     
            <Marker onClick={this.onMarkerClick}
                    name={this.state.selectedPlace.name} />
     
            <InfoWindow onClose={this.onInfoWindowClose}>
                <div>
                  <h1>{this.state.selectedPlace.name}</h1>
                </div>
            </InfoWindow>
          </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyCnh46XYs-zk2u5aXgEu3DZmuDO2mdOu5A"
    // language: props.language
  })(GMap)