import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
// import { CommonStyles } from "../../styles";

const HTMLUserNotFound = () => {
    // const { lang } = useParams();
    const history = useHistory();
  var lang = history.location.pathname.split('/')[1];

    var msg = (lang=='ko')? "존재하지 않는 페이지 입니다!!" : "Page Not Found!";
    return (
        <div style={{fontSize:"20px", height:"300px" , paddingTop: "50px"}}>
            <div style={{height:"200px" , width: "300px", border: "1px solid black",  margin: "0 auto" , paddingTop: "70px", backgroundColor: "#eee",  borderRadius: "50%" }}>
            <p style={{color: "black"}}>{msg}</p>
            <p style={{color: "red"}} >(ERROR: 404)</p>
            </div>
        </div>
    );
};

export default HTMLUserNotFound;