import React, { Component } from 'react';

class AdminManagementContent_Statistics extends Component {
    render() {
        return (
            <div>
                <h1>관리 및 통계 - 통계 화면 </h1>
            </div>
        );
    }
}

export default AdminManagementContent_Statistics;