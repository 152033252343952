import React,{useState} from 'react';
import {NavLink,Link} from 'react-router-dom'
import HTMLHeaderHomeBanner from './HTMLHeaderHomeBanner'
import HTMLHeaderSimpleBanner from './HTMLHeaderSimpleBanner'
import { useLocation } from 'react-router-dom'
import shortid from 'shortid';

import Button from '@material-ui/core/Button';



import DlgLogin from './common/DlgLogin';

import './common/board.css'


// function  ReloadPage  ()  {
//   let location = useLocation();
//   window.location.reload(false);

// }

const HeaderSubMenu = ({lang,menu,cbChangeScreen}) => {

  var items = menu.items;
  //
  var subIndex = 1; //현재는 부게시판이 없다.

  let brr = items.map((value,index) =>
        
          <li><Link  key= {index} to={'/'+ lang + value.url + '/'+ (index+1).toString() + '/'+ subIndex.toString() }   
                    lang={lang} onClick={()=>{cbChangeScreen( value.url, index+1, subIndex)}}  > {value.item}
          </Link></li>
           
    );
  return (
    <li className="menu_titles"><Link to="#" key={shortid.generate}>{menu.title}</Link>    
      <ul className="menu_contents">
        {brr}
      </ul>
  </li>
   );
 };
 

const  HTMLHeader = (props) =>
{
  var mainmenu_items = ['','','','',''];
  var mainmenu=[];

  var lang = props.lang, sel=props.sel,cbChangeLang=props.cbChangeLang,cbChangeScreen=props.cbChangeScreen;
  var loginLink, registerLink;
  var loginDesc, registerDesc;
  
  const [loginOpen, setLoginOpen] = useState(0);
  const [registerOpen, setRegisterOpen] = useState(0);
 
  const [userName, setUserName] = useState('');
  const [userPassword, setUserPassword] = useState('');

  const handleLogin = () => {
   
    setLoginOpen(1);
  }

  const handleRegister = () => {
    
    setRegisterOpen(1);
  }


  const handleOK = () => {
    console.log('handleOk');
    if(loginOpen)     setLoginOpen(0);
    if(registerOpen)  setRegisterOpen(0);
  }

  const handleCancel  = () => {

    if(loginOpen)     setLoginOpen(0);
    if(registerOpen)  setRegisterOpen(0);

}

const handleValueChange  = (e) => {

  // let nextState = {};
  //  nextState[e.target.name] = e.target.value;

  if(e.target.name=='user_writer') setUserName(e.target.value);
  else if(e.target.name=='user_passwd') setUserPassword(e.target.value);
 
  }


  var isValidContent = () => {
    // var valid = (this.state.writer && this.state.email && this.state.subject &&  this.state.content )? true : false;
    // var valid = (this.state.writer && this.state.email &&  this.state.content )? true : false;
    var valid = true;
    return valid;
  }
  //해당 상태가 변할때 랜더링 

  React.useEffect(() => {
    if (loginOpen )  console.log('Logged in');
    else              console.log('Logged out');
    if (registerOpen )  console.log('Register in');
    else              console.log('Register out');
   
  }, [loginOpen,registerOpen]);
  

  if(lang==='en') {
      mainmenu= [
        {title:'Company',items:[{item:'Greetings',url:'/company'},{item:'Business',url:'/company'},{item:'Contact US',url:'/company'}, {item:'Activity',url:'/support'}]},
    //    {title:'Product',items:[ {item:'HMI',url:'/product'},{item:'Embedded',url:'/product'},{item:'SOM Product',url:'/product'}]},
        {title:'Product',items:[ {item:'ODM Product',url:'/product'},{item:'SOM Product',url:'/product'}]},
        {title:'ODM',items:[ {item:'ODM History',url:'/odm'},{item:'ODM Platform',url:'/odm'},{item:'ODM Procedure',url:'/odm'}]},
        // {title:'ODM',items:[ {item:'ODM History',url:'/odm'},{item:'ODM Platform',url:'/odm'},{item:'ODM Procedure',url:'/odm'},{item:'ODM Inquiry',url:'/odm'}]},
        {title:'Service',items:[ {item:'Service History',url:'/service'},{item:'Service Platform',url:'/service'}]},
        // {title:'Service',items:[ {item:'Service History',url:'/service'},{item:'Service Platform',url:'/service'},{item:'Tech service Inquiry',url:'/service'}]},
        {title:'Support',items:[ {item:'Inquiry',url:'/support'},{item:'Q&A',url:'/support'},{item:'Ref. Room',url:'/support'},{item:'Activity',url:'/support'}]}
     ];
     loginLink = 'Login';
     loginDesc = 'Enter name and password'
     registerLink = 'Register';

  }else{
    mainmenu= [
        {title:'회사소개',items:[{item:'인사말씀',url:'/company'},{item:'사업영역',url:'/company'},{item:'찾아오시는 길',url:'/company'}, {item:'액티비티',url:'/support'}]},
  //      {title:'제  품',items:[ {item:'HMI',url:'/product'},{item:'ODM 제품',url:'/product'},{item:'SOM 제품',url:'/product'}]},
        {title:'제  품',items:[{item:'ODM 제품',url:'/product'},{item:'SOM 제품',url:'/product'}]},
        {title:'ODM',items:[ {item:'ODM 사례',url:'/odm'},{item:'ODM 플랫폼',url:'/odm'},{item:'ODM 절차',url:'/odm'}]},
        // {title:'ODM',items:[ {item:'ODM 사례',url:'/odm'},{item:'ODM 플랫폼',url:'/odm'},{item:'ODM 절차',url:'/odm'},{item:'ODM 요청폼',url:'/odm'}]},
        {title:'개발용역',items:[ {item:'기술용역 사례',url:'/service'},{item:'기술용역 플랫폼',url:'/service'}]},
        // {title:'개발용역',items:[ {item:'기술용역 사례',url:'/service'},{item:'기술용역 플랫폼',url:'/service'},{item:'기술용역 문의폼',url:'/service'}]},
        {title:'고객지원',items:[{item:'영업문의',url:'/support'}, {item:'기술문의',url:'/support'},{item:'자료실',url:'/support'},{item:'액티비티',url:'/support'}]}
      ];
      loginLink = '로그인';
      loginDesc = '로그인 정보를 입력하세요'
     registerLink = '회원가입';
    }
  

    const headerSubMenus = mainmenu.map((menu,inx)=>{return <HeaderSubMenu key={shortid.generate} lang={lang} menu={menu} cbChangeScreen={cbChangeScreen}  />  });
  
  return (
         <div id="banner">
          <div className="_container">
          
              <div className="wrap_menu" >
                {/* 헤더영역 */}
                <div className="navbar" >
               
                  <Link to={'/'+ lang}  key={shortid.generate}className="navbar_logo" onClick={()=>{cbChangeScreen('/',0)}} > <span></span></Link>
    
                  <div className="navbar_mobile"  >
                         <Link  key={shortid.generate} to={()=>{javascript:void(0)} } >  <i className="fas fa-bars"></i></Link>
                  </div>
                       
                  <div className="navbar_menu_wrap" >
                    <ul className="navbar_menu">
                      {headerSubMenus}
                    </ul>
                    {/* .navbar_menu */}
                  </div>
                
                  <div>            
                    <ul  className="navbar_language" >
                      <li id="eng" key={shortid.generate}><Link to="/en" activeClassName="active" lang={lang} onClick={()=>{cbChangeLang('en')}} onLoad={()=>{cbChangeLang('en')}}  >English</Link></li>
                      <li id="kor" key={shortid.generate}><Link to="/ko" activeClassName="active" lang={lang} onClick={()=>{cbChangeLang('ko')}} onLoad={()=>{cbChangeLang('en')}}  >Korean</Link></li>
                    </ul>
                    <ul  className="navbar_login"  >
                      <li id="login" key={shortid.generate}><Link to="#" activeClassName="active" lang={lang} onClick={()=>{handleLogin()}} >{loginLink}</Link></li>
                      <li id="register" key={shortid.generate}><Link to="#" activeClassName="active" lang={lang} onClick={()=>{handleRegister()}} >{registerLink}</Link></li>
                    </ul>
                  </div>
                   
                {/* .navbar_menu_wrap */}
                </div> 
                {/* .navbar  */}
       
                <div>
               
                  {loginOpen?
                       < DlgLogin  lang={lang} open={1} 
                        caption={loginLink } 
                        desc={loginDesc } 
                        isValid={isValidContent} writer={userName} passwd={userPassword}  
                        cbHandleSubmit={ {javascript:void(0)}} 
                    
                        cbHandleOk={()=>{ handleOK()}} 
                        cbHandleCancel={()=>{ handleOK()}} 
                        cbHandleChange={ {javascript:void(0)}} 
                        />      
                        
                      :
                      null
                  }    
                </div> 
 
                {/* 홈경로에서만 랜더링한다   */}
              
                { (sel==('/'+lang)) ? <HTMLHeaderHomeBanner lang={lang} sel={sel} /> :
                                      <HTMLHeaderSimpleBanner lang={lang} sel={sel} /> 
                }
   
              </div>
              {/* .wrap_menu  */}

              
          </div>
          {/* .container */}
            
        </div>
        //  #banner 

    );    

}

export default HTMLHeader;