import React, { Component } from 'react';
import {BrowserRouter as Router,Route,Switch,NavLink, Redirect, useParams} from 'react-router-dom';

import AdminManagementContent_Settings from './AdminManagementContent_Settings';
import AdminManagementContent_Statistics from './AdminManagementContent_Statistics';
import AdminManagementContent_Boards from './AdminManagementContent_Boards';



const AdminManagementHeader = ({lang}) => {
    var titles = ["설정","통계","게시판 관리"] ;
  
    return (
    <div className='prod_subheader'>
        <NavLink  exact to= {"/" + lang + "/admin/3/1"} lang={lang} className='prod_subitem' activeClassName="active">{titles[0]}</NavLink>
        <NavLink  exact to= {"/" + lang + "/admin/3/2"} lang={lang} className='prod_subitem' activeClassName="active">{titles[1]}</NavLink>
        <NavLink  exact to= {"/" + lang + "/admin/3/3"} lang={lang} className='prod_subitem' activeClassName="active">{titles[2]}</NavLink>

    </div>
    );
  };
  
  

function AdminManagementContent(props) 
{
  
    var params = useParams();
    var lang = props.location.pathname.split('/')[1];
   
 
    //높이 설정 
    const dstyle = {
      height: "1000px"
    }

    return (
      <div className="contents_cont"  id="s_contents" style={dstyle}>
         <div className="_container">
            <AdminManagementHeader lang= {lang} />

            <Switch>
              <Route exact path={`/admin/3`}><Redirect to={'/'+ lang +`/admin/3`} /></Route>
              <Route exact path={'/'+ lang +`/admin/3`}><Redirect to={'/'+ lang +`/admin/3/1`} /></Route>
 
              <Route  path={'/'+ lang +`/admin/3/1` }  component={AdminManagementContent_Settings} />
              <Route  path={'/'+ lang +`/admin/3/2` }  component={AdminManagementContent_Statistics} />
              
              <Route exact path={`/admin/3/3`}><Redirect to={'/'+ lang +`/admin/3/3/1`} /></Route>
              <Route exact path={'/'+ lang +`/admin/3/3`}><Redirect to={'/'+ lang +`/admin/3/3/1`} /></Route>
              <Route  path={'/'+ lang +`/admin/3/3/:index` }  component={AdminManagementContent_Boards} />
             </Switch>
        </div>
       </div>
    );
 }


export default AdminManagementContent;
