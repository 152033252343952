import React from 'react';
import {BrowserRouter as Router,Route,Switch,NavLink, Redirect, useParams} from 'react-router-dom';

import HMI_NXC9G45  from '../product/HM_NXC9G45'
import HMI_NXC320  from '../product/HMI_NXC320'
import EMB_NXC9G45  from '../product/EMB_NXC9G45'
import HMI_NXD3568_Auto  from '../product/HMI_NXD3568_Auto'
import HMI_NXD3568  from '../product/HMI_NXD3568'
import HMI_NXD8925  from '../product/HMI_NXD8925'
import EMB_NXSTMDCM  from '../product/EMB_NXSTMDCM'
import SOM_NXM320  from '../product/SOM_NXM320'
import SOM_NXM100  from '../product/SOM_NXM100'
import SOM_NXM2450  from '../product/SOM_NXM2450'

const productName_hmi = ["NXD3568 Auto","NXD3568 AVN","NXD8925","NXSTMDCM" ];
const productName_som = ["SOM_NXM320","SOM_NXM100","SOM_NXM2450" ];

const productArray_hmi = [HMI_NXD3568_Auto,HMI_NXD3568,HMI_NXD8925,EMB_NXSTMDCM ];
const productArray_som = [SOM_NXM320, SOM_NXM100,SOM_NXM2450 ];

const ProductHeaderBoard = ({lang}) => {
 
  const productList_hmi = productArray_hmi.map((product,inx)=>{ 
    return <NavLink  to= {"/" + lang + `/product/1/${inx+1}`} lang={lang} className='prod_item' activeClassName="active">{productName_hmi[inx]}</NavLink>  });

  return (
      <div className='prod_header'>
         {productList_hmi}
      </div>
  );
};

const ProductHeaderSOM = ({lang}) => {

    const productList_som = productArray_som.map((product,inx)=>{
     return   <NavLink  to= {"/" + lang + `/product/2/${inx+1}`} lang={lang} className='prod_item' activeClassName="active">{productName_som[inx]}</NavLink>  });



  return (
      <div className='prod_header'>
          {productList_som}
      </div>
  );
};


function ContentProductRoute({lang, index})
{

  
  switch(index){
    case 1:
      const productRouteList_hmi = productArray_hmi.map((product,inx)=>{   return    <Route  path={'/'+ lang +`/product/${index}/${inx+1}`}  component={product} />  });

    return  <div>
      <ProductHeaderBoard lang= {lang} />
      <Switch>
        <Route exact path="/product/${index}"><Redirect to={'/'+ lang +'/product/1'} /></Route>
        {productRouteList_hmi}
       </Switch>
     </div>
    
    case 2:
      const productRouteList_som = productArray_som.map((product,inx)=>{   return    <Route  path={'/'+ lang +`/product/${index}/${inx+1}`}  component={product} />  });

      return  <div>
      <ProductHeaderSOM lang= {lang} />
      <Switch>
        <Route exact path="/product"><Redirect to={'/'+ lang +'/product'} /></Route>
        {productRouteList_som}
      </Switch>
      </div>
    } //switch
}

function HTMLContentProduct(props) 
{
   var params = useParams();
   // var lang = props.location.pathname.split('/')[1];
   var lang = params.language;

    var index =  parseInt(params.id);

    //높이 설정 
    const dstyle = {
      height: "1600px"
    }

    return (
      <div className="contents_cont"  id="s_contents">
        <div className="_container">
          <div className="contents_wrap">
            <ContentProductRoute lang={lang} index={index} />
          </div>
        </div>
       </div>
    );
 }

export default HTMLContentProduct;







