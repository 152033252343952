import React, { Component } from 'react';

import FormOdmBrief from '../common/FormOdmBrief';


class ServiceContentPlatform extends Component {
    constructor(props) {
        super(props);
      }

    render() {
     var lang = this.props.location.pathname.split('/')[1];
   
        var formContents = 
        [

            
             // 플랫폼1 : 안드로이드 AI/ADV
             {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee"],
                    stamp : "/img/product/odm/hot.jpg",   
                    image : "/img/product/odm/logo-android-small.png",   
                    poster : "/mov/nxd3588_android_ai_adv.png",  
                    movie : "/mov/nxd3588_android_ai_adv.mp4", 
                    iWidth: "240px", iHeight:"240px",
                    titles: ["안드로이드" ,"적용분야",  "주요사양", "기타 보유 디자인"],
                    desc:[
                        ["인공지능/자율주행/광고용패널(28인치 스크린)"],
                        ["오픈GL3D(treejs) 초당  60프레임 재생"],
                        ["차량용 온도사양/ 차량용 이더넷(AEC-Q100)"],
                    ] ,
                    apply :   [
                        ["인공지능(얼굴인식)/자율주행메인보드"],
                        ["스크린골프/대형광고패널"],
                        ["각종 ODM 제품"],

                    ] ,
                     spec :   [
                        ["RK3588 2.0GHz Quad Core 64bit"],
                        ["RAM 4GB eMMC 16GB"],
                        ["2.5Gbit 차량용이더넷/ CANFD "],
                        [""]
                    ],
                    cap :   [
                        ["RK3588 / RK3568 / RK3568"],
                        ["IMX8MP / IMX8ULP /IMX6 "],
                        ["AM3354"],
                        ["AT91"],

                    ],
        
                },

               en: {
                    bgcolor : [ "#ddeeee","#ddeeee" ],
                    stamp : "/img/product/odm/hot.jpg",   
                    image : "/img/product/odm/logo-android-small.png",   
                    poster : "/mov/nxd3588_android_ai_adv.png",  
                    movie : "/mov/nxd3588_android_ai_adv.mp4",  
                    iWidth: "240px", iHeight:"240px",
                    titles: ["Android", "Application",  "Specification", "Ref. Designs"],
                    desc:[
                        ["Media play/Youtube/ Internet Radio/ Mirroring "],
                        ["Backend Camera/ Various contols"],
                        ["Android/ iPhone Mirroring & connectivity"],
                    ],
                    apply :   [
                        ["AI (Face detection) "],
                        ["Large Adv. sceen "],
                        ["ODM products"],

                    ] ,
                    spec :   [
                        ["RK3588 2.0GHz Quad Core 64bit"],
                        ["RAM 4GB eMMC 16GB"],
                        ["28 inch Adv. panel"],
                        [""]
                    ],
                    cap :   [
                        ["RK3588 / RK3568 / RK3568"],
                        ["IMX8MP / IMX8ULP /IMX6 "],
                        ["AM3354"],
                        ["AT91"],
                    ],
              }
            },       

     
             // 플랫폼1 : 안드로이드 AVN
             {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee"],
                    stamp : "/img/product/odm/hot.jpg",   
                    image : "/img/product/odm/logo-android-small.png",   
                    poster : "/mov/nxd3568_android_odm.jpg",  
                    movie : "/mov/nxd3568_android_odm.mp4",  
                    iWidth: "240px", iHeight:"240px",
                    titles: ["안드로이드" ,"적용분야",  "주요사양", "기타 보유 디자인"],
                    desc:[
                        ["미디어플레이/유투브/ 라디오/미러링 "],
                        ["후방카메라/ 차량관제"],
                        ["안드로이드폰 아이폰 미러링 및 연동 제어"],
                    ] ,
                    apply :   [
                        ["산업용 HMI / 디스플레이"],
                        ["핸드헬드 터미널"],
                        ["각종 ODM 제품"],

                    ] ,
                     spec :   [
                        ["RK3568 2.0GHz Quad Core 64bit"],
                        ["RAM 2GB eMMC 16GB"],
                        ["12인치 / 정전터치"],
                        [""]
                    ],
                    cap :   [
                        ["RK3288 / RK3128"],
                        ["IMX6 / IMX51 "],
                        ["TCC8970/ TCC8930 / TCC8925"],
                        ["AT91"],

                    ],
        
                },

               en: {
                    bgcolor : [ "#ddeeee","#ddeeee" ],
                    stamp : "/img/product/odm/hot.jpg",   
                    year : "2019",   
                    image : "/img/product/odm/logo-android-small.png", 
                    poster : "/mov/nxd3568_android_odm.jpg",  
                    movie : "/mov/nxd3568_android_odm.mp4",  
                    iWidth: "240px", iHeight:"240px",
                    titles: ["Android", "Application",  "Specification", "Ref. Designs"],
                    desc:[
                        ["Media play/Youtube/ Internet Radio/ Mirroring "],
                        ["Backend Camera/ Various contols"],
                        ["Android/ iPhone Mirroring & connectivity"],
                    ],
                    apply :   [
                        ["Industrial HMI "],
                        ["Handheld terminal"],
                        ["ODM products"],

                    ] ,
                    spec :   [
                        ["RK3568 2.0GHz Quad Core 64bit"],
                        ["RAM 2GB eMMC 16GB"],
                        ["12 inch LVDS / Capacitive touch panel"],
                        [""]
                    ],
                    cap :   [
                        ["RK3288 / RK3128"],
                        ["IMX6 / IMX51 "],
                        ["TCC8970/ TCC8930 / TCC8925"],
                        ["AT91"],

   
                    ],
              }
            },       

         
             // 플랫폼2 : 리눅스 YOCTO - IMX8ULP
             {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee"],
                    stamp : "",   
                    image : "/img/product/odm/logo-linux-small.png",   
                    poster : "/mov/nxdimx8ulp_linux_yocto.jpg",  
                    movie : "/mov/nxdimx8ulp_linux_yocto.mp4",  
                    iWidth: "240px", iHeight:"240px",
                    titles: ["리눅스 YOCTO" ,"적용분야",  "주요사양", "기타 보유 디자인"],
                    desc:[
                        ["산업용 QT/QML 기반 HMI"],
                        ["공장용 리눅스 터미널"],
                        ["저전력 AIOT 제품"],
                    ] ,
                    apply :   [
                        ["산업용 HMI / 디스플레이"],
                        ["공장용/차량용 터미널"],
                        ["각종 ODM 제품"],

                    ] ,
                     spec :   [
                        ["iMX8ULP Dual 800MHz "],
                        ["RAM 2GB eMMC 16GB"],
                        ["8인치 LVDS/정전식 멀티터치"],
                        [""]
                    ],
                    cap :   [
                        ["RK3588 / RK3568 / RK3568"],
                        ["IMX8MP / IMX8ULP /IMX6 "],
                        ["AM3354"],
                        ["AT91"],

                    ],
        
                },

               en: {
                    bgcolor : [ "#ddeeee","#ddeeee" ],
                    stamp : "",   
                    year : "2019",   
                    image : "/img/product/odm/logo-linux-small.png",   
                    poster : "/mov/nxdimx8ulp_linux_yocto.jpg",  
                    movie : "/mov/nxdimx8ulp_linux_yocto.mp4",  
                    iWidth: "240px", iHeight:"240px",
                    titles: ["Linux", "Application",  "Specification", "Ref. Designs"],
                    desc:[
                        ["Industrial QT/QML Terminal"],
                        ["FA terminal /Vehicle / AVN"],
                        ["Custom Linux product"]
                    ],
                    apply :   [
                        ["Industrial HMI "],
                        ["FA terminal /Vehicle  terminal"],
                        ["Ultra low power AIOT products"],

                    ] ,
                    spec :   [
                        ["iMX8ULP Dual 800MHz "],
                        ["RAM 2GB eMMC 16GB"],
                        ["8 inch LVDS/ Capacitive Multi-touch"],
                        [""]
                    ],
                    cap :   [
                        ["RK3588 / RK3568 / RK3568"],
                        ["IMX8MP / IMX8ULP /IMX6 "],
                        ["AM3354"],
                        ["AT91"],

                    ],
        

              }
            },           
            
             // 플랫폼2 : 리눅스 YOCTO 
             {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee"],
                    stamp : "",   
                    image : "/img/product/odm/logo-linux-small.png",   
                    poster : "/mov/nxdimx6_linux_yocto.jpg",  
                    movie : "/mov/nxdimx6_linux_yocto.mp4",  
                    iWidth: "240px", iHeight:"240px",
                    titles: ["리눅스 YOCTO" ,"적용분야",  "주요사양", "기타 보유 디자인"],
                    desc:[
                        ["산업용 QT/QML 기반 HMI"],
                        ["공장용 리눅스 터미널"],
                        ["커스텀 QT/QML 제품"],
                    ] ,
                    apply :   [
                        ["산업용 HMI / 디스플레이"],
                        ["공장용/차량용 터미널"],
                        ["각종 ODM 제품"],

                    ] ,
                     spec :   [
                        ["iMX6 SOLO/DualLite 1GHz "],
                        ["RAM 512GB eMMC 16GB"],
                        ["8인치 LVDS/정전식 멀티터치"],
                        [""]
                    ],
                    cap :   [
                        ["IMX6 / IMX51 "],
                        ["RK3568/ RK3288"],
                        ["TCC8970/ TCC8930 / TCC8925"],
                        ["AT91"],

                    ],
        
                },

               en: {
                    bgcolor : [ "#ddeeee","#ddeeee" ],
                    stamp : "",   
                    year : "2019",   
                    image : "/img/product/odm/logo-linux-small.png", 
                    poster : "/mov/nxdimx6_linux_yocto.jpg",  
                    movie : "/mov/nxdimx6_linux_yocto.mp4",  
                    iWidth: "240px", iHeight:"240px",
                    titles: ["Linux", "Application",  "Specification", "Ref. Designs"],
                    desc:[
                        ["Industrial QT/QML Terminal"],
                        ["FA terminal /Vehicle / AVN"],
                        ["Custom Linux product"]
                    ],
                    apply :   [
                        ["Industrial HMI "],
                        ["FA terminal /Vehicle  terminal"],
                        ["ODM products"],

                    ] ,
                    spec :   [
                        ["iMX6 SOLO/DualLite 1GHz "],
                        ["RAM 512GB eMMC 16GB"],
                        ["8 inch LVDS/ Capacitive Multi-touch"],
                        [""]
                    ],
                    cap :   [
                        ["IMX6 / IMX51 "],
                        ["RK3568/ RK3288"],
                        ["TCC8970/ TCC8930 / TCC8925"],
                        ["AT91"],

                    ],
        

              }
            },                   
            // 플랫폼3 : 리눅스 
            {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee"],
                    stamp : "",   
                   image : "/img/product/odm/logo-linux-small.png",   
                    poster : "/mov/linux-yocto-demo.jpg",  
                    movie : "/mov/linux-yocto-demo.mp4",  
                    iWidth: "240px", iHeight:"240px",
                    titles: ["리눅스 / 욕토/ 큐티" ,"적용분야",  "주요사양", "기타 보유 디자인"],
                    desc:[
                        ["임베디드 리눅스"],
                        ["리눅스 YOCTO"],
                        ["QT / QT QUICK(QML)"],
                    ] ,
                    apply :   [
                        ["산업용 HMI / 디스플레이"],
                        ["산업용터미널"],
                        ["각종 ODM 제품"],

                    ] ,

                    spec :   [
                        ["iMX6 DL  Cortex A9 800MHz "],
                        ["RAM 1GB NAND 8GB"],
                        ["8인치/15인치"],
  
                    ],
                    cap :   [
                        ["IMX6 / IMX51 "],
                        ["TCC8970/ TCC8930 / TCC8925"],
                        ["RK3288"],
                        ["AT91"],

                    ],
         
                },

               en: {
                    bgcolor : [ "#ddeeee","#ddeeee" ],
                    stamp : "",   
                    year : "2019",   
                    image : "/img/product/odm/logo-linux-small.png", 
                    poster : "/mov/linux-yocto-demo.jpg",  
                    movie : "/mov/linux-yocto-demo.mp4",  
                    iWidth: "240px", iHeight:"240px",
                    titles: ["Linux/QT/YOCTO", "Application", "Specification", "Ref. Designs"],
                    desc:[
                        ["Embeded Linux"],
                        ["Linux Yocto YOCTO"],
                        ["QT / QT QUICK(QML)"]
                    ],
                    apply :   [
                        ["Industrial HMI "],
                        ["Handheld terminal"],
                        ["ODM products"],

                    ] ,
                    spec :   [
                        ["iMX6 DL  Cortex A9 800MHz "],
                        ["RAM 1GB NAND 8GB"],
                        ["8inch/15inch"],
 
                    ],
                    cap :   [
                        ["H/W design & Cerification(KC+RF)"],
                        ["ODM Manufacturing"],
                        ["Linux porting"],
                        ["Embedded S/W "],

                    ],
  
              }
            },
      
             // 플랫폼2 : 리눅스 YOCTO - IMX8ULP
             {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee"],
                    stamp : "",   
                    image : "/img/product/odm/logo-linux-small.png",   
                    poster : "/mov/nxdam3354_linux_yocto.jpg",  
                    movie : "/mov/nxdam3354_linux_yocto.mp4",  
                    iWidth: "240px", iHeight:"240px",
                    titles: ["리눅스 YOCTO" ,"적용분야",  "주요사양", "기타 보유 디자인"],
                    desc:[
                        ["산업용 QT/QML 기반 HMI"],
                        ["공장용 리눅스 터미널"],
                        ["저전력 AIOT 제품"],
                    ] ,
                    apply :   [
                        ["산업용 HMI / 디스플레이"],
                        ["공장용/차량용 터미널"],
                        ["각종 ODM 제품"],

                    ] ,
                     spec :   [
                        ["AM3354 1GHzHz "],
                        ["RAM 512MB eMMC 16GB"],
                        ["5인치 RGB/저항식 멀티터치"],
                        [""]
                    ],
                    cap :   [
                        ["RK3588 / RK3568 / RK3568"],
                        ["IMX8MP / IMX8ULP /IMX6 "],
                        ["AM3354"],
                        ["AT91"],

                    ],
        
                },

               en: {
                    bgcolor : [ "#ddeeee","#ddeeee" ],
                    stamp : "",   
                    year : "2019",   
                    image : "/img/product/odm/logo-linux-small.png",   
                    poster : "/mov/nxdam3354_linux_yocto.jpg",  
                    movie : "/mov/nxdam3354_linux_yocto.mp4",  
                    iWidth: "240px", iHeight:"240px",
                    titles: ["Linux", "Application",  "Specification", "Ref. Designs"],
                    desc:[
                        ["Industrial QT/QML Terminal"],
                        ["FA terminal /Vehicle / AVN"],
                        ["Custom Linux product"]
                    ],
                    apply :   [
                        ["Industrial HMI "],
                        ["FA terminal /Vehicle  terminal"],
                        ["Ultra low power AIOT products"],

                    ] ,
                    spec :   [
                        ["AM3354 1GHzHz "],
                        ["RAM 512MB eMMC 16GB"],
                        ["5 inch/ touch"],
                        [""]
                    ],
                    cap :   [
                        ["RK3588 / RK3568 / RK3568"],
                        ["IMX8MP / IMX8ULP /IMX6 "],
                        ["AM3354"],
                        ["AT91"],

                    ],
        

              }
            },           
                        
           // 플랫폼4 : 윈CE 
           {
            ko: {
                bgcolor : [ "#ddeeee","#ddeeee"],
                stamp : "",   
                image : "/img/product/odm/logo-wce-small.png",   
                poster : "/mov/wce-demo.jpg",  
                movie : "/mov/wce-demo.mp4",  
                iWidth: "240px", iHeight:"240px",
                titles: ["윈도우CE" ,"적용분야",  "주요사양", "기타 보유 디자인"],
                desc:[
                    ["산업용 단말기"],
                    ["모바일/핸드헬드"],
                    ["커스텀 제품"],
                ] ,
                apply :   [
                    ["산업용 HMI / 디스플레이"],
                    ["핸드헬드 터미널"],
                    ["각종 ODM 제품"],

                ] ,
                
                spec :   [
                    ["AT91 ARM9 400MHz"],
                    ["RAM 128MB NAND 256MB"],
                    ["8인치 저항식 터치"],
                    [""]
                ],
                cap :   [
                    ["IMX6 / IMX51 "],
                    ["TCC8970/ TCC8930 / TCC8925"],
                    ["RK3288"],
                    ["AT91"],

                ],
     
            },

           en: {
                bgcolor : [ "#ddeeee","#ddeeee" ],
                stamp : "",   
                year : "2019",   
                image : "/img/product/odm/logo-wce-small.png", 
                poster : "/mov/wce-demo.jpg",  
                movie : "/mov/wce-demo.mp4",  
                iWidth: "240px", iHeight:"240px",
                titles: ["WinCE", "Application",  "Specification", "Ref. Designs"],
                desc:[
                    ["Industrial Terminal"],
                    ["Industrial device support "],
                    ["Custom product"]
                ],
                apply :   [
                    ["Industrial HMI "],
                    ["Handheld terminal"],
                    ["ODM products"],

                ] , 
                spec :   [
                    ["AT91 ARM9 400MHz"],
                    ["RAM 128MB NAND 256MB"],
                    ["8inch Registitive Touch"],
  
                ],
                cap :   [
                    ["H/W design & Cerification(KC+RF)"],
                    ["ODM Manufacturing"],
                    ["WinCE porting"],
                    ["Embedded S/W "],

                ],
          }
        },       
        
        
           // 플랫폼4 : 마이컴  
           {
            ko: {
                bgcolor : [ "#ddeeee","#ddeeee"],
                stamp : "",   
                image : "/img/product/odm/logo-fw-small.png",   
                poster : "/mov/nxstm-dcm_demo.jpg",  
                movie : "/mov/nxstm-dcm_demo.mp4",  
                iWidth: "240px", iHeight:"240px",
                titles: ["펌웨어/마이컴" ,"적용분야", "주요사양", "기타 보유 디자인"],
                desc:[
                    ["IOT센서/에지 "],
                    ["펌웨어제품"],
                    ["아두이노 호환제품"],
                ] ,
                apply :   [
                    ["IOT센서/IOT 에지"],
                    ["각종 제어 / 모터 구동 보드"],
                    ["각종 ODM 제품"],

                ] ,
               spec :   [
                    ["STM8"],
                    ["DC 모터 거리 속도 제어"],
                    ["부하시 긴급 멈춤"],
                    [""]
                ],
                cap :   [
                    ["ATMEGA "],
                    ["Nordic nRF52"],
                    ["PIC"],
                    ["AT91"],

                ],
 
     
            },

           en: {
                bgcolor : [ "#ddeeee","#ddeeee" ],
                stamp : "",   
                year : "2019",   
                image : "/img/product/odm/logo-fw-small.png", 
                poster : "",  
                movie : "",  
                iWidth: "240px", iHeight:"240px",
                titles: ["MiCOM", "Application",  "Specification", "Ref. Designs"],
                desc:[
                    ["Industrial conteroller"],
                    ["Industrial device support "],
                    ["Custom product"]
                ],
                apply :   [
                    ["Industrial products "],
                    ["control board"],
                    ["ODM products"],

                ] ,
                spec :   [
                    ["STM8"],
                    ["DC motor direction & distance contol"],
                    ["Quick stop on overload"],
                    [""]

                ],
                cap :   [
                    ["ATMEGA "],
                    ["Nordic nRF52"],
                    ["PIC"],
                    ["AT91"],

                ],
 
     
          }
        },               
                                   
        ] ;

        const dstyle = {
            color: "white",
            border: '2px solid #3d52ad',
            backgroundColor: '#eee',
            // height: "1040px"  // 헤더의 높이가 차감된듯 
          }
        return (
          
        <div className="odm_contents"  style={dstyle} >
            <br/>
            { (lang=='ko')? <FormOdmBrief formContent={formContents[0].ko}/> : <FormOdmBrief formContent={formContents[0].en}/>}  
            <br/>
            { (lang=='ko')? <FormOdmBrief formContent={formContents[1].ko}/> : <FormOdmBrief formContent={formContents[1].en}/>}  
            <br/>
            { (lang=='ko')? <FormOdmBrief formContent={formContents[2].ko}/> : <FormOdmBrief formContent={formContents[2].en}/>}  
            <br/>
            { (lang=='ko')? <FormOdmBrief formContent={formContents[3].ko}/> : <FormOdmBrief formContent={formContents[3].en}/>}  
            <br/>
            { (lang=='ko')? <FormOdmBrief formContent={formContents[4].ko}/> : <FormOdmBrief formContent={formContents[4].en}/>}  
            <br/>
         
            { (lang=='ko')? <FormOdmBrief formContent={formContents[5].ko}/> : <FormOdmBrief formContent={formContents[5].en}/>}  
            <br/>
         
            { (lang=='ko')? <FormOdmBrief formContent={formContents[6].ko}/> : <FormOdmBrief formContent={formContents[6].en}/>}  
            <br/>
            { (lang=='ko')? <FormOdmBrief formContent={formContents[7].ko}/> : <FormOdmBrief formContent={formContents[7].en}/>}  
            <br/>
         
              

        </div>
      );
    }
}


export default ServiceContentPlatform;
