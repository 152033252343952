import React from 'react';
import {BrowserRouter as Router,Route,Switch,NavLink, Redirect, useParams} from 'react-router-dom';

import ServiceContentHistory  from '../service/ServiceContentHistory'
import ServiceContentPlatform  from '../service/ServiceContentPlatform'
import ServiceContentInquiry  from '../service/ServiceContentInquiry'

import '../service/ServiceHeader.css'



const ServiceHeaderHistory = ({lang}) => {

  var titles = (lang=='ko')? ["주요 기술용역 사례 "] :
                            ["Technical Service Summary"];
  return (
      <div className='service_header'>
          <NavLink  to= {"/" + lang + "/service/1/1"} lang={lang} className='service_item' activeClassName="active">{titles[0]}</NavLink>
       </div>
  );
};


const ServiceHeaderPlatform = ({lang}) => {

  var titles = (lang=='ko')? ["기술용역 레퍼런스 플랫폼 "] :
                            ["Technical service Reference Platform"];
  return (
      <div className='service_header'>
          <NavLink  to= {"/" + lang + "/service/2/1"} lang={lang} className='service_item' activeClassName="active">{titles[0]}</NavLink>
   
      </div>
  );
};

const ServiceHeaderInquiry = ({lang}) => {
  var titles = (lang=='ko')? ["기술용역 문의 "] :
  ["Technical service Inquiry"];

  return (
      <div className='service_header'>
          <NavLink  to= {"/" + lang + "/service/3/1"} lang={lang} className='service_item' activeClassName="active">{titles[0]}</NavLink>
      </div>
  );
};

function ContentServiceRoute({lang, index})
{
  switch(index){
    case 1:
      return  <div>
        <ServiceHeaderHistory lang= {lang} />
          <Switch>
             <Route exact path={`/service/${index}`}><Redirect to={'/'+ lang +`/service/${index}`} /></Route>
            <Route  path={'/'+ lang +`/service/${index}`}  component={ServiceContentHistory} />
          </Switch>
      </div>
    
    case 2:
      return  <div>
        <ServiceHeaderPlatform lang= {lang} />
        <Switch>
              <Route exact path={`/service/${index}`}><Redirect to={'/'+ lang +`/service/${index}`} /></Route>
              <Route  path={'/'+ lang +`/service/${index}`}  component={ServiceContentPlatform} />
            </Switch>
      </div>
    
    case 3:
      return  <div>
        <ServiceHeaderInquiry lang= {lang} />    
        <Switch>
              <Route exact path={`/service/${index}`}><Redirect to={'/'+ lang +`/service/${index}`} /></Route>
              <Route  path={'/'+ lang +`/service/${index}`}  component={ServiceContentInquiry} />
            </Switch>
      </div>
     } //switch
}



function HTMLContentService(props) 
{
  
    var params = useParams();
   // var lang = props.location.pathname.split('/')[1];
   var lang = params.language;

    var index =  parseInt(params.id);

    

    return (
      <div className="contents_cont"  id="s_contents" >
        <div className="_container">
          <div className="contents_wrap" >
            <ContentServiceRoute lang={lang} index={index} />
          </div>
        </div>
       </div>
    );
 }

export default HTMLContentService;







