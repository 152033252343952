import React from 'react';
import Dialog from '@material-ui/core/Dialog';
//import { Grid, Row, Cell } from 'react-inline-grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const DlgCommon = ({lang,open,caption,desc,handleOk,handleCancel}) => {

    var dlgOk = (lang=='ko') ? "확인" : "Yes";
    var dlgCancel = (lang=='ko') ? "취소" : "Cancle";

    return (
    <Dialog onClose={handleOk} open={open}>
        <DialogTitle onClose={handleOk}>
            {caption  }
        </DialogTitle>

            <DialogContent>
                <Typography gutterBottom>
                {desc}
                </Typography>
            </DialogContent>

        <DialogActions>

        <Button variant="contained" color="primary" onClick={handleOk}>{dlgOk}</Button> 
        {handleCancel ? 
            <Button variant="contained" color="primary" onClick={handleCancel}>{dlgCancel}</Button> 
           :
           null
        } 
        </DialogActions>
    </Dialog>

    );
};

export default DlgCommon;