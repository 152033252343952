import React, { Component } from 'react';
import '../../public/css/product.css'

// 언어설정을 props.lang 과 같이 바로 전달받지 못한다.
// 예전에는 NavLink를 통해서 props도 바로 전달 받았었는데... 이상하네.. 일단 주소줄에서 파싱해서 언어를 설정한다 . 

class SOM_NXM2450 extends React.Component {
  constructor(props) {
    super(props);
  }
    
  render() {
    const dstyle = {
      color: "white",
      border: '2px solid #3d52ad',
      backgroundColor: '#eee',
      // height: "1040px"  // 헤더의 높이가 차감된듯 
    }

    const hstyle = {
      color: "black",
      fontFamily: "Arial"
     
    }

    //주소줄로 부터 언어설정을 파싱..
    var language = this.props.location.pathname.split('/')[1];

    var prod_title = (language=='ko')? "윈도우CE/ 모바일 /저전력/ 차량용 초소형 CPU 모듈" : "WinCE/QT SOM (CPU Module)" ;
    var prod_subtitles = (language=='ko')? ["SOM(CPU모듈)사진", "적용된 사례"] : ["Front view", "Askew view"]  ;
    var prod_use = (language=='ko')? 
    {
      title: "적용분야",
      desc: ["• 차량용 /핸드헬드/모바일 / 저전력/배터리 구동 단말기 :PDA / 바코더스캐너 / 결제기", "• CDMA / WiFi를 통한 데이터 송수신", "• 산업용 장비용 CPU모듈"]
    }:{
      title: "Application",
      desc: ["• MDT / Mobile / Handheld: PDA/ Barcode scaner", "• Wireless (CDMA/WiFI) communication  ", "• Industrial SOM"]
    }
    var prod_title_specs = (language=='ko')? ["하드웨어 사양(SOM_NXM2450)", "소프트웨어 사양(SOM_NXM2450)"] : ["Hardware Specification(SOM_NXM2450)", "Software Specification(NXC320M)"]  ;

    return (
   
      <div className="prod_contents" div style={dstyle} >
        <br/>
        <div className="column">
          <div className="col_tab">
            <div className="col1">
              <h1 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "16px",fontStyle: 'italic', marginTop: '5px' }}>{prod_title}</h1>
              <hr/>
              <h2 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "14px"}}>{prod_subtitles[0]}</h2>
              <br/>
              
              <img width="320"  border="0" src="/img/product/som/nxm2450.jpg"  />
            </div>
            <div className="col2">
              <h1 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "16px",fontStyle: 'italic', marginTop: '5px' }}>{prod_title}</h1>
              <hr/>
              <h2 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "14px"}}>{prod_subtitles[1]}</h2>
              <br/>

              <img width="320"  border="0" src="/img/product/som/nxm2450-1.png"  />
            </div>
          </div>
          
          <div className="col3">
            <br/>

            <h1 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "16px",fontStyle: 'italic', marginTop: '5px' }}>{prod_use.title}</h1>
            <br/>
            <center>
            <table border="0" cellpadding="0" cellspacing="0"   width ="60%">
                <tr bgcolor="#c8bfe">
                    <ul>
                    <li  style={{fontSize:"18px"}}>{prod_use.desc[0]}</li>
                    <li  style={{fontSize:"18px"}}>{prod_use.desc[1]}</li>
                    <li  style={{fontSize:"18px"}}>{prod_use.desc[2]} </li>
                    </ul>
                 </tr>
                
              </table>
              <br/>    
            </center>
            <h1 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "16px",fontStyle: 'italic', marginTop: '5px' }}>{prod_title_specs[0]}</h1>
            <br/>
            <center>
        
              <table border="1" cellpadding="0" cellspacing="0"   width ="60%">
                        
                <tr>
                  <td width="21%">&nbsp;<b>Name</b></td>
                  <td width="79%">&nbsp;<b>Description</b></td>
                </tr>
                <tr>
                <td width="21%">&nbsp;CPU</td>
                  <td width="79%">&nbsp;ARM9 533MHz</td>
                </tr>
                <tr>
                  <td width="21%">&nbsp;Memory</td>
                  <td width="79%">&nbsp;RAM 256MB / NAND 128MB</td>
                </tr>
                <tr>
                  <td width="21%">&nbsp;Display</td>
                  <td width="79%">&nbsp;TFT 24bit color (max 800x600)</td>
                </tr>
                <tr>
                  <td width="21%">&nbsp;Touch</td>
                  <td width="79%">&nbsp;4 wire registitive</td>
                </tr>
                <tr>
                  <td width="21%"  rowspan="2">&nbsp;Wireless</td>
                  <td width="79%">&nbsp; CDMA </td>
                </tr>
                <tr>
                  <td width="79%">&nbsp; WiFi</td>
                </tr>                
                <tr>
                  <td width="21%">&nbsp;CAN</td>
                  <td width="79%">&nbsp;Dual CAN controller</td>
                </tr>
                <tr>
                  <td width="21%">&nbsp;Audio</td>
                  <td width="79%">&nbsp;AC97 Audio </td>
                </tr>
                <tr>
                  <td width="21%" rowspan="5">&nbsp;IO</td>
                  <td width="79%">&nbsp;USB 1.0/ Host/Function 2.0</td>
                </tr>
                <tr>
                  <td width="79%">&nbsp;SD 1 Slot</td>
                </tr>
                <tr>
                      <td width="79%">&nbsp;Serial 4 port (RS-232 3 pdorts, RS-485 1 port</td>
                
                </tr>
                <tr>
                  <td width="79%">&nbsp;Camera 1 port</td>
                
                </tr>
                <tr>
                  <td width="79%">&nbsp;N/A　</td>
                </tr>
              </table>
            </center>

            <br/>
            <h1 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "16px",fontStyle: 'italic', marginTop: '5px' }}>{prod_title_specs[1]}</h1>
            <br/>
            <center> 
            <table border="1" cellpadding="0" cellspacing="0" width ="60%">
                <tr>
                  <td width="21%">&nbsp;<b>Name</b></td>
                  <td width="79%">&nbsp;<b>Description</b></td>
                </tr>
                <tr>
                  <td width="21%">&nbsp;OS</td>
                  <td width="79%">&nbsp;Windows CE 5.0/6.0</td>
                </tr>
                <tr>
                  <td width="21%">&nbsp;SDK</td>
                  <td width="79%">&nbsp; Custom SDK Support</td>
                </tr>
                <tr>
                  <td width="21%">&nbsp;Upgrade</td>
                  <td width="79%">&nbsp; OS Upgrade</td>
                </tr>
                <tr>
                  <td width="21%" rowspan="3">&nbsp;Device&nbsp;
                  <p/>&nbsp;</td>
                  <td width="79%">&nbsp; Display/Audio/Touch/Serial/Eth/</td>
                </tr>
                <tr>
                  <td width="79%">&nbsp; NTSC CAM Preview/Record/Playback</td>
                </tr>
                <tr>
                  <td width="79%">&nbsp; USB/SD/RTC/</td>
                </tr>
                <tr>
                  <td width="21%" rowspan="5">&nbsp;Cutomizing</td>
                  <td width="79%">&nbsp; Boot Logo </td>
                </tr>
                <tr>
                  <td width="79%">&nbsp; Display</td>
                </tr>
                <tr>
                  <td width="79%">&nbsp; Application Auto Lauching</td>
                </tr>
                <tr>
                  <td width="79%">&nbsp; Custom SDK support </td>
                </tr>
                <tr>
                  <td width="79%">&nbsp; N/A</td>
                </tr>
              </table>
            </center>
          </div>
          <div className="col4">
            
          </div>
          <div className="col5">
            
          </div>
          <div className="col6">
           
          </div>
          <div className="col7">
            
          </div>
        </div>
      </div>
    );
  }
}

export default SOM_NXM2450;
