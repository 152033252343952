import React, { Component, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import {BrowserRouter as Router,Route,Switch,NavLink, Redirect, useParams} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Moment from 'moment';
import BoardList from '../support/BoardList';
// import HTMLContentSupport  from '../route/HTMLContentSupport';
import HTMLContentSupport  from '../route/HTMLContentSupport';

///////////////////////////////////////////////////

/* 설정된 언어별로 메인화면을 보여준다 */

function HTMLContentHome(props) 
{
  var params = useParams();
   // var lang = props.location.pathname.split('/')[1];
   var lang = params.language;
  
  var col1_title,col2_title,col3_title,col4_title,col5_title ;
  var col1_desc, col1_subdesc, col1_img;
  var quotation_info;

  var login_info, login_footer_info;
  var anounce;
  var miniBoard_titles = {}
  var biz_etc1;
  col1_img = ["img/product/p1_0.png", "img/product/p1_1.png", "img/product/p1_2.png", "img/product/p1_3.png", "img/product/p1_4_2.png"  ] ;
   
  if(lang=='en') {
      col1_title =  "Linux QT/ Android / Embedded ODM Product";
      col2_title =  "Transportation card reader ";
      col3_title =  "Q&A";
      col4_title =  "Development : Electric Measuring Instrument";
      col5_title =  "Quotation | Inquiry";
      quotation_info  = ['hone call:','+82-2057-2422','8:00 AM ~ 6:00 PM','E-mail: support@nexfield.net'];
      miniBoard_titles =  ['Inquiry','Reference','News','Board','Move'];

      col1_desc =  ["Android Embedded",           "Nobile app" ,              "Linux QT/QML Product","Linux QT/QML Product","Micom/ Firmware Product"];
      col1_subdesc = ["(Camping Car Controller)", "(Phone applications)", "(Motor Control System)", "(Transportation card checker)", "(Indistrial HMI)", "(Modbus DC Motor control board)" ] ;
    
         
      login_info = 
      [   
        "Members's service",
        'Settings',
        'authentificattion',
        'Keep login',
        'IP security',
        'Login region',
        'ID',
        'Password',
        'Login'
      ];
               
      login_footer_info = 
      [   
        'Troublehoot',
        'Register',
        'Search Id / Password',
      ];
        
      anounce = 'Board is under contruction ! Use this from support menu! (Click to redirect).';

      biz_etc1 = `Design House & Resale Partner`;
  }else{
      col1_title =  "ODM: 리눅스 QT QML/ 안드로이드 / 임베디드/ 소프트웨어";
 //     col2_title =  "제품 : 교통카드 개집표기 ";
      col2_title =  "제품|소프트웨어 : 자율주행농기계/자율주행AVN";
      col3_title =  "Q&A | 자료실";
      col4_title =  "제품개발 사례 : 스마트그리드 | 전기계측기";
      col5_title =  "견적요청 | 문의";
      // 지금은 편의상.. 연락처를 보여준다 => 추후 견적 폼으로 연결하려함
      quotation_info  = ['문의전화','(02) 2057-2422','평일: 8:00 AM ~ 6:00 PM','문의메일: support@nexfield.net'];
      miniBoard_titles =  ['영업문의','자료실','액티비티','기술문의','이동'];
 
      col1_desc =  ["안드로이드 차량용AVN",   "안드로이드/아이폰 폰앱" ,         "리눅스QT/QML 제품",      "리눅스 YOCTO QT/QML ",    "마이컴/ 펌웨어 제품"];
      col1_subdesc = ["(캠핑카 컨트롤러)","(제어용 폰앱)", "(교통카드 개집표기)",  "(산업용 HMI)",   "(모드버스 DC 모터 보드)" ] ;
    
      login_info = 
      [   
        '회원서비스',
        '로그인 및 관련 설정',
        '로그인 보안',
        '로그인 상태 유지',
        'IP 보안',
        '로그인 영역',
        '아이디',        
        '비밀번호',
        '로그인'
      ];
               
      login_footer_info = 
      [   
        '로그인 문제해결',
        '회원가입',
        '아이디 비밀번호 찾기',
      ];
      anounce = '게시판 개편중입니다. 게시판은 고객지원 메뉴를 사용해주세요 (클릭시 자동연결).';
      biz_etc1 = `공인 디자인 하우스 및  리세일 파트너`;
 }

 const listStyle = {
  listStyle: 'none'
}
//=============================================================
// 2022.12.09: 미니게시판의 "이동" 버튼 클릭시 해당 게시판 열기 
// 항목클릭시 상태변수 activeId를 변경시키고  "이동" 버튼 클릭시 history.push() 시킨다 
var captionMove= (lang=='ko')?"전체보기"  : "View all" ;
var btnBgColor =  '#3F51B5' ;
var history = useHistory();
const handleMoveTo = (id) => {
   history.push('/'+lang + `/support/${id}/1`);
}


const [activeId, setActiveId] = useState(1);  // 파라미터는 라우터의 인덱스이므로 1부터 시작 


 var today = new Date();
  //debugger

  return (
     <div id="contents">
      <div className="_container">
        <div className="contents">

          <div className="column col1">
            <div className="gallery">
                <h4>{col1_title}</h4>
                <div className="gallery_btn">
                  <button className="gb_icon3 prev"><span className="ir_pm">prev</span></button>
                  <button className="gb_icon4 next"><span className="ir_pm">next</span></button>
                </div>
                {/* 슬릭이미지들 */}
                <div className="gallery_img"  >
                  <a href={'/'+lang + '/product/1/1'} ><img src={col1_img[0] } height="480" height="272" alt="product0"/>
                    <div className="gallery_text">
                      <p>{col1_desc[0]}</p>
                      <p>{col1_subdesc[0]}</p>
                    </div>
                  </a>
 
                  <a href={'/'+lang + '/product/2/1'} ><img src={col1_img[1]} height="272"   style={{ alignSelf: 'center' }} alt="product1"/>
                    <div className="gallery_text">
                      <p>{col1_desc[1]}</p>
                      <p>{col1_subdesc[1]}</p>
                    </div>
                  </a>

                  <a href={'/'+lang + '/odm/1/1'} ><img src={col1_img[2]}  height="272" alt="product2"/>
                    <div className="gallery_text">
                      <p>{col1_desc[2]}</p>
                      <p>{col1_subdesc[2]}</p>
                    </div>
                  </a>

                  <a href={'/'+lang + '/product/1/1'} ><img src={col1_img[3]}  height="272" alt="product3"/>
                    <div className="gallery_text" >
                      <p>{col1_desc[3]}</p>
                      <p>{col1_subdesc[3]}</p>
                    </div>
                  </a>
          
                  <a href={'/'+lang + '/product/2/6'} ><img src={col1_img[4]} height="272" alt="product4"/>
                    <div className="gallery_text" id="t3">
                      <p>{col1_desc[4]}</p>
                      <p>{col1_subdesc[4]}</p>
                    </div>
                  </a>
              </div>
              {/* <!-- .gallery_img --> */}
            </div>
          {/* <!-- .gallery --> */}
          </div>
          {/* <!-- .col1 --> */}

          <div className="column col2">
            <div className="movi">
              <h4>{col2_title}</h4>
                <div className="movi_btn">
                  {/* <!--
                  <button className="movi_icon1 play"><span className="ir_pm">Play</span></button>
                  <button className="movi_icon2 pause"><span className="ir_pm">Pause</span></button>
                  <button className="movi_icon3 prev"><span className="ir_pm">prev</span></button>
                  <button className="movi_icon4 next"><span className="ir_pm">next</span></button>
                  --> */}
                </div>
                <div className="movi_img">
                {/* <video width="60%" height="60%" controls="controls" className="video-js vjs-default-skin" data-setup="{}"   >
                        <source src="./mov/o1.mp4"  type="video/mp4" />
                    </video> */}
                    <video width="90%" height="90%" controls="controls" className="video-js vjs-default-skin" data-setup="{}"        poster="./mov/nxd3568-dd.png"  >
                        <source src="./mov/nxd3568-dd.mp4"  type="video/mp4" />
                    </video>
                </div>
            </div>
            {/* <!-- .movi --> */}
          </div>
          {/* <!-- .col2 --> */}
          {/* 미니게시판       */}
          <div className="column col3">
            {/* <!-- 탭 메뉴 --> */}          
            <div className="tab_menu" >
              <h4 className="ir_su">{col3_title}</h4>
                  <ul>
                    < li className="active" style={listStyle} onClick={()=>setActiveId(1)}><a href="#">{miniBoard_titles[0]}</a>    //영업문의 
                      <ul style={{display:"block"}}>
                        <BoardList history={props.history}  lang={lang} board={'support'} boardType={"mini"} maxPageArticles={10} index={'1'} subIndex={'1'}  /> 
                      </ul>
                    </li>
                    <li  style={listStyle} onClick={()=>setActiveId(3)}><a href="#">{miniBoard_titles[1]}</a>   //자료실 
                      <ul>
                        <BoardList history={props.history}  lang={lang} board={'support'} boardType={"mini"} maxPageArticles={10} index={'3'} subIndex={'1'} /> 
                      </ul>
                    </li>
                    <li  style={listStyle} onClick={()=>setActiveId(4)}><a href="#">{miniBoard_titles[2]}</a> //액티비티 
                      <ul>
                        <BoardList history={props.history}  lang={lang} board={'support'} boardType={"mini"} maxPageArticles={10} index={'4'}  subIndex={'1'} /> 
                      </ul>
                    </li>
                    <li  style={listStyle} onClick={()=>setActiveId(2)}><a href="#">{miniBoard_titles[3]}</a> //기술문의 
                      <ul>
                        <BoardList history={props.history}  lang={lang} board={'support'} boardType={"mini"} maxPageArticles={10} index={'2'}  subIndex={'1'} /> 
                      </ul>
                    </li>

                    <li  style={listStyle} ><a href="#">
                        <Button variant="contained" style={{color: '#ffffff',backgroundColor: `${btnBgColor}`, height: '20px' }} 
                        onClick={()=>{  handleMoveTo(activeId)}}>{captionMove}</Button>
                        </a>
                    </li>:            
                  </ul>
            </div>
            {/* <!-- .tab_menu -->*/}
          </div>
          {/* <!-- .col3 --> */}

          <div className="column col4" >
            <div className="dev_service">
              <h4 >{col4_title}</h4>
              <div className="movi_img">
              <video width="80%" height="80%" controls="controls" className="video-js vjs-default-skin" data-setup="{}"
                  poster="./mov/m1.jpg"  >
                      <source src="./mov/m1.mp4"  type="video/mp4" />
                  </video>
              </div>
            </div>
          </div>
          {/* <!-- .col4 --> */}

          <div className="column col5">
            <div className="inquiry">
              <h4 >{col5_title}</h4>
              <div className="inquiry_info">
                <span>{quotation_info[0]}</span>
                <strong>{quotation_info[1]}</strong>
                <em>{quotation_info[2]}</em>
                <a href="mailto:support@nexfield.net">{quotation_info[3]}</a>
              </div>
            </div>
            {/* <!-- 로그인 => 지금은 로그인 화면 대신 르네사스IDH 홍보화면으로 사용 --> */}
               <div  style={{ borderTop:"1px solid #ccc", borderBottom:"1px solid #aaa",height:"148px"  ,fontFamily: 'Hi Melody' }} >
                <div style={{float:"left", paddingTop: "2px", paddingLeft: "5px"}}>
                      <img src="img/biz_renesas.jpg" height="144px" alt="biz_etc"  /> 
                </div>
                <div style={{color:"#44f", fontSize:"25px", display:"block" , margin:"25px 10px " }}>
                  <span  style={{lineHeight: "40px"}} > <strong>RENESAS</strong> <br/></span>
                  <span  style={{lineHeight: "30px"}} >{biz_etc1}</span>
                </div>
              </div>
              

 {/* 
            <div id="login-wrap">
  
                <h4 >{login_info[0]}</h4>

                <form id="login_form" name="login_form" method="post" action="#">
                    <fieldset>
                        <legend className="ir_su">{login_info[1]}</legend>
                        <div className="login_header">
                            <h5 className="ir_su">{login_info[2]}</h5>
                            <div className="lh_check">
                                <input id="infor_check" type="checkbox" className="input_check"/>
                                <label htmlFor="infor_check">{login_info[3]}</label>
                            </div>

                            <div className="lh_ip">
                                {login_info[4] }<em>ON</em>
                            </div>
                        </div>
                        <div className="login_content">
                            <h5 className="ir_su">{login_info[5]}</h5>
                            <div className="lc_text">
                                <label htmlFor="uid" className="ir_su">{login_info[6]}</label>
                                <input type="text" id="uid" name="uid" className="input_text" maxLength="20" placeholder="아이디"/>
                                <label htmlFor="upw" className="ir_su">{login_info[7]}</label>
                                <input type="password" id="upw" name="upw" className="input_text" maxLength="20" placeholder="비밀번호"/>
                            </div>
                            <button className="lc_btn" type="submit"  disabled>{login_info[8]}</button>
                        </div>
                        
                        <div className="loing_footer">
                            <h5 className="ir_su">{login_footer_info[0]}</h5>
                            <ul>
                          
                              <li><a ><strong>{login_footer_info[1]}</strong></a> /</li>
                              <li><a >{login_footer_info[2]}</a></li>
                            </ul>
                        </div>
                    </fieldset>
                </form> 
            </div>
                */}
           
            </div>
            {/* <!-- .col5 --> */}

            {/* <!-- 게시판 탭메뉴는 col3에 구현되어 있으나.. 모바일에서는 최하단에 배치하기 위해 생성하되 감추어둔다 --> */}
            <div className="column col6" >
             {/* <!-- 탭 메뉴 --> */}          
             <div className="tab_menu" >
              <h4 className="ir_su">{col3_title}</h4>
              <ul>
                < li className="active" style={listStyle} ><a href="#">{miniBoard_titles[0]}</a>    
                  <ul style={{display:"block"}}><BoardList history={props.history}  lang={lang} board={'support'} boardType={"mini"} maxPageArticles={10} index={'1'}  /> </ul>
                </li>
                <li  style={listStyle} ><a href="#">{miniBoard_titles[1]}</a>
                  <ul><BoardList history={props.history}  lang={lang} board={'support'} boardType={"mini"} maxPageArticles={10} index={'2'}  /> </ul>
                </li>
                <li  style={listStyle} ><a href="#">{miniBoard_titles[2]}</a>
                  <ul><BoardList history={props.history}  lang={lang} board={'support'} boardType={"mini"} maxPageArticles={10} index={'3'}  /> </ul>
                </li>
                <li  style={listStyle} ><a href="#">{miniBoard_titles[3]}</a>
                  <ul><BoardList history={props.history}  lang={lang} board={'support'} boardType={"mini"} maxPageArticles={10} index={'4'}  /> </ul>
                </li>
              </ul>
                
              </div>
               
              </div>
            {/* <!-- .col6  --> */}

        </div>
        {/* <!-- .contents --> */}
      </div>
      {/* <!-- ._container --> */}
    </div>
    // #contents
    );

      {/* <script>
      //임시로.. 글목록을 추가했으나.. 서버페이지에 들어가는것이 맞다..
      function showBoard(id)
      {
            //액티비티 추가
          if(id==1)        var obj =  $(".tab_menu").find('>ul>li.active>ul').append('<li><a href="#">홈페이지 개편으로 게시판 서비스를 준비중입니다.</a><span>2018.12.29</span></li>'); // 태그 추가
          if(id==2)        var obj =  $(".tab_menu").find('>ul>li.active>ul').append('<li><a href="#">홈페이지 개편으로 자료실 서비스를 준비중입니다.</a><span>2018.12.29</span></li>'); // 태그 추가
          if(id==3)        var obj =  $(".tab_menu").find('>ul>li.active>ul').append('<li><a href="#">홈페이지 개편으로 서비스를 준비중입니다.</a><span>2018.12.29</span></li>'); // 태그 추가
          //console.log(obj);
      }
      </script>
   */}
}

export default HTMLContentHome;