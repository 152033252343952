import React from 'react'
import FormBoardContent from './FormBoardContent'

const styles = theme => ({
  hidden: {
    display: 'none'
  }
});

class ABoardModify extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      boardName: '',
      lang: '',
      index: '',
      num: '',
      form: 0,
      contents:'',
      writer:'',
      passwd:'',
      image:''
    }
    this.state.boardName = props.boardName;
    this.state.lang = props.lang;
    this.state.index = props.index;
    // this.state.form = props.form ? 1 : 0; //글쓰기 폼을 보임 
    this.state.num = props.num;
    this.state.contents=props.contents;

    this.state.writer = props.writer;
    this.state.passwd = props.passwd;
    this.state.image = props.image;
 

   }
  

  componentDidMount()
  {
    var show = this.props.form ?1 :0;
    if(show){
   
      this.setState({ form: show  });
    }
  }

 
  render() {
    const { classes } = this.props;
    var reply = this.props.reply;
    if(!reply) reply = 0;
       return (
        <div>
          {this.state.form ?
            //수정할때에는 수정글의 num을 전달해준다.
            <FormBoardContent boardName={this.state.boardName} lang={this.state.lang} index={this.state.index} num={this.state.num} 
            history={this.props.history}  modify={1} 
            writer={this.state.writer} passwd={this.state.passwd}   reply={this.props.reply} 
            stateRefresh={this.props.stateRefresh}  contents={this.state.contents} file={this.state.image} 
            />
            :
            null

          }   
        </div>
      )
    }
  }

  // export default withStyles(styles)(ABoardModify)
  export default ABoardModify;