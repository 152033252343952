import React, { Component } from 'react';
import '../../public/css/product.css'

// 언어설정을 props.lang 과 같이 바로 전달받지 못한다.
// 예전에는 NavLink를 통해서 props도 바로 전달 받았었는데... 이상하네.. 일단 주소줄에서 파싱해서 언어를 설정한다 . 

class SOM_NXM320 extends React.Component {
  constructor(props) {
    super(props);
  }
    
  render() {
    const dstyle = {
      color: "white",
      border: '2px solid #3d52ad',
      backgroundColor: '#eee',
      // height: "1040px"  // 헤더의 높이가 차감된듯 
    }

    const hstyle = {
      color: "black",
      fontFamily: "Arial"
     
    }

    //주소줄로 부터 언어설정을 파싱..
    var language = this.props.location.pathname.split('/')[1];

    var prod_title = (language=='ko')? "윈도우CE/ 모바일 /저전력 단말기용 초소형 CPU 모듈" : "WinCE/QT SOM (CPU Module)" ;
    var prod_subtitles = (language=='ko')? ["SOM(CPU모듈)사진", "적용된 사례"] : ["Front view", "Askew view"]  ;
    var prod_use = (language=='ko')? 
    {
      title: "적용분야",
      desc: ["• 핸드헬드/모바일 / 저전력/배터리 구동 단말기 :PDA / 바코더스캐너 / 결제기", "• CDMA / WiFi를 통한 데이터 송수신", "• 산업용 장비용 CPU모듈"]
    }:{
      title: "Application",
      desc: ["• Mobile / Handheld: PDA/ Barcode scaner", "• Wireless (CDMA/WiFI) communication  ", "• Industrial SOM"]
    }
    var prod_title_specs = (language=='ko')? ["하드웨어 사양(SOM_NXM320)", "소프트웨어 사양(SOM_NXM320)"] : ["Hardware Specification(SOM_NXM320)", "Software Specification(NXC320M)"]  ;

    return (
   
      <div className="prod_contents" div style={dstyle} >
        <br/>
        <div className="column">
          <div className="col_tab">
            <div className="col1">
              <h1 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "16px",fontStyle: 'italic', marginTop: '5px' }}>{prod_title}</h1>
              <hr/>
              <h2 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "14px"}}>{prod_subtitles[0]}</h2>
              <br/>
              <img  width="320" border="0" src="/img/product/som/nxm320.jpg"  />
            </div>
            <div className="col2">
              <h1 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "16px",fontStyle: 'italic', marginTop: '5px' }}>{prod_title}</h1>
              <hr/>
              <h2 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "14px"}}>{prod_subtitles[1]}</h2>
              <br/>
              <img  width="320"  border="0" src="/img/product/som/nxm320-ex.jpg"   />
            </div>
          </div>

          <div className="col3">
            <br/>

            <h1 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "16px",fontStyle: 'italic', marginTop: '5px' }}>{prod_use.title}</h1>
            <br/>
            <center>
            <table border="0" cellpadding="0" cellspacing="0"   width ="60%">
                <tr bgcolor="#c8bfe">
                    <ul>
                    <li  style={{fontSize:"18px"}}>{prod_use.desc[0]}</li>
                    <li  style={{fontSize:"18px"}}>{prod_use.desc[1]}</li>
                    <li  style={{fontSize:"18px"}}>{prod_use.desc[2]} </li>
                    </ul>
                 </tr>
                
              </table>
              <br/>    
            </center>
            <h1 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "16px",fontStyle: 'italic', marginTop: '5px' }}>{prod_title_specs[0]}</h1>
            <br/>
            <center>
        
              <table border="1" cellpadding="0" cellspacing="0"   width ="60%">                        
                <tr>
                  <td width="21%" bgcolor="#c8bfe">&nbsp;<b>Name</b></td>
                  <td width="79%" bgcolor="#c8bfe">&nbsp;<b>Description</b></td>
                </tr>
                <tr>
                  <td width="21%" bgcolor="#c8bfe">&nbsp;CPU</td>
                  <td width="79%">&nbsp;ARM11 Cortex 806MHz</td>
                </tr>
                <tr>
                  <td width="21%" bgcolor="#c8bfe">&nbsp;Memory</td>
                  <td width="79%">&nbsp;128MB both (Opt. 256MB)</td>
                </tr>
                <tr>
                  <td width="21%" bgcolor="#c8bfe">&nbsp;Display</td>
                  <td width="79%">&nbsp;TFT 800x480 16bit </td>
                </tr>
                <tr>
                  <td width="21%" bgcolor="#c8bfe">&nbsp;Touch</td>
                  <td width="79%">&nbsp;4 wire registitive</td>
                </tr>
                <tr>
                  <td width="21%" bgcolor="#c8bfe">&nbsp;Audio</td>
                  <td width="79%">&nbsp;AC97 Audio </td>
                </tr>
                <tr>
                  <td width="21%"  bgcolor="#c8bfe" rowspan="2">&nbsp;Wireless</td>
                  <td width="79%">&nbsp; CDMA </td>
                </tr>
                <tr>
                  <td width="79%">&nbsp; WiFi</td>
                </tr>
                
                <tr>
                  <td width="21%"  bgcolor="#c8bfe" rowspan="5">&nbsp;IO</td>
                  <td width="79%">&nbsp;USB Host/Function 1.0</td>
                </tr>
                <tr>
                  <td width="79%">&nbsp;SD 1 Slots</td>
                </tr>
                <tr>
                  <td width="79%">&nbsp;Serial 2 port (RS-232) + 1 debug Ports</td>
                </tr>
                <tr>
                  <td width="79%">&nbsp;External RTC with Backup battery</td>
                </tr>
              <tr>
                  <td width="79%">&nbsp; N/A</td>
                </tr>
              </table>
            </center>

            <br/>
            <h1 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "16px",fontStyle: 'italic', marginTop: '5px' }}>{prod_title_specs[1]}</h1>
            <br/>
            <center> 
            <table border="1" cellpadding="0" cellspacing="0" width ="60%">
              <tr>
                <td width="21%" bgcolor="#c8bfe" rowspan="2">&nbsp;Device&nbsp;
                <p/>&nbsp;</td>
                <td width="79%">&nbsp; Display/Audio/Touch/Serial/Eth/</td>
              </tr>
              <tr>
                <td width="79%">&nbsp; USB/SD/RTC/</td>
              </tr>
              <tr>
                <td width="21%"  bgcolor="#c8bfe" rowspan="4">&nbsp;USB</td>
                <td width="79%">&nbsp; HID - Mouse/ Keyboard , IR Mouse/ Keyboard / Storage</td>
              </tr>
              <tr>
                <td width="79%">&nbsp; Storage</td>
              </tr>
              <tr>
                <td width="79%">&nbsp; I/O - WLAN /BT</td>
              </tr>
              <tr>
                <td width="79%">&nbsp; Gadget -Serial, RNDIS gadget</td>
              </tr>

              <tr>
                <td width="21%"  bgcolor="#c8bfe" rowspan="5">&nbsp;Cutomizing</td>
                <td width="79%">&nbsp; Boot Logo </td>
              </tr>
              <tr>
                <td width="79%">&nbsp; Custom OS</td>
              </tr>
                    
             </table>
            </center>
          </div>
          <div className="col4">
            
          </div>
          <div className="col5">
            
          </div>
          <div className="col6">
           
          </div>
          <div className="col7">
            
          </div>
        </div>
      </div>
    );
  }
}

export default SOM_NXM320;
