import React from 'react';
import {BrowserRouter as Router,Route,Switch,NavLink, Redirect, useParams} from 'react-router-dom';

import BoardListQna  from '../support/BoardListQna'
import BoardListRefRoom  from '../support/BoardListRefRoom'
import BoardListInquiry  from '../support/BoardListInquiry'
import BoardListNews  from '../support/BoardListNews'

import BoardContent  from '../support/BoardContent'

import BoardList  from '../support/BoardList'

const SupportHeader = ({lang}) => {
  var titles = (lang=='ko')? ["견적요청(영업문의)","게시판(기술문의)","자료실(기술자료)","액티비티"] :
  ["Quotation/Inquiry","Board(Q&A)", "Reference room","News"];

  return (
      <div className='prod_header'>
          <NavLink  exact to= {"/" + lang + "/support/1/1"} lang={lang} className='prod_item' activeClassName="active">{titles[0]}</NavLink>
          <NavLink  exact to= {"/" + lang + "/support/2/1"} lang={lang} className='prod_item' activeClassName="active">{titles[1]}</NavLink>
          <NavLink  exact to= {"/" + lang + "/support/3/1"} lang={lang} className='prod_item' activeClassName="active">{titles[2]}</NavLink>
          <NavLink  exact to= {"/" + lang + "/support/4/1"} lang={lang} className='prod_item' activeClassName="active">{titles[3]}</NavLink>          
      </div>
  );
};

function ContentSupportRoute({lang, index})
{

  var components = [ BoardListInquiry,BoardListQna,BoardListRefRoom,BoardListNews];
  switch(index){
    case 1:
    case 2:
    case 3:
    case 4:
      return  <div>
        <SupportHeader lang= {lang} />
        <Switch>
          <Route exact path={'/'+ lang +`/support/:index/:subindex`}  component={components[index-1]} />
          <Route  path={'/'+ lang +`/support/:index/:subindex` }  component={BoardContent} />
        </Switch>
      </div>
    } //switch

    return  <div></div>
}

 function HTMLContentSupport(props) 
{
  
    var params = useParams();
    // var lang = props.location.pathname.split('/')[1];
    
    var lang = (params.language)? params.language:props.lang;

    var index = (params.id)?  parseInt(params.id): parseInt(props.index);
  
    //높이 설정 
    const dstyle = {
      height: "1600px"
    }

    return (
      <div className="contents_cont"  id="s_contents" >
        <div className="_container">
          <div className="contents_wrap">
            <ContentSupportRoute lang={lang} index={index} />
        </div>
      </div>
     </div>
  );
}

 
  export default HTMLContentSupport;