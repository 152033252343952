import React from 'react';
import {BrowserRouter as Router,Route,Switch,NavLink, Redirect, useParams} from 'react-router-dom';

import BoardListWork  from '../support/BoardListWork'
import BoardListRefRoom  from '../support/BoardListRefRoom'

import BoardContent  from '../support/BoardContent'

import AdminManagementContent  from '../admin/AdminManagementContent'


const AdminHeader = ({lang}) => {
  var titles = (lang=='ko')? ["일일 업무 (사내 업무 진행사항)","기술 자료실(사내 자료)","관리 및 통계"] :
  ["Works", "Reference room", "Management/Statistics"];

  return (
  <div className='prod_header'>
      <NavLink  exact to= {"/" + lang + "/admin/1"} lang={lang} className='prod_item' activeClassName="active">{titles[0]}</NavLink>
      <NavLink  exact to= {"/" + lang + "/admin/2"} lang={lang} className='prod_item' activeClassName="active">{titles[1]}</NavLink>
      <NavLink  exact to= {"/" + lang + "/admin/3"} lang={lang} className='prod_item' activeClassName="active">{titles[2]}</NavLink>
  </div>
  );
};



function ContentAdminRoute({lang, index})
{
  switch(index){
    case 1:
        return  <div>
          <AdminHeader lang= {lang} />
          <Switch>
            <Route exact path={`/admin/${index}`}><Redirect to={'/'+ lang +`/admin/${index}`} /></Route>
            <Route exact path={'/'+ lang +`/:board/${index}`}  component={BoardListWork} />
            {/* 게시글 내용보기  */}
            <Route  path={'/'+ lang +`/:board/${index}` }  component={BoardContent} />
          </Switch>
        </div>
    
    case 2:
      return  <div>
        <AdminHeader lang= {lang} />
        <Switch>
          <Route exact path={`/admin/${index}`}><Redirect to={'/'+ lang +`/admin/${index}`} /></Route>
          <Route exact path={'/'+ lang +`/:board/${index}`}  component={BoardListRefRoom} />
          {/* 게시글 내용보기  */}
          <Route  path={'/'+ lang +`/:board/${index}` }  component={BoardContent} />
        </Switch>
      </div>
  

  case 3:
      return  <div>
        <AdminHeader lang= {lang} />
        <Switch>
        <Route exact path={`/admin/3`}><Redirect to={'/'+ lang +`/admin/${index}`} /></Route>
        <Route exact path={`/admin/${index}`}><Redirect to={'/'+ lang +`/admin/${index}`} /></Route>
            {/* 게시판 관리/설정 내용보기  */}
            <Route  path={'/'+ lang +`/:board/${index}` }  component={AdminManagementContent} />
         </Switch>
      </div>
  


    } //switch

    return  <div></div>
}

function HTMLContentAdmin(props) 
{
  
    var params = useParams();
     // var lang = props.location.pathname.split('/')[1];
     var lang = params.language;
     var index =  parseInt(params.id);
    var index2 = props.location.pathname.split('/')[3];
 
    //높이 설정 
    const dstyle = {
      height: "1600px"
    }

    return (
      <div className="contents_cont"  id="s_contents" >
        <div className="_container">
          <div className="contents_wrap">
            <ContentAdminRoute lang={lang} index={index} />
          </div>
        </div>
       </div>
    );
 }

export default HTMLContentAdmin;







