import React from 'react';
import {BrowserRouter as Router,Route,Switch,NavLink, Redirect, useParams} from 'react-router-dom';

import BoardListQna  from '../support/BoardListQna'
import BoardListRefRoom  from '../support/BoardListRefRoom'
import BoardListInquiry  from '../support/BoardListInquiry'
import BoardListNews  from '../support/BoardListNews'

import BoardContent  from '../support/BoardContent'


const AdminManagementContent_BoardsHeader = ({lang}) => {
  var titles = (lang=='ko')? ["견적요청(영업문의)","게시판(기술문의)","자료실(기술자료)","공지사항"] :
  ["Quotation/Inquiry","Board(Q&A)", "Reference room","News"];

  return (
      <div className='prod_subheader_2'>
          <NavLink  exact to= {"/" + lang + "/admin/3/3/1"} lang={lang} className='prod_item' activeClassName="active">{titles[0]}</NavLink>
          <NavLink  exact to= {"/" + lang + "/admin/3/3/2"} lang={lang} className='prod_item' activeClassName="active">{titles[1]}</NavLink>
          <NavLink  exact to= {"/" + lang + "/admin/3/3/3"} lang={lang} className='prod_item' activeClassName="active">{titles[2]}</NavLink>
          <NavLink  exact to= {"/" + lang + "/admin/3/3/4"} lang={lang} className='prod_item' activeClassName="active">{titles[3]}</NavLink>          
      </div>
  );
};



function AdminManagementContent_BoardsRoute({lang, index})
{
  var idx = parseInt(index);
  // var board = 'admin';
  switch(idx){
    case 1:
      return  <div>
      <AdminManagementContent_BoardsHeader lang= {lang} />
      <Switch>
        <Route exact path={`/:board/3/3/${index}`}><Redirect to={'/'+ lang +`/:board/3/3/${index}`} /></Route>
        <Route exact path={'/'+ lang +`/:board/3/3/:index`}  component={BoardListInquiry} />
        <Route  path={'/'+ lang +`/:board/3/3/:index` }  component={BoardContent} /> {/* 게시글 내용보기  */}
      </Switch>
    </div>
  
    case 2:
      return  <div>
      <AdminManagementContent_BoardsHeader lang= {lang} />
      <Switch>
        < Route exact path={`/:board/3/3/${index}`}><Redirect to={'/'+ lang +`/:board/3/3/${index}`} /></Route>
        <Route exact path={'/'+ lang +`/:board/3/3/:index`}  component={BoardListQna} />
        <Route  path={'/'+ lang +`/:board/3/3/:index` }  component={BoardContent} /> {/* 게시글 내용보기  */}
      </Switch>
    </div>

  case 3:
    return  <div>
    <AdminManagementContent_BoardsHeader lang= {lang} />
    <Switch>
      <Switch>
        < Route exact path={`/:board/3/3/${index}`}><Redirect to={'/'+ lang +`/:board/3/3/${index}`} /></Route>
        <Route exact path={'/'+ lang +`/:board/3/3/:index`}  component={BoardListRefRoom} />
        <Route  path={'/'+ lang +`/:board/3/3/:index` }  component={BoardContent} /> {/* 게시글 내용보기  */}
      </Switch>      
    </Switch>
  </div>

  case 4:
        return  <div>
      <AdminManagementContent_BoardsHeader lang= {lang} />
      <Switch>
        < Route exact path={`/:board/3/3/${index}`}><Redirect to={'/'+ lang +`/:board/3/3/${index}`} /></Route>
        <Route exact path={'/'+ lang +`/:board/3/3/:index`}  component={BoardListNews} />
        <Route  path={'/'+ lang +`/:board/3/3/:index` }  component={BoardContent} /> {/* 게시글 내용보기  */}
      </Switch>
    </div>
  
  
    } //switch

    return  <div></div>
}






function AdminManagementContent_Boards(props) 
{
    // var params = useParams();
    var lang = props.location.pathname.split('/')[1];
    var sIndex = (props.match.params.index)?props.match.params.index:1;
   
   return (    
    <div >
        <AdminManagementContent_BoardsRoute lang={lang}index={sIndex} />
    </div>
   
    );
 }

export default AdminManagementContent_Boards;







