import React, { Component } from 'react';



class FormServiceBrief extends Component {

    constructor(props) {
        super(props);
    }

    
    render() {

        const {formContent} = this.props;
  
        const hstyle = {
        color: "black",
        backgroundColor: formContent.bgcolor[0],
        fontFamily: "Arial",
        fontSize : "40px",
        paddingLeft:"5px",
        textAlign : 'center',
        verticalAlign:"top"
        }

        const istyle = {
            backgroundColor: formContent.bgcolor[1],
            padding : "5px"
        }
        
        const tstyle = {
            color: "black",
            backgroundColor: formContent.bgcolor[2],
            fontFamily: "Arial",
            fontSize : "12px",
            paddingLeft: "20px",
            border: "0"
        
        }

     
        const captionstyle = {
            color: "purple",
            backgroundColor: formContent.bgcolor[2],
            fontFamily: "Arial",
            fontSize : "16px",
            paddingTop: "5px" ,
            paddingLeft: "20px" ,
            borderBottom : "0"     
        }
     

        return (
            <div>
               
               <center>
  
                <table  border="1" cellpadding="0" cellspacing="0"   width ="60%">
                    <tr>
                        <td width={"140px"} style={hstyle} rowspan="6">{formContent.year}</td>
                        <td  width={formContent.iWidth}  rowspan="6" >
                            <img  style={istyle} border="0" src={formContent.image}  width={formContent.iWidth}  height={formContent.iHeight} align="center" />
                        </td>
                    </tr>
             
                    <tr><td style={captionstyle}   >  { formContent.prod}</td></tr>
                    <tr><td style={tstyle}   >      { formContent.spec[0]}</td></tr>
                    <tr><td style={tstyle}   >      { formContent.spec[1]}</td></tr>
                    <tr><td style={tstyle}   >      { formContent.spec[2]}</td></tr>
                    <tr><td style={tstyle}   >      { formContent.spec[3]}</td></tr>
                    {/* <tr><td style={tstyle}   >      { formContent.spec[4]}</td></tr>
                    <tr><td style={tstyle}   >      { formContent.spec[5]}</td></tr>
                    <tr><td style={captionstyle}  >   { formContent.title}</td></tr>
                    <tr><td style={tstyle}   >      { formContent.apply[0]}</td></tr>
                    <tr><td style={tstyle}   >      { formContent.apply[1]}</td></tr> */}
                </table>
             </center>
            </div>
        );
    }
}


export default FormServiceBrief;