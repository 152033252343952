import React, { Component } from 'react';
import BoardList from './BoardList';

class BoardListInquiry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang:'',
            board:'',
            index:1
        }
        this.state.board = (props.match.params.board)?props.match.params.board:'support';
        this.state.index = (props.match.params.index)?props.match.params.index:1;
    }
   
    
    render() {
        var language = this.props.location.pathname.split('/')[1];
     
        return (
            <div>
                 {/* index props : 게시판의 종류 */}
                 <BoardList history={this.props.history} lang={language} board={this.state.board} index={this.state.index} maxPageArticles={15} />
            </div>
        );
    }
}

export default BoardListInquiry;