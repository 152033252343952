import React, { Component, Fragment } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import { ENV } from '../constants/variables'
// import { getToken } from "../services/auth"

class CKCustomEditor extends React.Component{

    constructor(props) {
        super(props);
      }



  render(){
    const { content, value, onChange } = this.props // <- Dont mind this, just handling objects from props because Im using this as a shared component.

    const custom_config={
        language:'ko',
        extraPlugins: [ MyCustomUploadAdapterPlugin ],


        toolbar: {
          // items:
          // [
          //   'heading', '|', 
          //   'alignment',  
          //   'bold', 'italic', 'highlight', 'link', 'bulletedList', 
          //   'numberedList', 'imageUpload', 'blockQuote', 'insertTable', 
          //   'mediaEmbed', 'undo', 'redo'
          // ],

        },
        image: {
          toolbar: [
              'imageStyle:full',
              'imageStyle:side',
              '|',
              'imageTextAlternative'
          ]
        },
        heading: {
            options: [
                { model: 'heading1', view: 'h1', title: '헤더1', class: 'ck-heading_heading1' },
                { model: 'heading2', view: 'h2', title: '헤더2', class: 'ck-heading_heading2' },
                { model: 'heading3', view: 'h3', title: '헤더3', class: 'ck-heading_heading3' },
                { model: 'paragraph', title: '본문', class: 'ck-heading_paragraph' },
            ]
        },
        // ckfinder: {
        //   // uploadUrl: 'http://api.dev.mustrip.io/meetup/upload/files/'
        //   uploadUrl: 'http://localhost:5000/api/upload/'
        // },

      }



    return(
        <CKEditor  
        editor={ClassicEditor}
        data={content} 
        config={custom_config}
        onChange={ ( event, editor ) => {
            var data = editor.getData();
            var temp = data ;

            // temp = temp.replace(/<figure(.*?)>/gi,'').replace( /<img(.*?)>/gi,'').replace(/<\/figure>/gi,''); //파일명은 따로 분리해야한다 
           this.setState({content:temp});  //텍스트만 분리 한다
            
           //console.log( { event, editor, data } );
        } }
        
        onInit={editor => {
          editor.editing.view.change(writer => {
              writer.setStyle(
                "height",
                "600px",
                editor.editing.view.document.getRoot()
              );
            });
        }}
     
    /> 
    )
  }
}

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get( 'FileRepository' ).createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader)
  }
}

class MyUploadAdapter {
    constructor(props) {
        // CKEditor 5's FileLoader instance.
      this.loader = props;
      // URL where to send files.
      this.url = `http://localhost:5000/api/upload`;
    }

    // Starts the upload process.
    upload() {
        return new Promise((resolve, reject) => {
            this._initRequest();
            this._initListeners(resolve, reject);
            this._sendRequest();
        } );
    }

    // Aborts the upload process.
    abort() {
        if ( this.xhr ) {
            this.xhr.abort();
        }
    }

    // Example implementation using XMLHttpRequest.
    _initRequest() {
        const xhr = this.xhr = new XMLHttpRequest();

        xhr.open('POST', this.url, true);
        xhr.responseType = 'json';
        // xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
        // xhr.setRequestHeader('Authorization', getToken())
    }


    // Initializes XMLHttpRequest listeners.
    _initListeners( resolve, reject ) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = 'Couldn\'t upload file:' + ` ${ loader.file.name }.`;

        xhr.addEventListener( 'error', () => reject( genericErrorText ) );
        xhr.addEventListener( 'abort', () => reject() );
        xhr.addEventListener( 'load', () => {
            const response = xhr.response;
            if ( !response || response.error ) {
                return reject( response && response.error ? response.error.message : genericErrorText );
            }

            // If the upload is successful, resolve the upload promise with an object containing
            // at least the "default" URL, pointing to the image on the server.
            resolve({
                default: response.s3Url
            });
        } );

        if ( xhr.upload ) {
            xhr.upload.addEventListener( 'progress', evt => {
                if ( evt.lengthComputable ) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            } );
        }
    }

    // Prepares the data and sends the request.
    _sendRequest() {
        const data = new FormData();

        this.loader.file.then(result => {
          data.append('file', result);
          this.xhr.send(data);
          }
        )
    }

}

export default CKCustomEditor