import React, { Component } from "react";
import { Navbar, Button, Image } from "react-bootstrap";
import {NavLink,Link} from 'react-router-dom'
import axios from "axios";
import $ from "jquery";
import {} from "jquery.cookie";

import './public/css/reset.css';
import './public/css/style-main.css';
import HTMLHeader from "./app/HTMLHeader";
import HTMLCommonNaviTitle from "./app/HTMLCommonNaviTitle";
import HTMLCommonNaviContent from "./app/HTMLCommonNaviContent";

axios.defaults.withCredentials = true;
const headers = { withCredentials: true };



class Header extends React.Component 
{
  constructor(props) {
    super(props);
    this.state = {
       buttonDisplay:"block",
       subpath:props.subpath,
    }
   }

  
  componentDidMount() {
  }


  renderHeader(props){

    var lang=this.props.lang,cbChangeLang=this.props.cbChangeLang,cbChangeScreen=this.props.cbChangeScreen ;
  
    var pathname='/'+this.props.history.location.pathname.split('/')[1];
   var sel=this.props.subpath;
    var root_id= (sel==('/'+lang)) ? '' :  's_root';

      return (
        <div id={root_id}>
            {/* 홈화면 */}
            {
              (sel==('/'+lang))?  
                <div id='wrap_bg'> 
                  <HTMLHeader lang={lang} sel={sel} cbChangeLang={cbChangeLang} cbChangeScreen={cbChangeScreen}/>
                </div>
              :
              <div id='wrap_bg'> 
                <HTMLHeader lang={lang} sel={sel} cbChangeLang={cbChangeLang} cbChangeScreen={cbChangeScreen}/>
                <div id='nav_tit'>      
                //                   <HTMLCommonNaviTitle lang={lang} pathname={sel} /> 
                </div> 
                <div id="cont_nav">
                  <div className="_container">
                    <h2 className="ir_su">All menu</h2>
                    <HTMLCommonNaviContent lang={lang} pathname={sel} /> 
                  </div>
                </div>
              </div>  
            }
          </div>
       )
   }

  render() {
     
    return this.renderHeader(this.props);
   
  }
}

export default Header;
