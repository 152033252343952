import React from 'react'

import FormBoardContent from './FormBoardContent'

const styles = theme => ({
  hidden: {
    display: 'none'
  }
});

class ABoardAdd extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      boardName: '',
      lang: '',
      index: '',
      num: '',
      ref_num: '',
      edit: '',    
      form: 0
    }
    this.state.boardName = props.boardName;
    this.state.lang = props.lang;
    this.state.index = props.index;
   
    this.state.num = props.num ? props.num :'';
    this.state.ref_num = props.ref_num ? props.ref_num :''; //졍렬번호 
    this.state.edit = props.edit;
    this.state.form = props.form ? 1 : 0; //글쓰기 폼을 보임 
  }
  
  componentDidMount()
  {
    var show = this.props.form ?1 :0;
    if(show){
      this.setState({ content:{ form: 1}});
    }
  }

  render() {
    const { classes } = this.props;

    var reply = this.props.reply;
    if(!reply) reply = 0;
  
      return (
        <div>
          {this.state.form ?
            //새로 추가할 때에는 글번호(num)은 없다. -1로 초기화 해둔다 
            <FormBoardContent boardName={this.state.boardName} lang={this.state.lang} index={this.state.index} num={this.state.num }  ref_num={this.state.ref_num } modify={0}  reply={this.props.reply} 
             stateRefresh={this.props.stateRefresh} contents={null} file={null}
            />
            :
            null
          }    
        </div>
      )
    }
  }

  // export default withStyles(styles)(ABoardAdd)
  export default ABoardAdd;