import React, { Component } from 'react';

import FormMovieClip from './FormMovieClip';


class ContentMovieClip extends Component {
    constructor(props) {
        super(props);
      }

    render() {
     var lang = this.props.location.pathname.split('/')[1];
   
        var formContents = 
        [
            // 플랫폼1 : 리눅스 
            {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee"],
                    image : "/img/product/odm/logo-linux-small.png",   
                    poster : "/mov/linux-yocto-demo.jpg",  
                    movie : "/mov/linux-yocto-demo.mp4",  
                    iWidth: "240px", iHeight:"240px",
                    titles: ["리눅스 / 욕토/ 큐티" ,"적용분야",  "보유 디자인", "핵심역량"],
                    desc:[
                        ["임베디드 리눅스"],
                        ["리눅스 YOCTO"],
                        ["QT / QT QUICK(QML)"],
                    ] ,
                    apply :   [
                        ["산업용 HMI / 디스플레이"],
                        ["산업용터미널"],
                        ["각종 ODM 제품"],

                    ] ,
                    cap :   [
                        ["IMX6 / IMX51 "],
                        ["TCC8970/ TCC8930 / TCC8925"],
                        ["RK3288"],
                        ["AT91"],

                    ],
                    co :   [
                        ["ODM생산납품 및 제품유지보수"],
                        ["H/W 설계 및 인증취득(KC+RF)"],
                        ["Linux 커널 포팅"],
                        ["구동소프트웨어 제작 (리눅스/큐티/GTK)"],

                    ]
         
                },

               en: {
                    bgcolor : [ "#ddeeee","#ddeeee" ],
                    year : "2019",   
                    image : "/img/product/odm/logo-linux-small.png", 
                    poster : "/mov/linux-yocto-demo.jpg",  
                    movie : "/mov/linux-yocto-demo.mp4",  
                    iWidth: "240px", iHeight:"240px",
                    titles: ["Linux/QT/YOCTO", "Application", "Capability", "Snapshot/ Movie"],
                    desc:[
                        ["Embeded Linux"],
                        ["Linux Yocto YOCTO"],
                        ["QT / QT QUICK(QML)"]
                    ],
                    apply :   [
                        ["Industrial HMI "],
                        ["Handheld terminal"],
                        ["ODM products"],

                    ] ,
                    cap :   [
                        ["H/W design & Cerification(KC+RF)"],
                        ["ODM Manufacturing"],
                        ["Linux porting"],
                        ["Embedded S/W "],

                    ],
                    co :   [
                        ["OHW Design / ODM"],
                        ["Linux kernel / QT Porting"],
                        ["Linux GUI (QT/GTK)"],
                        ["Product maintenance"]

                    ]
              }
            },
            
             // 플랫폼2 : 안드로이드 
             {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee"],
                    image : "/img/product/odm/logo-android-small.png",   
                    poster : "/mov/android-demo.jpg",  
                    movie : "/mov/android-demo.mp4",  
                    iWidth: "240px", iHeight:"240px",
                    titles: ["안드로이드" ,"적용분야",  "보유 디자인", "핵심역량"],
                    desc:[
                        ["산업용 안드로이드"],
                        ["커스텀 디바이스 지원"],
                        ["커스텀 안드로이드 제품"],
                    ] ,
                    apply :   [
                        ["산업용 HMI / 디스플레이"],
                        ["핸드헬드 터미널"],
                        ["각종 ODM 제품"],

                    ] ,
                    cap :   [
                        ["IMX6 / IMX51 "],
                        ["TCC8970/ TCC8930 / TCC8925"],
                        ["RK3288"],
                        ["AT91"],

                    ],
                    co :   [
                        ["ODM생산납품 및 제품유지보수"],
                        ["Android 커널 포팅"],
                        ["소프트웨어/앱 개발 "],
                        [""]
                    ]
         
                },

               en: {
                    bgcolor : [ "#ddeeee","#ddeeee" ],
                    year : "2019",   
                    image : "/img/product/odm/logo-android-small.png", 
                    poster : "/mov/android-demo.jpg",  
                    movie : "/mov/android-demo.mp4",  
                    iWidth: "240px", iHeight:"240px",
                    titles: ["Android", "Application", "Capability", "Snapshot/ Movie"],
                    desc:[
                        ["Industrial Android"],
                        ["Industrial device support "],
                        ["Custom android product"]
                    ],
                    apply :   [
                        ["Industrial HMI "],
                        ["Handheld terminal"],
                        ["ODM products"],

                    ] ,
                    cap :   [
                        ["H/W design & Cerification(KC+RF)"],
                        ["ODM Manufacturing"],
                        ["Linux porting"],
                        ["Embedded S/W "],

                    ],
                    co :   [
                        ["OHW Design / ODM"],
                        ["Linux kernel / Framework Porting"],
                        ["Android app"],
                        ["Product maintenance"]

                    ]
              }
            },       

           // 플랫폼3 : 윈CE 
           {
            ko: {
                bgcolor : [ "#ddeeee","#ddeeee"],
                image : "/img/product/odm/logo-wce-small.png",   
                poster : "/mov/wce-demo.jpg",  
                movie : "/mov/wce-demo.mp4",  
                iWidth: "240px", iHeight:"240px",
                titles: ["윈도우CE" ,"적용분야",  "보유 디자인", "핵심역량"],
                desc:[
                    ["산업용 단말기"],
                    ["모바일/핸드헬드"],
                    ["커스텀 제품"],
                ] ,
                apply :   [
                    ["산업용 HMI / 디스플레이"],
                    ["핸드헬드 터미널"],
                    ["각종 ODM 제품"],

                ] ,
                cap :   [
                    ["IMX6 / IMX51 "],
                    ["TCC8970/ TCC8930 / TCC8925"],
                    ["RK3288"],
                    ["AT91"],

                ],
                co :   [
                    ["ODM생산납품 및 제품유지보수"],
                    ["BSP 포팅"],
                    ["소프트웨어/앱 개발 "],
                    [""]
                ]
     
            },

           en: {
                bgcolor : [ "#ddeeee","#ddeeee" ],
                year : "2019",   
                image : "/img/product/odm/logo-wce-small.png", 
                poster : "/mov/wce-demo.jpg",  
                movie : "/mov/wce-demo.mp4",  
                iWidth: "240px", iHeight:"240px",
                titles: ["WinCE", "Application", "Capability", "Snapshot/ Movie"],
                desc:[
                    ["Industrial Terminal"],
                    ["Industrial device support "],
                    ["Custom product"]
                ],
                apply :   [
                    ["Industrial HMI "],
                    ["Handheld terminal"],
                    ["ODM products"],

                ] ,
                cap :   [
                    ["H/W design & Cerification(KC+RF)"],
                    ["ODM Manufacturing"],
                    ["WinCE porting"],
                    ["Embedded S/W "],

                ],
                co :   [
                    ["OHW Design / ODM"],
                    ["WinCE kernel / Framework Porting"],
                    ["Embedded Softwares"],
                    ["Product maintenance"]

                ]
          }
        },       
        
        
           // 플랫폼4 : 마이컴  
           {
            ko: {
                bgcolor : [ "#ddeeee","#ddeeee"],
                image : "/img/product/odm/logo-fw-small.png",   
                poster : "",  
                movie : "",  
                iWidth: "240px", iHeight:"240px",
                titles: ["펌웨어/마이컴" ,"적용분야",  "보유 디자인", "핵심역량"],
                desc:[
                    ["IOT센서/에지 "],
                    ["펌웨어제품"],
                    ["아두이노 호환제품"],
                ] ,
                apply :   [
                    ["IOT센서/IOT 에지"],
                    ["각종 제어보드"],
                    ["각종 ODM 제품"],

                ] ,
                cap :   [
                    ["ATMEGA "],
                    ["Nordic"],
                    ["PIC"],
                    ["AT91"],

                ],
                co :   [
                    ["ODM생산납품 및 제품유지보수"],
                    ["마이컴 펌웨어 개발"],
                    ["소프트웨어/앱 개발 "],
                    [""]
                ]
     
            },

           en: {
                bgcolor : [ "#ddeeee","#ddeeee" ],
                year : "2019",   
                image : "/img/product/odm/logo-fw-small.png", 
                poster : "",  
                movie : "",  
                iWidth: "240px", iHeight:"240px",
                titles: ["MiCOM", "Application", "Capability", "Snapshot/ Movie"],
                desc:[
                    ["Industrial conteroller"],
                    ["Industrial device support "],
                    ["Custom product"]
                ],
                apply :   [
                    ["Industrial products "],
                    ["control board"],
                    ["ODM products"],

                ] ,
                cap :   [
                    ["H/W design & Cerification(KC+RF)"],
                    ["ODM Manufacturing"],
                    ["RTOS porting"],
                    ["Embedded Firware "],

                ],
                co :   [
                    ["OHW Design / ODM"],
                    ["Firmware"],
                    ["RTOS p"],
                    ["Product maintenance"]

                ]
          }
        },               
                                   
        ] ;

        const dstyle = {
            color: "white",
            border: '2px solid #3d52ad',
            backgroundColor: '#eee',
            // height: "1040px"  // 헤더의 높이가 차감된듯 
          }
        return (
          
        <div className="odm_contents"  style={dstyle} >
            <br/>
            { (lang=='ko')? <FormMovieClip formContent={formContents[0].ko}/> : <FormMovieClip formContent={formContents[0].en}/>}  
            <br/>
            { (lang=='ko')? <FormMovieClip formContent={formContents[1].ko}/> : <FormMovieClip formContent={formContents[1].en}/>}  
            <br/>
            { (lang=='ko')? <FormMovieClip formContent={formContents[2].ko}/> : <FormMovieClip formContent={formContents[2].en}/>}  
            <br/>
            { (lang=='ko')? <FormMovieClip formContent={formContents[3].ko}/> : <FormMovieClip formContent={formContents[3].en}/>}  
            <br/>
         
              

        </div>
      );
    }
}

export default ContentMovieClip;
