import React from 'react';
import {NavLink,Link} from 'react-router-dom'

//이부분은 (홈화면을 제외한 화면들에서만 적용된다) - 추후 정리한다 

// 라우터를 통해서 넘어오는 props와 Header를 통하는 경우가 서로 다르다
function HTMLCommonNaviTitle(props) 
{
  var hgt = '300px';
  var navi_title =[];
  // var params = props.pathname.split('/');
  // var subIndex = Number(params[3]);
   // var pathname =params[2];
  var pathname = props.pathname.split('/')[1];
  var lang = props.lang;
  var index=0;
  
  switch(pathname){
    case 'company': index=1; break;
    case 'product': index=2; break;
    case 'odm':     index=3; break;
    case 'service': index=4; break;
    case 'support': index=5; break;
    default:    break;                
  }
  //debugger;
  if(lang==='en') {
    navi_title =  [
      'Inquiry for ODM /Technical Service :  (+82) 2057-2422',
      'Industrial Products/ ODM /Technical Service :  (+82) 2057-2422',
      'Industrial Products ODM :  (+82) 2057-2422',
      'Hardware & Software Design /Technical Service :  (+82) 2057-2422',
      'Inquiry /Technical Service :  (+82) 2057-2422'

    ]
  }else{
    navi_title =  [
      'ODM 및 각종 문의 : (02) 2057-2422',
      '산업용 제품 개발 및 공급 - 문의 : (02) 2057-2422',
      '산업용 제품 ODM 전문 - 문의 : (02) 2057-2422',
      '하드웨어설계 및 소프트웨어 개발 용역 : (02) 2057-2422',
      '기술지원 및 영업문의 : (02) 2057-2422'
    ]  
   }
   //debugger;

  return (    
  //  베너에 대한 디스크립션
    <div className="container">
      <div className="tit">
        <a className="btn">{navi_title[index-1]}</a>
      </div>
    </div>
  );
}
export default HTMLCommonNaviTitle;