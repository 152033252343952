import React from 'react';
import '../../App.css';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
// import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import shortid from 'shortid';

import {BrowserRouter as Router,Route,Switch,NavLink,Link,Redirect} from 'react-router-dom';
import ABoardAdd from '../board/ABoardAdd';
// import icon_re from '../../assets/icon_re.gif';
import icon_re from '../assets/icon_re.gif';

var dateFormat = require('dateformat');

const  MAX_PAGE_ARTICLES_DEFAULT = 10; //페이지에 표시되는 글의 수 
const  MINI_BOARD_SUBJECT_LEN  = 25;

const styles = theme => ({
  root: {
    width: "100%",
    // minWidth: 1080
  },
  menu: {
    marginTop: 15,
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'center'
  },
  paper: {
    marginLeft: 5,
    marginRight: 10
  },
  progress: {
    margin: theme.spacing.unit * 2
  },
  grow: {
    flexGrow: 1,
  },
  tableHead: {
    fontSize: '1.0rem'
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  }
});


class PageInfo extends React.Component {
 
  constructor(props) {
    super(props);
    this.state = {
      activePage:0,
      maxPageArticles:0
    }
    this.state.activePage = props.pageNo;
    this.state.maxPageArticles = props.maxPageArticles;
  }

 
  initPageInfo = () => {
    var nPages = Math.ceil(this.props.total / this.state.maxPageArticles);
    let outputHTML = []
   
    var cbOnNPrevPageGroup  = this.props.onPrevPageGroup; // 다음 페이지(그룹)
    var cbOnNextPageGroup  = this.props.onNextPageGroup; // 다음 페이지(그룹)

    var cbOnPageClick  = this.props.onPageClick;  

    // 이전 그룹 
    outputHTML.push( <><Link key={shortid.generate()} to={()=>{javascript:void(0)} }  ><span>&#9664;</span></Link>&nbsp;&nbsp;&nbsp;</>)
    for (let j = 0; j < nPages; j++) 
      outputHTML.push(<Link key={shortid.generate()} to={()=>{javascript:void(0)} }  onClick={()=>{cbOnPageClick(j)}}  >
                      { j== this.state.activePage?
                        <span className="jb active">{j+1}</span>: <span className="jb">{j+1}</span>
                      }
                      </Link>)
   // 다음 그룹 
    outputHTML.push( <>&nbsp;&nbsp;&nbsp;<Link key={shortid.generate()} to={()=>{javascript:void(0)} }   ><span>&#9654;</span></Link></>)

    return outputHTML
  }


//   initPageInfo = () => {
//     var nPages = Math.ceil(this.props.total / this.state.maxPageArticles);
//     let outputHTML = []

//     var cbOnNPrevPageGroup  = this.props.onPrevPageGroup; // 다음 페이지(그룹)
//     var cbOnNextPageGroup  = this.props.onNextPageGroup; // 다음 페이지(그룹)

//     var cbOnPageClick  = this.props.onPageClick;  

//     // 이전 그룹 
//    outputHTML.push( <Link key={shortid.generate()} to={()=>{javascript:void(0)} } ><span>&#9664;</span></Link>)
//    let j = 0;
//    for ( j = 0; j < nPages; j++) 
//      outputHTML.push(<Link key={shortid.generate()+j+1} to={()=>{javascript:void(0)} } onClick={()=>{cbOnPageClick(j)}}  >
//                      { j== this.state.activePage?
//                        <span className="jb active">{j+1}</span>: <span className="jb">{j+1}</span>
//                      }
//                      </Link>)
//   // // 다음 그룹 
//    outputHTML.push( <Link key={shortid.generate()+j+1} to={()=>{javascript:void(0)} }> <span>&#9654;</span></Link>)

//    return outputHTML;
//  }



  render() {
    return (
      <div>{this.initPageInfo()}</div>
    );
  }
}


//보드의 요약보기에 해당... 보드의 글번호, 제목, 첨부파일 여부, 등록 날짜를 하나의 행으로 보여준다..
class Board extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      boardType: ''          
    }
    this.state.boardType = props.boardType;
  }

  
  isMobile = () =>  { return ((window.innerWidth <800) || (this.state.boardType=='mini')) ?true:false;}
  truncate = (str, len) =>{   
    if(str==null) return null;
    return (this.isMobile()==false)? str : (str.length > len ? str.substring(0, len) + "..." : str); }

  render() {
      var upload_file = this.props.upload_file;
      var boardMode =  this.props.boardMode;
      var boardName= this.props.boardName;
      var boardType= this.props.boardType;
      var num = this.props.num;
      var index = this.props.index;
      var lang = this.props.lang;
      var ref_num = this.props.ref_num;
      var subject =  this.props.subject ;
      var reply =  (num==ref_num) ?0:1; // num=ref 면 원글, 
      var re_level =  this.props.re_level ;
      var maxPageArticles = this.props.maxPageArticles;
      var total =  this.props.total;
      var totalPageNo = Math.ceil(total/maxPageArticles);
      var remainderNo = (total % maxPageArticles);
      var pageNo =  this.props.pageNo;
                 
      var count =  this.props.count;
      if(reply){
        // num='';
        // subject = (lang=='ko' )? "[답글] " : "  [Re] ";
        // subject += this.props.subject;
      }
      var modeIndex =  '';
      var registered_date = this.isMobile()? dateFormat(this.props.reg_date, "yyyy.mm.dd") :  dateFormat(this.props.reg_date, "yyyy.mm.dd HH:MM");

      //관리자 모드의 게시판 지원 
      // 보통의 게시판은 /support/ + index + item 식인데....
      // 관리자모드에서는 주소줄이 /admin/3/3 + index + item  식으로.. 한 step 더 들어간다... 
      // 게시판 모드가 admin은 주소줄이 /ko/admin/ 으로 시작하는 경우이고, 게시판모드가 support는 주소줄이 /ko/support/ 으로 시작하는 경우
      
      if((this.props.boardMode=='admin')&& (this.props.boardName=='support') ){
          boardName=  this.props.boardMode +'/3';
          modeIndex = '3/';
      }
      var indent =0;

       for(var i=0;i<re_level-1;i++)  indent += this.isMobile()?5 : 20; //첫번째 댓글만 빼고...
       var seqNo ;
       
       if (totalPageNo==1)  seqNo  = count;
       else{
          seqNo  = (pageNo==totalPageNo-1)?count :( (totalPageNo-pageNo) * maxPageArticles  +count +remainderNo );
        }

       return (

        
          <tr height="50px" >
            {(this.props.num)?
              <td  className="itemOdd" style={{width:'180px', textAlign:"center",  paddingLeft:"10px", paddingRight:"10px"}}  > 
                  <NavLink to={{pathname: '/' +lang +`/${boardName}`+`/${modeIndex}${index}`  +`/1`+`/${this.props.num}` , query: { num: num } }}  style={{ textDecoration:'none'}} >{seqNo}</NavLink>
              </td> 
              :
              <td  style={{width:'180px'}}  /> 
              }
              {(this.props.num)?
              // 라우터로 연결되므로 여기서 key를 map할 필요가 없다 
              <td  className="itemEven" style={{width:'70%', textAlign:"left",  paddingLeft:"10px", paddingRight:"10px"}}  >           
                {(reply)? 
                    <NavLink  to={{pathname: '/' +lang +`/${boardName}`+`/${modeIndex}${index}`  + `/1`+`/${this.props.num}` , query: { num: num } }}  style={{ textDecoration:'none'}} >
                      <img className="icon"  style={{paddingLeft: `${indent}px`}} src={icon_re}></img>&nbsp;&nbsp;{this.truncate(subject,MINI_BOARD_SUBJECT_LEN-(re_level*5))      } 
                    </NavLink>
                    :
                    <NavLink to={{pathname: '/' +lang +`/${boardName}`+`/${modeIndex}${index}`  +`/1`+  `/${this.props.num}` , query: { num: num } }}  style={{ textDecoration:'none'}} >
                    {this.truncate(subject,MINI_BOARD_SUBJECT_LEN)}
                    </NavLink>
                  }
                </td> 
                :
                <td style={{width:'70%'}} />       
              }

            {(this.props.num)?
              <td  className="itemOdd" style={{width:'10%', textAlign:"center"}}  >{(upload_file)? <img src={upload_file} alt="profile"/> :null}</td> 
              :
              <td style={{width:'10%'}} />
            }
              {(this.props.num)?
              <td className="itemEven"  style={{ paddingLeft:"10px", paddingRight:"10px"}}  >{(this.props.num)? <> {registered_date}</>: null}</td>  
              :
              <td style={{ background:'#ccddddd' }} />
            }          
          </tr>

      )
  }
}

//게시판의 글목록 보기 : 게시글의 천제 리스트를 보여준다 
class BoardList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      boardType: '',
      boardContents: '',
      completed: 0,
      searchKeyword: '',
      registerContent: 0, //새로운 글을 등록하는 화면을 보여주기 위해..
      lang:'',
      boardMode:'',
      boardName:'',
      index:0,
      subIndex:0,
      reply:0,
      total:0,
      pageNo:0,
      maxPageArticles:0 //한페이지 내 표시되는 글의 수 s
    }
    this.stateRefresh = this.stateRefresh.bind(this);
    this.state.lang = props.lang;
    this.state.index = props.index;
    this.state.subIndex = props.subIndex;
    this.state.boardMode= props.history.location.pathname.split('/')[2]; //게시판의 (실행)모드 
    this.state.boardName= props.board;
    var boardIndex= props.history.location.pathname.split('/')[3]; //게시판 보드 인덱스
    var boardSubIndex= props.history.location.pathname.split('/')[4]; //게시판 보드 서브인덱스 : 예약, 게시판을 다시 나누기 위한것 

    var articleNo= props.history.location.pathname.split('/')[5]; //게시글의 순번 

    this.state.boardType = props.boardType? props.boardType : null;
    this.state.maxPageArticles = (props.maxPageArticles)?props.maxPageArticles : MAX_PAGE_ARTICLES_DEFAULT;
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleRegisterContent = this.handleRegisterContent.bind(this);
    this.onPageClick=this.onPageClick.bind(this);
    this.onNextPageGroup=this.onNextPageGroup.bind(this);
    this.onPrevPageGroup=this.onPrevPageGroup.bind(this);
    //관리자 메뉴에서 게시판을 지원하기 위해서... 라우터가 길어졌다...
    // 1번 게시판의 10번 내용은 
    // 일반 게시판모드('support')에서는  /support/1/10 와 같이 라우팅되지만
    // 관리자용 게시판모드('admin')에서는  /admin/3/3/1/10 와 같이 라우팅 된다 

    if(this.state.boardMode=='admin') 
    {
      this.state.boardName= 'admin';
      this.state.index= props.history.location.pathname.split('/')[3];

      if((boardIndex=='3') && (boardSubIndex=='3'))
      {
        this.state.index= props.history.location.pathname.split('/')[5];
        this.state.boardName= 'support';
      }
    }else
         this.state.boardName= 'support';

  }

  isMobile = () =>  { return ((window.innerWidth <800) || (this.state.boardType=='mini')) ?true:false;}
  truncate = (str, len) =>{   
    if(str==null) return null;
    return (this.isMobile()==false)? str : (str.length > len ? str.substring(0, len) + "..." : str); }
  
  componentWillMount()
  {

    this.setState({registerContent:0});
  }
  stateRefresh() {
    this.callBoardInfo()
    .then(res=> this.callBoardList())
    .then(res => this.setState({boardContents: res}))
      .then(()=>this.handleRegisterContent(0))
      .then( ()=> 
        {this.setState({
          boardContents: '',
          completed: 0,
          searchKeyword: ''
        });
        // clearInterval(this.timer);      
      })
      .catch(err => console.log(err));
  }

  componentDidMount() {
    this.timer = setInterval(this.progress, 20);
    this.callBoardInfo()
    .then(res=> this.callBoardList())
    .then(res => 
      {
        this.setState({boardContents: res},
          ()=>clearInterval(this.timer)
          );
      })
      .catch(err => console.log(err));
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  //게시글의 갯수 얻어오기 
  callBoardInfo = async () => {
    const response = await fetch(`/api/${this.state.boardName}/info/` + this.state.index);  //게시글 수 
    const body = await response.json();
    var count =  body[0]['count(*)']; // 전체 게시글 
    this.setState({  total: count  }); 
    return body;
  }

  //게시글을 적정단위로 가져오는 방법은?

  callBoardList = async () => {
    // const response = await fetch(`/api/${this.state.boardName}` + '/' + this.state.index);  //게시판 인덱스 
    var request = `/api/${this.state.boardName}` + '/' + this.state.index + '/' + this.state.subIndex + '/' + this.state.pageNo *this.state.maxPageArticles+ '/' + `${this.state.maxPageArticles}`;

    const response = await fetch(request);  //게시판 인덱스와 몇개를 가져올지...
    const body = await response.json();
    return body;
  }

  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  };

  handleValueChange(e) {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }
 
  handleRegisterContent(status) {
    this.setState({registerContent:status});
  }
  // 하위 컴포넌트로 부터 콜백 받는다 
  onPageClick(page ){

    // console.log('onPageClick' + page );
    this.setState({pageNo:page},()=>{
      this.callBoardList()
      .then(res => this.setState({boardContents: res}))
        .then(()=>this.handleRegisterContent(0))
        .then( this.setState({
          boardContents: '',
          completed: 0,
          searchKeyword: ''
        }))
        .catch(err => console.log(err));
    });
  }

  // // 하위 컴포넌트로 부터 콜백 받는다 
  onNextPageGroup(){
    console.log('onNextPageGroup' );
  }
  
  onPrevPageGroup(){
  console.log('onPrevPageGroup' );
  }

  render() {
  
    const hstyle = {


    }

    const filteredComponents = (data) => {
      data = data.filter((c) => {
          return c.writer.indexOf(this.state.searchKeyword) > -1;
        });
        
      //게시글의 한 행씩을 순회하면서 보여준다 
      var count =data.length ; // 디폴트로는 deleted=0 을 빼고 가져온다, 추후 admin 모드에서는 모두를 가져올 예정 

      // return data.map((c) => {
          
      //       return <Board lang={this.state.lang} stateRefresh={this.stateRefresh} key={c.num}
      //       boardMode={this.state.boardMode}  boardName={this.state.boardName} index={this.state.index} count={count--} num={c.num} ref_num={c.ref}  re_level={c.re_level}  subject={c.subject } reg_date={c.reg_date} image={c.image} writer={c.writer} />
      // });
      
      let outputHTML = []; 
  
      for(var i = 0 ; i <this.state.maxPageArticles ; i++)  {

        if(i<count){
          var c =  data[i];
          var subject = this.truncate( c.subject, MINI_BOARD_SUBJECT_LEN);
          outputHTML.push(<Board  lang={this.state.lang} stateRefresh={this.stateRefresh} key={shortid.generate()} boardType={this.state.boardType} boardMode={this.state.boardMode}  boardName={this.state.boardName} index={this.state.index} 
          total={this.state.total} maxPageArticles={this.state.maxPageArticles} pageNo={this.state.pageNo} count={count-i} num={c.num} ref_num={c.ref}  re_level={c.re_level}  subject={subject } reg_date={c.reg_date} image={c.image} writer={c.writer} />);
          
        }else
        {
          outputHTML.push(<Board  lang={this.state.lang} stateRefresh={this.stateRefresh} key={shortid.generate()} boardMode={this.state.boardMode}  boardName={this.state.boardName} index={this.state.index} 
          total={this.state.total} maxPageArticles={this.state.maxPageArticles} pageNo={this.state.pageNo} count={0} num={0} ref_num={0}  re_level={0}  subject={null} reg_date={null} image={null} writer={null} />);

        }
      }


      // 문제 : Board 컴포넌트를 중복해서 호출하면 경고가 발생한다. 경고의 내용은 동일한 key를 사용했다는 것.
      //        BoardList => fileteredComponents => Board 컴포넌트 중복호출시...
      // 예상 : Board 컴포넌트는 TableRow, TableCell 및 NavLink 들을 가지므로, 이런 요소들의 key를 독립적으로 할당해야 한다는 것 같음 
      // 발생 : 2021-01-07 일 발견하고 디버깅 시작함 => 일단은 내버려 둔다 
     
      // Warning: Encountered two children with the same key, `function generate() {
      //   return build(clusterWorkerId);
      // }`. Keys should be unique so that components maintain their identity across updates. Non-unique keys may cause children to be duplicated and/or omitted — the behavior is unsupported and could change in a future version.
      //     in tbody (created by TableBody)
      //     in TableBody (created by WithStyles(TableBody))
      //     in WithStyles(TableBody) (at BoardList.js:533)
      //     in table (created by Table)
      //     in Table (created by WithStyles(Table))
      //     in WithStyles(Table) (at BoardList.js:519)

      //짐작되는 문제 

      // outputHTML.push(<Board  key={'#111'} lang={this.state.lang} stateRefresh={this.stateRefresh} key={shortid.generate} boardMode={this.state.boardMode}  boardName={this.state.boardName} index={this.state.index} 
      //   count={0} num={0} ref_num={0}  re_level={0}  subject={null} reg_date={null} image={null} writer={null} />);

      //   outputHTML.push(<Board  key={'#222'}   lang={this.state.lang} stateRefresh={this.stateRefresh} key={shortid.generate} boardMode={this.state.boardMode}  boardName={this.state.boardName} index={this.state.index} 
      //     count={0} num={0} ref_num={0}  re_level={0}  subject={null} reg_date={null} image={null} writer={null} />);


      return outputHTML

    }



    const { classes } = this.props;
    
    var  titles = [];
    var  cellList,search ;
    
    
    if(this.state.lang=='ko')  {
      cellList =  ["번호", "제목", "첨부", "작성일"] ;
      search =  "검색하기";
    }else{
      cellList =  ["No", "Title", "Attachment", "Date"];
      search = "Search";
    }
    var nindex = this.state.index-1;  //게시판 종류를 의미 
 
    //게시판별로 타이틀을 정의 : 지금은 admin, support

    var captions = (this.state.lang=='ko') ? {
      titles:{
        support: ["견적 및 영업문의","게시판(기술문의)","자료실","뉴스 및 액티비티"] ,
        admin:["일일 업무 내역","사내 자료실","관리/통계"] 
      },
      desc:{
        support: [
                  "견적요청 개발의뢰 등 영업적인 문의를 위한 게시판 입니다 기술에 대한 세부 문의는 기술게시판을 이용바랍니다",
                  "기술지원을 위한 게시판 입니다 영업 문의는 해당 게시판을 이용바랍니다",
                  "",
                  "사내 활동을 기록하는 게시판으로 일부 내용은 부정확한 사실을 포함하고 있을수 있습니다"] ,
        admin:["","",""] 
       }
    }:
    {
      titles:{
        support: ["Quotation/Inquiry","Board(Technical Inquiry)","Refernce Room","News/ Anncouncement"],
        admin: ["Daily works","Refernce Room","Management/Statistics"]
      },
      desc:{
        support: ["","","","News/ Acivities in company"],
        admin:["","",""]        
      }
    }
    
  
    var articles =  (this.state.lang=='ko')? " 전체 게시 글 : " : "Total articles : "
   
    //언어별 게시판별 타이틀   
    var curTitle =  (this.state.boardName=='admin')? captions.titles.admin[nindex] : captions.titles.support[nindex];
    var curTitleDesc =  (this.state.boardName=='admin')? captions.desc.admin[nindex] : captions.desc.support[nindex];

    var titleRegister = (this.state.lang=='ko')? "새 글 쓰기" : "Write new";
 
    return (

      <div className={classes.root}>
       <div style={{ width: '100%', margin: "0 auto"}}>
        { (this.state.boardType=='mini')?
            <div></div>
            :      
           <AppBar position="static">
             <Toolbar>
               {/* <IconButton className={classes.menuButton} color="inherit" aria-label="Open drawer"><MenuIcon /></IconButton> */}
               <Typography className={classes.title} variant="h6" color="inherit" noWrap>{curTitle} </Typography>

               {this.isMobile()? null
               :

                <div style={{fontSize:"14px", marginLeft: "50px"}}>
                  {curTitleDesc}
                </div>
               }
                <div className={classes.grow}  />
                 <div style={{height: 10, width:200}} >{articles}{this.state.total}</div>
                 <div className={classes.menu}>
                   <Button variant="contained" color="secondary" onClick={()=>this.setState({registerContent:1})}>{titleRegister}</Button>
                 </div>
              
                 <div className={classes.search}>
                 <div className={classes.searchIcon}>
                   <SearchIcon />
                </div>
                <InputBase
                    placeholder= {search} classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    name="searchKeyword"
                    value={this.state.searchKeyword}
                    onChange={this.handleValueChange}
                />
              </div>
            </Toolbar>
          </AppBar>
          }
           {this.state.registerContent ? 
            <div>
              {/* num==-1 은 기존글을 참조하지 않는 새글  */}
                <ABoardAdd boardName={this.state.boardName}  lang={this.state.lang} reply={0}  index={this.state.index}  num=''   history={this.props.history} 
                  stateRefresh={this.stateRefresh}  form={1}
                /> 
          </div>
             :
            <div>  
              {/* <div className={classes.menu}>
              </div> */}

             <Paper className={classes.paper}>
             <Table >
               {/* //테이블의 헤더 보여주기 */}
               { (this.state.boardType=='mini')?
                null
                :      
                <TableHead className="header" >
                  <TableRow>
                    {cellList.map(c => {
                      return <TableCell key={shortid.generate()} className={classes.tableHead}>{c}</TableCell>
                    })}
                  </TableRow>
                </TableHead>
               }
               {/* //원형 loading 아이콘 보여주기 */}
               <TableBody>
                 {this.state.boardContents ?
                 <>
                    {
                      //게시판의 내용을 테이블 리스트 형식으로 보여주기 
                      filteredComponents(this.state.boardContents) 
                    }
                     <TableRow  className="pageinfo" >
                        <TableCell key={shortid.generate()} colSpan="6" align="center"> 
                        <PageInfo  key={shortid.generate()} total={this.state.total} pageNo={this.state.pageNo} maxPageArticles={this.state.maxPageArticles} onPageClick={this.onPageClick}/> 
                      </TableCell>
                   </TableRow>
                 </>
              
                   :
                   <TableRow>
                    <TableCell key={shortid.generate()} colSpan="6" align="center">
                      <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
                    </TableCell>
                 </TableRow>
               
               }
               </TableBody>
             </Table>
           </Paper> 
          </div>           
        }
        </div>
      </div>
  );
  }
}

export default withStyles(styles)(BoardList);
