import React, {Component} from 'react';
import {Route,Switch,Redirect} from 'react-router-dom';
import $ from "jquery";
import {} from "jquery.cookie";

import Header from "./Header";
import Footer from "./Footer";
import HTMLContentHome from './app/route/HTMLContentHome';
import HTMLContentCompany from './app/route/HTMLContentCompany';
import HTMLContentProduct from './app/route/HTMLContentProduct';
import HTMLContentOdm from './app/route/HTMLContentOdm';
import HTMLContentService from './app/route/HTMLContentService';
import HTMLContentSupport from './app/route/HTMLContentSupport';
import HTMLContentAdmin from './app/route/HTMLContentAdmin';
import HTMLUserNotFound from './app/route/HTMLUserNotFound';
import HTMLHeaderHomeBanner from './app/HTMLHeaderHomeBanner'
import HTMLHeaderSimpleBanner from './app/HTMLHeaderSimpleBanner'

import history from './history';
import './App.css';
//import { LocalStorage } from "node-localstorage";



// 실질적인 애플리케이션 컴포넌트 
class App extends Component {
   constructor(props) {
    super(props);
    const { cookies } = props;

   // this.state = { lang:  $.cookie('lang'),  subpath:  global.localStorage.getItem('subpath') }

    this.state = { lang:  $.cookie('lang'),  subpath:  localStorage.subpath }

    if(!this.state.lang){
      this.state.lang = 'ko';
    }
    if(!this.state.subpath){
       this.state.subpath = '/';
       localStorage.subpath ='/';
    }
  
    this.cbChangeLang = this.cbChangeLang.bind(this);
    this.cbChangeScreen = this.cbChangeScreen.bind(this); 

   }

   componentWillMount(){
     const subpath =  localStorage.subpath ;
   }

 // 콜백함수로 헤더의 한영전환 a 태그가 클릭시 
 cbChangeLang(lang) 
  {
    this.setState({lang: lang},
      ()=>{ $.cookie("lang",lang);  }
    );
  }



 // 콜백함수로 헤더의 메뉴선택시 => setState로 props를 변경후 render시 반영 
 cbChangeScreen(_subpath,index, subIndex) 
 {
    //로고클릭시에는 _subpath 가 '/' 면 id 파라미터는 무시   
    var sel = (_subpath=='/') ? _subpath :( _subpath + '/' +index+ '/' +subIndex) ;

     this.setState({subpath: sel},
     // ()=>{ $.cookie("subpath",sel);  
      ()=>{localStorage.subpath=sel;}

    );

    // 로고를 클릭했을때, 페이지를 reload 시켜준다.. => 이렇게 하지 않으면 slick 등 javascript 가 정상 동작하지 않는다 
    if(sel == '/'){
      //window.location.reload();
      window.location.href = '/';
      return;
    }


  }

  componentDidMount()
  {
    console.log(this.props);
  }

  // 컴포넌트 랜더링 
  render() {

  // var pathname =this.history.props.pathname.split('/')[1];
  // var lang = this.history.props.lang;

  


    var language = this.state.lang ;
    var subpath = this.state.subpath ;
   
    if(!subpath) subpath = '/';

   if(subpath=='/')
      subpath = '/' + language ;

    return (
      <div>
        {/* props를 사용하기 편하도록...   */}
     
        <Header history={history} lang={language} subpath={subpath} cbChangeLang={this.cbChangeLang} cbChangeScreen={this.cbChangeScreen}/>  
        <Switch>
  
          {/* /admin/:id 는 별도로 redirect 한다  */}
       
          <Route exact path="/admin"><Redirect to={`/${language}/admin/1`} /></Route>
          <Route exact path="/company"><Redirect to={`/${language}/company/1`} /></Route>
          <Route exact path="/product"><Redirect to={`/${language}/product/1`} /></Route>
          <Route exact path="/service"><Redirect to={`/${language}/service/1`} /></Route>
          <Route exact path="/support"><Redirect to={`/${language}/support/1`} /></Route>
          <Route exact path="/login"><Redirect to={`/${language}/login`} /></Route>
          
          <Route exact path="/"><Redirect to={language} /></Route>
          <Route exact path={'/:language'}  component={HTMLContentHome} />

          <Route  path={'/:language/company/:id'}  component={HTMLContentCompany} />
          <Route  path={'/:language/product/:id'}  component={HTMLContentProduct} />


          <Route  path={'/:language/odm/:id'}      component={HTMLContentOdm} />
          <Route  path={'/:language/service/:id'}  component={HTMLContentService} />
          <Route  path={'/:language/support/:id'}  component={HTMLContentSupport} />  
          {/* /admin/:id 는 별도로 redirect 된다   */}
          <Route  path={'/:language/admin/:id'}  component={HTMLContentAdmin} />  
          <Route  path={'/:language/login'}  component={HTMLContentAdmin} />  
        
         <Route  path={'*'}  component={HTMLUserNotFound} />  


        </Switch>

        {/* <CKEditor data="<p>This is an example CKEditor 4 WYSIWYG editor instance.</p>" /> */}
        <Footer lang={language} />    
    </div>
    );
  }
}

export default App;


