import React, { Component } from 'react';



class FormMovieClip extends Component {

    constructor(props) {
        super(props);
    }

    
    render() {

        const {formContent} = this.props;
  
        const hstyle = {
        color: "black",
        backgroundColor: formContent.bgcolor[0],
        fontFamily: "Arial",
        fontSize : "40px",
        paddingLeft:"10px",
        textAlign : 'center',
        verticalAlign:"top"
        }

        const istyle = {
            backgroundColor: formContent.bgcolor[1],
            padding : "5px"
        }
        
        const tstyle = {
            color: "black",
            backgroundColor: formContent.bgcolor[2],
            fontFamily: "Arial",
            fontSize : "14px",
            paddingLeft: "20px",
            border : "0" 
        
        }

     
        const captionstyle = {
            color: "purple",
            backgroundColor: formContent.bgcolor[2],
            fontFamily: "Arial",
            fontSize : "20px",
            paddingTop: "5px" ,
            paddingLeft: "20px" ,
            borderBottom : "0"     
        }
     

        return (
            <div>
{/*                
               <center>
  
                <table  border="1" cellpadding="0" cellspacing="0"   width ="60%">
                    <tr>
                        <td width="15%" style={hstyle} rowspan="11">{formContent.year}</td>
                        <td width="30%" rowspan="11" >
                            <img  style={istyle} border="0" src={formContent.image}  width={formContent.iWidth}  height={formContent.iHeight} align="center" />
                        </td>
                    </tr>
             
                    <tr><td style={captionstyle}   >  { formContent.prod}</td></tr>
                    <tr><td style={tstyle}   >      { formContent.spec[0]}</td></tr>
                    <tr><td style={tstyle}   >      { formContent.spec[1]}</td></tr>
                    <tr><td style={tstyle}   >      { formContent.spec[2]}</td></tr>
                    <tr><td style={tstyle}   >      { formContent.spec[3]}</td></tr>
                    <tr><td style={tstyle}   >      { formContent.spec[4]}</td></tr>
                    <tr><td style={tstyle}   >      { formContent.spec[5]}</td></tr>
                    <tr><td style={captionstyle}  >   { formContent.title}</td></tr>
                    <tr><td style={tstyle}   >      { formContent.apply[0]}</td></tr>
                    <tr><td style={tstyle}   >      { formContent.apply[1]}</td></tr>

                  
                </table>
             </center> */}
            </div>
        );
    }
}


export default FormMovieClip;