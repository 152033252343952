import React, { Component } from 'react';
import {BrowserRouter as Router,Route,Switch,NavLink, Redirect, useParams} from 'react-router-dom';
import { post } from 'axios';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';
import shortid from 'shortid';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { RadioGroup,Radio,FormControl,FormLabel,FormControlLabel,FormHelperText,Button } from '@material-ui/core';

const styles = theme => ({
    root: {
      width: "100%",
      minWidth: 1080
    },
    menu: {
      marginTop: 15,
      marginBottom: 15,
      display: 'flex',
      justifyContent: 'center'
    },
    paper: {
      marginLeft: 18,
      marginRight: 18
    },
    
    progress: {
      margin: theme.spacing.unit * 2
    },
    grow: {
      flexGrow: 1,
    },
    tableHead: {
      fontSize: '1.0rem'
    },
    tblcell_odd: {
      // borderTopWidth: 1, 
      // borderColor: '#888',
      // borderStyle: 'solid',
      backgroundColor: '#eee'
    },    
    tblcell_even: {
      // borderTopWidth: 1, 
      // borderColor: '#888',
      // borderStyle: 'solid',
      backgroundColor: '#efe'
    },    
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing.unit,
        width: 'auto',
      },
    },
    searchIcon: {
      width: theme.spacing.unit * 9,
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    inputInput: {
      paddingTop: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingLeft: theme.spacing.unit * 10,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 120,
        '&:focus': {
          width: 200,
        },
      },
    }
  });
  

class AdminManagementContent_Settings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            boardName:'',
            index:'',
            lang: '',
            content:'',
            completed :0,
            boardInfos: '',
            boardSettings: '',
            sel_voiceAlarm:'0',  //음성알람 사용 설정 
            testResult:''  
          }

            this.state.lang =    this.props.location.pathname.split('/')[1];      
            this.state.boardName =    this.props.location.pathname.split('/')[2];      
            this.state.index =    this.props.match.params.index;      
            this.stateRefresh = this.stateRefresh.bind(this);
            this.handleSubmit = this.handleSubmit.bind(this);
            this.handleRadioChange = this.handleRadioChange.bind(this);
            this.modifySettings = this.modifySettings.bind(this);
            this.stateRefresh = this.stateRefresh.bind(this);
            this.handleTest = this.handleTest.bind(this);
  
    }

    componentWillUnmount() {
      clearInterval(this.timer);
    }
    stateRefresh() {
      this.callBoardInfo()
      .then(res => this.setState({boardInfos: res}))
      .then( this.setState({
          boardInfos: '',
          boardSettings: '',
          completed: 0,
         }))
        
        .catch(err => console.log(err));
    }
  
    componentDidMount() {
      this.timer = setInterval(this.progress, 20);
      this.callBoardInfo()
        .then(res => this.setState({boardInfos: res}))
        .then(  ()=>{
          // clearInterval(this.timer);
         })
        .catch(err => console.log(err));


        this.callBoardSettings()
        .then((res)=>{

 
          this.setState({boardSettings: res},  ()=>{
            var alarm_voice =  ( this.state.boardSettings[0].alarm_mask & 1)?0:1; // 0 : 사용, 1: 미사용 이다  
           
              this.setState({
                sel_voiceAlarm: alarm_voice
              },()=> { 
                this.stateRefresh();  
              })
    
            });   
          })        
         .then(  ()=>{


            clearInterval(this.timer);
      })
        .catch(err => console.log(err));
    }
  
    componentWillUnmount() {
      clearInterval(this.timer);
    }


    handleSubmit()
    {
        // this.modifySettings( )
        // .then((response) => {
        //   console.log(response.data);
        // })
    }


    handleRadioChange(e) {
      let nextState = {};
      nextState[e.target.name] = e.target.value;
      this.setState({
        sel_voiceAlarm:  e.target.value
      },()=>{
        this.callBoardSettings()
        .then((res)=>{

          this.setState({boardSettings: res},  ()=>{
            var alarm_mask =  this.state.boardSettings[0].alarm_mask; // 전체 게시글 
            this.modifySettings( )
            .then((res) => { console.log(res.data); })
            .then(()=>{ this.stateRefresh();  })

            });   
          });
      })
    }
  

    handleTest()
    {
           console.log('handleTest');
           this.callBoardTest()
           .then(  (res)=>{
            console.log(res);
             this.setState({
               testResult:  res.count
             })

        })
    }



  //게시판별 게시글의 갯수 얻어오기 

  callBoardInfo = async () => {
    const response = await fetch(`/api/${this.state.boardName}/info/` + this.state.index);  //게시글 수 
    const body = await response.json();
    var count =  body[0]['count(*)']; // 전체 게시글 
    this.setState({  total: count  }); 
  
    return body;
  }


//전체 게시판의 설정정보 일기 
callBoardSettings = async () => {

    const response = await fetch(`/api/admin/info` );  //설정정보  
    const body = await response.json();
    
    return body;
  }

//테스트 
callBoardTest = async () => {

  const response = await fetch(`/count` );  //설정정보  
  const body = await response.json();
  
  return body;
}


//게시판의 정보 수정 
  // modifySettings = async () => {
  //   const url = `/api/admin/info`;

  //   var alarm_mask = this.state.boardSettings[0].alarm_mask;
    
  //   if(this.state.sel_voiceAlarm=='1')   alarm_mask  &= ~(1<<0); 
  //   else  alarm_mask |= (1<<0); 


  //   const formData = new FormData();
  //   formData.append('alarm_mask', alarm_mask);   
  //   formData.append('content', this.state.content);
  
  //   const config = {
  //     headers: {
  //       'content-type': 'multipart/form-data'
  //     }
  //   }
     
  //   return  post(url, formData, config);
  // }
  

  modifySettings = async () => {
    
    var alarm_mask = this.state.boardSettings[0].alarm_mask;
    
    if(this.state.sel_voiceAlarm=='1')   alarm_mask  &= ~(1<<0); 
    else  alarm_mask |= (1<<0); 


    const url = `/api/admin/info/mask/${alarm_mask}`;



    const formData = new FormData();
    // formData.append('alarm_mask', alarm_mask);   
    // formData.append('content', this.state.content);
  
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
     
    return  post(url, formData, config);
  }
  


  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  };
    // // var params = useParams();
    // var lang = props.location.pathname.split('/')[1];
    // var index =  parseInt(params.id);
    // var index2 = props.location.pathname.split('/')[3];
    render() {

  
    //높이 설정 
    const dstyle = {
      height: "1600px"
    }


    const { classes } = this.props;
    

    var  titles = [];
    var  cellList,search ;
      
    var captions = ["문의시 음성안내 반복 설정","서버 응답 테스트"] ;

    var captions_desc = [`게시판에 새로운 문의 글이 등록 됐을때  음성안내를 반복할지에 대한 설정입니다.  중지하려면 "미사용"을 선택하세요.
    `,"프록시 서버로 부터의 응답 테스트 : 테스트 버튼을 클릭할 때 마다 새로운 랜덤값이 전송됩니다."] ;

    var voice;

    const { sel_voiceAlarm } = this.state;
    

    // const  buttonStyle = {
    //     backgroundColor: '#1E6738'
    //   }
      const styles = {
        buttonStyle: {
        backgroundColor: "rgb(0,192,192)",
        color: "rgb(0,0,0)"
        }
        }
    return (
    
        
        <div className="contents_cont"  id="s_contents" style={dstyle}>
            <div className="_container">
                <div className="contents_wrap">
                     <div>  
                        {/* <div className={classes.menu}>
                    </div> */}

                    <Paper className={classes.paper}>
                    <Table >
                    {/* //테이블의 헤더 보여주기 */}
                    {/* <TableHead style={{height: 10}} className="header" >
                        <TableRow>
                        
                        </TableRow>
                     </TableHead> */}
                  
                    <TableBody>
                       <TableRow>
                       <TableCell key={shortid.generate} width="15%" colSpan="1" align="left" >{captions[0]} </TableCell>
                        <TableCell key={shortid.generate} width="35%" colSpan="1" align="left" >
                          {/* <form onSubmit={this.handleSubmit}> */}
                          <FormControl component="fieldset" name="radio_voiceAlarm" >
                          <RadioGroup  className={classes.group} onChange={this.handleRadioChange} value={sel_voiceAlarm} row={true}  >
                              <FormControlLabel value={'0'} control={<Radio color="primary" checked={sel_voiceAlarm==0}/>} label="사용" />
                              <FormControlLabel value={'1'} control={<Radio color="primary"checked={sel_voiceAlarm==1}/>} label="미사용" />
                          </RadioGroup>
                          </FormControl>
                        {/* </form> */}
                        </TableCell>
                        <TableCell key={shortid.generate}  colSpan="1" align="left"   className={classes.tblcell_odd}   >{captions_desc[0]} </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell key={shortid.generate}  width="10%" colSpan="1" align="left"    >
                          <Button onClick={this.handleTest}  style={styles.buttonStyle} variant="outlined"  >{ captions[1]}</Button>
                        </TableCell>
                        <TableCell key={shortid.generate}  width="35%"   colSpan="1" align="left"    >
                          <div style={{fontSize:'25px'}}>{this.state.testResult}</div>
                        </TableCell>
                        <TableCell key={shortid.generate}  colSpan="1" align="left"   className={classes.tblcell_odd}   >{captions_desc[1]} </TableCell>
                     </TableRow>

                        {/* //원형 loading 아이콘 보여주기 */}
                      {/* <TableRow>
                        <TableCell key={shortid.generate} colSpan="6" align="center">
                          <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
                        </TableCell>
                      </TableRow> */}
                    </TableBody>
                    </Table>
                </Paper>  
                </div>           



                </div>
            </div>
        </div>
        );
    }
    
}

// export default AdminManagementContent;
export default withStyles(styles)(AdminManagementContent_Settings);
