import React, { Component } from 'react';

import FormBrief from '../common/FormBrief';


class OdmContentHistory extends Component {
    constructor(props) {
        super(props);
      }

    render() {
     var lang = this.props.location.pathname.split('/')[1];
   
        var formContents = 
        [
            // 제품1: 농기게 자율주행 컨트롤러  
            {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2021",   
                    video: "/mov/nxd3568-dd.mp4",
                    image : "/mov/nxd3568-dd.png",   
                    iWidth: "300px", iHeight:"260px",
                    prod: ["농기계 자율주행 HMI"," (모델명: NXD3568)"],
                        spec:[
                            ["Core : ", "RK3568 Cortex-A55 2GHz Quad"],
                            ["Screen : ", "1280x800 12 inch Color TFT"],
                            ["I/O : ", "WiFi, HDMI,SD, USB, RS485"],
                            ["Features : ", "농기계자율주행제어/ "],
                            ["Desc : ", "개발 후 생산공급/KC인증 / RF인증"],
                            ["Etc : ", ""],
                        ]     
                    ,
                    title :   ["적용분야"],
                    apply :   ["농기계 통합컨트롤러 / 차량용터미널 / AVN",""]
           
                },

               en: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2021",   
                    video: "/mov/nxd3568-dd.mp4",
                    image : "/mov/nxd3568-dd.png",   
                    iWidth: "300px", iHeight:"260px",
                    prod: ["Auto steering Multi purpose Vehicle controller", "(Model: NXD3568)"],
                        spec:[
                            ["Core : ", "RK3568 Cortex-A55 2GHz Quad"],
                            ["Screen : ", "1280x800 12 inch Color TFT"],
                            ["I/O : ", "WiFi, HDMI,SD, USB, RS485"],
                            ["Features : ", "Auto steering  car control/ Multimedia(Youtube/Radio/Mirroring)"],
                            ["Desc : ", "KC/ RF cerftification"],
                            ["Etc : ", ""],
                        ]     
                    ,
                    title :   ["Application"],
                    apply :   ["Auto steering  car Multi purpose controller","Vechicle control termimal", "AVN"]
                    
                }
            },            // 제품2: 캠핑카통합컨트롤러  
            {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2020",   
                    video: "",
                    image : "/img/product/odm/nxd3568_android_odm.jpg",   
                    iWidth: "300px", iHeight:"260px",
                    prod: ["캠핑카 통합컨트롤러"," (모델명: NXD3568)"],
                        spec:[
                            ["Core : ", "RK3568 Cortex-A55 2GHz Quad"],
                            ["Screen : ", "1280x800 12 inch Color TFT"],
                            ["I/O : ", "WiFi, HDMI,SD, USB, RS485"],
                            ["Features : ", "캠핑카제어/ 멀티미디어(유투브/라디오/미러링/테더링)"],
                            ["Desc : ", "개발 후 생산공급/KC인증 / RF인증"],
                            ["Etc : ", ""],
                        ]     
                    ,
                    title :   ["적용분야"],
                    apply :   ["캠핑카 통합컨트롤러 / 차량용터미널 / AVN",""]
           
                },

               en: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2020",   
                    video: "",
                    image : "/img/product/odm/nxd3568_android_odm.jpg",   
                    iWidth: "300px", iHeight:"260px",
                    prod: ["Camping car Multi purpose controller", "(Model: NXD3568)"],
                        spec:[
                            ["Core : ", "RK3568 Cortex-A55 2GHz Quad"],
                            ["Screen : ", "1280x800 12 inch Color TFT"],
                            ["I/O : ", "WiFi, HDMI,SD, USB, RS485"],
                            ["Features : ", "Camping car control/ Multimedia(Youtube/Radio/Mirroring)"],
                            ["Desc : ", "KC/ RF cerftification"],
                            ["Etc : ", ""],
                        ]     
                    ,
                    title :   ["Application"],
                    apply :   ["Camping car Multi purpose controller","Vechicle control termimal", "AVN"]
                    
                }
            },
            // 제품3: 레이턴트 
            {
            ko: {
                bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                year : "2019",   
                video: "",
                image : "/img/product/odm/nx8925-oem.png",   
                iWidth: "300px", iHeight:"260px",
                prod: ["교통카드 개집표기"," (모델명: NX8925)"],
                    spec:[
                        ["Core : ", "TCC8925 816MHz"],
                        ["Screen : ", "480x272 Color TFT"],
                        ["I/O : ", "NFC controller, 2 RS-232, 1 DBG uart, Ethernet, SD, USB"],
                        ["Features : : ", "Linux QT 5.3.1 / 13.56MHz NFC"],
                        ["Desc : ", "개발 후 생산공급/KC인증 / RF인증"],
                        ["Etc : ", ""],
                    ]     
                ,
                title :   ["적용분야"],
                apply :   ["교통카드 개집표기 / 지하철 게이트 ","NFC리더"]
        
            },

            en: {
                bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                year : "2019",   
                video: "",
                image : "/img/product/odm/nx8925-oem.png",   
                iWidth: "300px", iHeight:"260px",
                prod: ["Transportation card reader", "(Model: NX8925)"],
                    spec:[
                        ["Core : ", "TCC8925 816MHz"],
                        ["Screen : ", "480x272 Color TFT"],
                        ["I/O : ", "NFC controller, 2 RS-232, 1 DBG uart, Ethernet, SD, USB"],
                        ["Features : : ", "Linux QT 5.3.1 / 13.56MHz NFC"],
                        ["Desc : ", ""],
                        ["Etc : ", ""],
                    ]     
                ,
                title :   ["Application"],
                apply :   ["Transportation card reader","NFC Card reader"]
                
            }
        },
        
            // 제품3: 네비  
           {
            ko: {
                bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                year : "2017",   
                video: "",
                image : "/img/product/odm/android-demo.jpg",   
                iWidth: "300px", iHeight:"260px",
                prod: ["안드로이드 PND"," (모델명: NX8970)"],
                    spec:[
                        ["Core : ", "TCC8970 1GHz Dual"],
                        ["Screen : ", "800x480 Color TFT"],
                        ["I/O : ", "DMB, 후방카메라, GPS, SD, USB"],
                        ["Features : : ", "안드로이드 Kitkat / 차량용 네이게이션 터미널"],
                        ["Desc : ", "KC인증 / RF인증"],
                        ["Etc : ", ""],
                    ]     
                ,
                title :   ["적용분야"],
                apply :   ["교통카드 개집표기 / 지하철 게이트 ","NFC리더"]
       
            },

           en: {
                bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                year : "2017",   
                video: "",
                image : "/img/product/odm/android-demo.jpg",   
                iWidth: "300px", iHeight:"260px",
                prod: ["Transportation card reader", "(Model: NX8970)"],
                    spec:[
                        ["Core : ", "TCC8970 1GHz Dual"],
                        ["Screen : ", "800x480 Color TFT"],
                        ["I/O : ", "DMB, Rear CAM, GPS, SD, USB"],
                        ["Features : : ", "Android Kitkat / CAR Navigation"],
                        ["Desc : ", ""],
                        ["Etc : ", ""],
                    ]     
                ,
                title :   ["Application"],
                apply :   ["Transportation card reader","NFC Card reader"]
                
            }
        },
                 
            // 제품4: 전기충전기  
            {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2013",   
                    video: "",
                    image : "/img/product/odm/nx320-m.jpg",   
                    iWidth: "300px", iHeight:"260px",
                    prod: ["전기차 충전기 메인보드"," (모델명: NX320-L)"],
                        spec:[
                            ["Core : ", "PXA320 806MHz"],
                            ["Screen : ", "480x272 Color TFT"],
                            ["I/O : ", "RS-485 2 RS232 2 ports, SD, USB"],
                            ["Features : : ", "Windows CE 6.0"],
                            ["Desc : ", ""],
                            ["Etc : ", ""],
                        ]     
                    ,
                    title :   ["적용분야"],
                    apply :   ["차량 진단기","진단기"]
           
                },

               en: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2013",   
                    video: "",
                    image : "/img/product/odm/nx320-m.jpg",   
                    iWidth: "300px", iHeight:"260px",
                    prod: ["Transportation card reader", "(Model: NX8925)"],
                    spec:[
                        ["Core : ", "PXA320 806MHz"],
                        ["Screen : ", "480x272 Color TFT"],
                        ["I/O : ", "RS-485 2 RS232 2 ports, SD, USB"],
                        ["Features : : ", "Windows CE 6.0"],
                        ["Desc : ", ""],
                        ["Etc : ", ""],
                    ]     
                 ,
                    title :   ["Application"],
                    apply :   ["Electric car charger","VData display/ terminal"]
                    
                }
            },                
            // 제품4: MDT 
            {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2012",   
                    video: "",
                    image : "/img/product/odm/nx2450-mdt.jpg",   
                    iWidth: "300px", iHeight:"260px",
                    prod: ["차량용 데이터 단말기"," (모델명: NX2450)"],
                        spec:[
                            ["Core : ", "S3C2450 533MHz"],
                            ["Screen : ", "800x480 Color TFT"],
                            ["I/O : ", "WiFi/ GPRS / CDMA / GPS, 2 RS-232 4 port, Dual CAN, SD, USB"],
                            ["Features : : ", "Windows CE 6.0"],
                            ["Desc : ", ""],
                            ["Etc : ", ""],
                        ]     
                    ,
                    title :   ["적용분야"],
                    apply :   ["차량용 데이터 터미널 / 차량관제용 단말기 ","모바일데이터터미널(MDT)"]
           
                },

               en: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2012",   
                    video: "",
                    image : "/img/product/odm/nx2450-mdt.jpg",    
                    iWidth: "300px", iHeight:"260px",
                    prod: ["Transportation card reader", "(Model: NX8925)"],
                    spec:[
                        ["Core : ", "S3C2450 533MHz"],
                        ["Screen : ", "800x480 Color TFT"],
                        ["I/O : ", "WiFi/ GPRS / CDMA / GPS, 2 RS-232 4 port, Dual CAN, SD, USB"],
                        ["Features : : ", "Windows CE 6.0"],
                        ["Desc : ", ""],
                        ["Etc : ", ""],
                    ]     
                 ,
                    title :   ["Application"],
                    apply :   ["Vechicle Fleet management terminal","MDT"]
                    
                }
            },                         
            // 제품5: 진단기  
            {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2011",   
                    video: "",
                    image : "/img/product/odm/nx320-wl.png",   
                    iWidth: "300px", iHeight:"260px",
                    prod: ["차량 진단기"," (모델명: NX320-W)"],
                        spec:[
                            ["Core : ", "PXA320 806MHz"],
                            ["Screen : ", "640x480 Color TFT"],
                            ["I/O : ", "USB IF with scope, 2 RS-232 4 port, SD, USB"],
                            ["Features : : ", "Windows CE 6.0"],
                            ["Desc : ", ""],
                            ["Etc : ", ""],
                        ]     
                    ,
                    title :   ["적용분야"],
                    apply :   ["차량 진단기","진단기"]
           
                },

               en: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2011",   
                    video: "",
                    image : "/img/product/odm/nx320-wl.png",   
                    iWidth: "300px", iHeight:"260px",
                    prod: ["Transportation card reader", "(Model: NX8925)"],
                    spec:[
                        ["Core : ", "PXA320 806MHz"],
                        ["Screen : ", "640x480 Color TFT"],
                        ["I/O : ", "USB IF with scope, 2 RS-232 4 port, SD, USB"],
                        ["Features : : ", "Windows CE 6.0"],
                        ["Desc : ", ""],
                        ["Etc : ", ""],
                    ]     
                 ,
                    title :   ["Application"],
                    apply :   ["Vechicle scanner","Vechicle Analyzer"]
                    
                }
            },
 
           // 제품6: 핸드헬드 바코더스캐너  
           {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2009",   
                    video: "",
                    image : "/img/product/odm/nx320-mi.jpg",   
                    iWidth: "300px", iHeight:"260px",
                    prod: ["바코더스캐너"," (모델명: NX320-MI)"],
                        spec:[
                            ["Core : ", "PXA320 806MHz"],
                            ["Screen : ", "240x320 Color TFT"],
                            ["I/O : ", "WiFi/ CDMA / 1D Scaner, SD, USB"],
                            ["Features : : ", "Windows CE 6.0"],
                            ["Desc : ", ""],
                            ["Etc : ", ""],
                        ]     
                    ,
                    title :   ["적용분야"],
                    apply :   ["바코더스캐너","핸디 데이터 터미널"]
        
                },

            en: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2009",   
                    video: "",
                    image : "/img/product/odm/nx320-mi.jpg",   
                    iWidth: "300px", iHeight:"260px",
                    prod: ["Handheld Barcode scanner", "(Model:  NX320-MI)"],
                    spec:[
                        ["Core : ", "PXA320 806MHz"],
                        ["Screen : ", "240x320 Color TFT"],
                        ["I/O : ", "WiFi/ CDMA / 1D Scaner, SD, USB"],
                        ["Features : : ", "Windows CE 6.0"],
                        ["Desc : ", ""],
                        ["Etc : ", ""],
                    ]     
                ,
                    title :   ["Application"],
                    apply :   ["Handheld Barcode scanner","Handy data terminal"]
                    
                }
            },

            // 제품7: ATM메인보드  
            {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2007",   
                    video: "",
                    image : "/img/product/odm/nx2440-qt.jpg",    
                    iWidth: "300px", iHeight:"260px",
                    prod: ["주차관제용 ATM보드"," (모델명: NX2440)"],
                        spec:[
                            ["Core : ", "S3C2440 400MHz"],
                            ["Screen : ", "800x480 Color TFT"],
                            ["I/O : ", "RS-232 8 ports (Opt. 3 ports), Ethernet 10T, SD, USB"],
                            ["Features : : ", "Windows CE 5.0"],
                            ["Desc : ", ""],
                            ["Etc : ", ""],
                        ]     
                    ,
                    title :   ["적용분야"],
                    apply :   ["ATM보드/ 관제용 메인보드","데이터 수집용 메인보드"]
            
                },

                en: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2007",   
                    video: "",
                    image : "/img/product/odm/nx2440-qt.jpg",   
                    iWidth: "300px", iHeight:"260px",
                    prod: ["Transportation card reader", "(Model: NX8925)"],
                    spec:[
                        ["Core : ", "S3C2440 400MHz"],
                        ["Screen : ", "800x480 Color TFT"],
                        ["I/O : ", "RS-232 8 ports (Opt. 3 ports), Ethernet 10T, SD, USB"],
                        ["Features : : ", "Windows CE 5.0"],
                        ["Desc : ", ""],
                        ["Etc : ", ""],
                    ]     
                    ,
                    title :   ["Application"],
                    apply :   ["Industrial ATM","Data gatherer"]
                    
                }
            },
                                   
        ] ;

        const dstyle = {
            color: "white",
            border: '2px solid #3d52ad',
            backgroundColor: '#eee',
            // height: "1040px"  // 헤더의 높이가 차감된듯 
          }
        return (
          
        <div className="odm_contents"  style={dstyle} >
            <br/>
            { (lang=='ko')? <FormBrief formContent={formContents[0].ko}/> : <FormBrief formContent={formContents[0].en}/>}  
            <br/>
            { (lang=='ko')? <FormBrief formContent={formContents[1].ko}/> : <FormBrief formContent={formContents[1].en}/>}  
            <br/>
            { (lang=='ko')? <FormBrief formContent={formContents[2].ko}/> : <FormBrief formContent={formContents[2].en}/>}  
            <br/>
            { (lang=='ko')? <FormBrief formContent={formContents[3].ko}/> : <FormBrief formContent={formContents[3].en}/>}  
            <br/>
              
            { (lang=='ko')? <FormBrief formContent={formContents[4].ko}/> : <FormBrief formContent={formContents[4].en}/>}  
            <br/>
            { (lang=='ko')? <FormBrief formContent={formContents[5].ko}/> : <FormBrief formContent={formContents[5].en}/>}  
            <br/>
            {/* { (lang=='ko')? <FormBrief formContent={formContents[6].ko}/> : <FormBrief formContent={formContents[6].en}/>}  
            <br/> */}
              

        </div>
      );
    }
}

export default OdmContentHistory;
