import React, { Component } from 'react';

import FormBoardInquiry from '../common/FormBoardInquiry';




const ServiceContentInquiry = (props) => {
    var lang = props.location.pathname.split('/')[1];

    
    return (
        <div>
            {/* <FormBoardInquiry boardName={this.state.boardName} lang={this.state.lang} index={this.state.index} num={this.state.num }  ref_num={this.state.ref_num } modify={0}  reply={this.props.reply} 
                            stateRefresh={this.props.stateRefresh} contents={null} file={null}
                            /> */}

            <FormBoardInquiry  lang={lang} boardName={'inquiry'} kindof={'service'}   />           
        </div>
    );
};

export default ServiceContentInquiry;