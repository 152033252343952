import React from 'react';
import {NavLink,Link} from 'react-router-dom'
import shortid from 'shortid';


const BannerDesc = ({lang,desciptions}) => {
   let brr = desciptions.map((value,index) =>
        
    // <li><Link  key= {index}  to="#"  style={{color:"white", textDecoration:"none"}} > {value}   </Link></li>
    <li>{value}</li>       
    );
  return (
         <ul> {brr} </ul>
   );
 };
 


function HTMLHeaderHomeBanner({lang,sel}) 
{
  var mainmenu_items = ['','','','',''];
  var mainmenu=[];
  var hgt = '800px';
  
  //이부분은 첫화면(홈화면에만 적용된다) - 추후 정리한다 
  var banner_intro_desc;
  var banner_intro_info;
  var company_biz;
  var biz_descriptions =[];
  var col1_title ;
  var quotation_info=[];

  // 최상위 경로가 아니면 더미로 리턴한다  
  if(sel!=('/'+lang)){
    return  null;
  }
  if(lang==='en') {
    
    company_biz =["Industrial product ODM",  "HW Design / Certification",  "Embedded OS / Firware ", "Software Development", "IOT Edge Device & Service"];
    biz_descriptions =
      [
        ["Industrial HMI ",         "Mobile Handheld Terminal",   "Mobile Data Termianl",       "Embedded Control Board",     "IOT Edge Device" ],
        ["HW Design & Deveolpment", "Certification",              "Mass Production ",           "ODM OEM Production",         "Maintenance & Service"],
        ["Linux & YOCTO",            "YOCTO QT/QML Framework",   "Android Framework ",          "RTOS /Firmware / MICOM",     "IOT Edge Device Firmwares"],
        ["QT/ QML",                 "Android Apps.",              "Embedded MFC/ C#",           "Desktop & Web Applications", "IOT Service Developmet"],
        ["IOT Edge device Design",  "IOT Edge Firware",           "IOT Service Page Developmet", "",                            ""],
      ];
      banner_intro_desc="Leader of Industrial ODM Products & Solutions";
      banner_intro_info="Nexfield Solution";
  
  }else{
    
    company_biz =["산업용 제품 ODM 생산 공급", 
                  "소프트웨어 개발용역 및 유지보수", 
                  "임베디드 제품설계 / 인증 취득",
                  "임베디드OS 및 펌웨어 개발", 
                  "IOT 제품 생산 및 서비스 구축" ];
    biz_descriptions =
    [
      ["안드로이드 산업용/차량용 터미널 ",    "리눅스(QT/QML) 터미널",  "자율주행 통합컨트롤러",     "임베디드 컨트롤 보드",         "IOT 에지 센서 디바이스" ],
      ["임베디드 QT/ QML",       "임베디드 안드로이드, QT/QML 앱",             "안드로이드 아이폰 폰앱",         "데스크톱 응용/웹앱",         "IOT 서비스 페이지 개발"],
      ["하드웨어 설계/ 샘플제작 ",         "각종 인증 취득",                    "양산 및 사후관리 ",             "ODM OEM 생산 납품",           "제품유지 보수 및 업그레이드"],
      ["Linux 커널포팅",         "YOCTO QT/QML 프레임워크 포팅",  "안드로이드 커널 프레임워크 포팅",                  "RTOS /펌웨어/ 마이컴",        "IOT 디바이스 펌웨어"],
      ["IOT 서비스 구축 운용",   "IOT 서버 페이지 개발",              "Lora 기반 서비스 구축",         "필드버스/모드버스 서비스 구축",  "리액트/웹서비스 개발"    ],
    ];
    banner_intro_desc="ODM제품 생산 공급 | 제품 및 소프트웨어 개발용역 전문 기업";
    banner_intro_info="(주)넥스필드솔루션";
   }
   const banner_title = company_biz.map((_biz,inx)=>{return <li><Link to="#" key={shortid.generate} >{_biz}</Link></li> });
   const banner_desc = biz_descriptions.map((_desc,inx)=>{return  <BannerDesc desciptions={_desc} /> });

  return (     
    <div className="banner_contents_wrap">
      {/* <!-- 슬릭베너에 대한 타이틀 --> */}
      <div className="banner_intro" ><strong>{banner_intro_desc}</strong><em>{banner_intro_info}</em>
      </div>

      {/* <!-- 슬릭베너출력 --> */}
      <div className="banner_slick">
        <div><Link to="#" key={shortid.generate} ><img src="img/biz_odm.jpg" alt="BUSSINESS:IOT" width="60%" height="60%"/></Link></div>
        <div><Link to="#" key={shortid.generate} ><img src="img/biz_dev.jpg" alt="BUSSINESS:DEVELOPMENT SERVICE" width="60%" height="60%" /></Link></div>
        <div><Link to="#" key={shortid.generate} ><img src="img/biz_emb.jpg" alt="BUSSINESS:OEM" width="60%" height="60%" /></Link></div>
        <div><Link to="#" key={shortid.generate} ><img src="img/biz_si.jpg" alt="BUSSINESS:EMBEDDED" width="60%" height="60%"/ ></Link></div>
            <div><Link to="#" key={shortid.generate} ><img src="img/biz_iot.jpg" alt="BUSSINESS:SI" width="60%" height="60%"/></Link></div>
      </div>
      
      <div className="banner_contents">
        <div className="banner_title"  >
          <ul>
            {banner_title}
          </ul>
        </div>
        <div className="banner_desc" >
            {banner_desc}
        </div>
     

      </div>
 
    </div>
    // .banenr-contents 
      
  );
}
export default HTMLHeaderHomeBanner;