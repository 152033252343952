import React from 'react';
import { useParams} from 'react-router-dom';
const OdmContentProcedure = (props) => {

    // var lang = this.props.location.pathname.split('/')[1];
    var params = useParams();
    // var lang = props.location.pathname.split('/')[1];
    var lang = params.lang;
    return (

        <div  style={{ height:"800px" , paddingTop:"100px" ,fontFamily: 'Hi Melody' }} >
            {
                (lang=='ko')? 
                    <img src="img/odm/odmProcess_ko.png" width="80%" alt="biz_odm3"  /> 
                    :
                    <img src="img/odm/odmProcess_en.png" width="80%" alt="biz_odm3"  /> 
                
            }
              
        </div>

);
};

export default OdmContentProcedure;