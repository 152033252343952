import React, {Component} from 'react';
import $ from "jquery";
import {} from "jquery.cookie";

import HTMLFooter from "./app/HTMLFooter";
import './public/css/reset.css';
import './public/css/style-main.css';

class Footer extends Component{
  constructor(props) {
    super(props);
  
  }

  componentDidMount() {
      
   }

    render(){
      return(
        <div>
          <HTMLFooter lang={this.props.lang}/>
        </div>
      )
    }
}

export default Footer;