import React from 'react';
import {BrowserRouter as Router,Route,Switch,NavLink, Redirect, useParams} from 'react-router-dom';

import OdmContentHistory  from '../odm/OdmContentHistory'
import OdmContentPlatform  from '../odm/OdmContentPlatform'
import OdmContentProcedure  from '../odm/OdmContentProcedure'
import OdmContentInquiry  from '../odm/OdmContentInquiry'

import ContentMovieClip  from '../common/ContentMovieClip'

import '../../public/css/odm.css'


const OdmHeaderHistory = ({lang}) => {

  var titles = (lang=='ko')? ["주요 오디엠 이력 "] :
                            ["ODM Summary"];
  return (
      <div className='odm_header'>
          <NavLink  to= {"/" + lang + "/odm/1/1"} lang={lang} className='odm_item' activeClassName="active">{titles[0]}</NavLink>
       </div>
  );
};


const OdmHeaderPlatform = ({lang}) => {

  var titles = (lang=='ko')? ["오디엠 레퍼런스 플랫폼 "] :
                            ["ODM Reference Platform"];
  return (
      <div className='odm_header'>
          <NavLink  to= {"/" + lang + "/odm/2/1"} lang={lang} className='odm_item' activeClassName="active">{titles[0]}</NavLink>
   
      </div>
  );
};



const OdmHeaderProcess= ({lang}) => {
  var titles = (lang=='ko')? ["ODM 진행 절차  "] :  ["ODM Process"];

  return (
      <div className='odm_header'>
          <NavLink  to= {"/" + lang + "/odm/3/1"} lang={lang} className='odm_item' activeClassName="active">{titles[0]}</NavLink>
      </div>
  );
};

const OdmHeaderInquiry = ({lang}) => {
  var titles = (lang=='ko')? ["ODM 문의 및 요청  "] :  ["ODM Inquiry"];

  return (
      <div className='odm_header'>
          <NavLink  to= {"/" + lang + "/odm/4/1"} lang={lang} className='odm_item' activeClassName="active">{titles[0]}</NavLink>
      </div>
  );
};


function ContentOdmRoute({lang, index})
{
  switch(index){
    case 1:
      return  <div>
        <OdmHeaderHistory lang= {lang} />
        <Switch>
          <Route exact path="/odm/1"><Redirect to={'/'+ lang +'/odm/1'} /></Route>
          <Route  path={'/'+ lang +'/odm/1/1'}  component={OdmContentHistory} />
          </Switch>

        </div>
    
    case 2:
    return  <div>
      <OdmHeaderPlatform lang= {lang} />
      <Switch>
        <Route exact path="/odm/2"><Redirect to={'/'+ lang +'/odm/2'} /></Route>
        <Route  path={'/'+ lang +'/odm/2/1'}  component={OdmContentPlatform} />
      </Switch>
      </div>
    
    case 3:
    return  <div>
      <OdmHeaderProcess lang= {lang} />
      <Switch>
        <Route exact path="/odm/3"><Redirect to={'/'+ lang +'/odm/3'} /></Route>
        <Route  path={'/:lang/odm/3/1'}  component={OdmContentProcedure} />
      </Switch>
      </div>
    
    case 4:
      return  <div>
        <OdmHeaderInquiry lang= {lang} />
        <Switch>
          <Route exact path="/odm/4"><Redirect to={'/'+ lang +'/odm/3'} /></Route>
      
          <Route  path={'/'+ lang +'/odm/4/1'}  component={OdmContentInquiry} />
        </Switch>
        </div>
  } //switch
}

function HTMLContentOdm(props) 
{
  
    var params = useParams();
   // var lang = props.location.pathname.split('/')[1];
   var lang = params.language;

    var index =  parseInt(params.id);

    //높이 설정 
    const dstyle = {
      height: "1800px"
    }

    return (
      <div className="contents_cont"  id="s_contents" >
        <div className="_container">
          <div className="contents_wrap">
            <ContentOdmRoute lang={lang} index={index} />
          </div>
        </div>
       </div>
    );
 }

export default HTMLContentOdm;







