import React, { Component } from 'react';
import '../../public/css/product.css'

// 언어설정을 props.lang 과 같이 바로 전달받지 못한다.
// 예전에는 NavLink를 통해서 props도 바로 전달 받았었는데... 이상하네.. 일단 주소줄에서 파싱해서 언어를 설정한다 . 

class EMB_NXC9G45 extends React.Component {
  constructor(props) {
    super(props);
  }
    
  render() {
    const dstyle = {
      color: "white",
      border: '2px solid #3d52ad',
      backgroundColor: '#eee',
      // height: "1040px"  // 헤더의 높이가 차감된듯 
    }

    const hstyle = {
      color: "black",
      fontFamily: "Arial"
     
    }

    //주소줄로 부터 언어설정을 파싱..
    var language = this.props.location.pathname.split('/')[1];

    var prod_title = (language=='ko')? "윈도우CE/ QT 산업용HMI/산업용컴퓨터" : "WinCE/QT Indutrial HMI/Industrial Computer" ;
    var prod_subtitles = (language=='ko')? ["전면사진", "정면사진"] : ["Front view", "Askew view"]  ;
    var prod_use = (language=='ko')? 
    {
      title: "적용분야",
      desc: ["• 산업용컨트롤러 : 터치 디스플레이 패널 연결", "• 데이터 수집및 표시장치 : 이더넷/ 시리얼 통신을 통한 데이터 수집", "• 산업용 컨트롤 보드"]
    }:{
      title: "Application",
      desc: ["• Indutrial control board : Touch display panel (Optional)", "• Data gatherer : Ethernet / RS232", "• Single board computer(SBC)"]
    }
    var prod_title_specs = (language=='ko')? ["하드웨어 사양(NX9G45)", "소프트웨어 사양(NX9G45)"] : ["Hardware Specification(NX9G45)", "Software Specification(NX9G45)"]  ;

    return (
   
      <div className="prod_contents"  style={dstyle} >
        <br/>
        <div className="column">
          <div className="col_tab">
            <div className="col1">
              <h1 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "16px",fontStyle: 'italic', marginTop: '5px' }}>{prod_title}</h1>
              <hr/>
              <h2 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "14px"}}>{prod_subtitles[0]}</h2>
              <br/>
              <img border="0" src="/img/product/emb/nxc9g45.jpg"  width="50%" />
            </div>
            <div className="col2">
              <h1 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "16px",fontStyle: 'italic', marginTop: '5px' }}>{prod_title}</h1>
              <hr/>
              <h2 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "14px"}}>{prod_subtitles[1]}</h2>
              <br/>
              <img border="0" src="/img/product/emb/nxc9g45_ring.png"  width="70%" />
            </div>
          </div>
          <div className="col3">
            <br/>

            <h1 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "16px",fontStyle: 'italic', marginTop: '5px' }}>{prod_use.title}</h1>
            <br/>
            <center>
            <table border="0" cellpadding="0" cellspacing="0"   width ="60%">
                <tr bgcolor="#c8bfe">
                    <ul>
                    <li  style={{fontSize:"18px"}}>{prod_use.desc[0]}</li>
                    <li  style={{fontSize:"18px"}}>{prod_use.desc[1]}</li>
                    <li  style={{fontSize:"18px"}}>{prod_use.desc[2]} </li>
                    </ul>
                 </tr>
                
              </table>
              <br/>    
            </center>
            <h1 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "16px",fontStyle: 'italic', marginTop: '5px' }}>{prod_title_specs[0]}</h1>
            <br/>
            <center>
        
              <table border="1" cellpadding="0" cellspacing="0"   width ="60%">
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;<b>Name</b></td>
                    <td width="79%" bgcolor="#c8bfe">&nbsp;<b>Description</b></td>
                </tr>
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;CPU</td>
                    <td width="79%">&nbsp;ARM9 400MHz (ATSAM9G45/ATSAM9M10)</td>
                </tr>
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;Memory</td>
                    <td width="79%">&nbsp;128MB Flash/ 128MB DDR2 DRAM </td>
                </tr>
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;Display</td>
                    <td width="79%">&nbsp;8"(inch) TFT 24bit color 800x480 (max 1280x1024)</td>
                </tr>
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;Touch</td>
                    <td width="79%">&nbsp;4 wire registitive</td>
                </tr>
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;Audio</td>
                    <td width="79%">&nbsp;N/A </td>
                </tr>
                <tr>
                    <td width="21%"  bgcolor="#c8bfe" rowspan="5">&nbsp;IO</td>
                    <td width="79%">&nbsp;USB Host 2.0/Function 2.0</td>
                </tr>
                <tr>
                    <td width="79%">&nbsp;SD 1 Slots</td>
                </tr>
                <tr>
                    <td width="79%">&nbsp;Serial 4 port (RS-232 2 port, RS-485 2 port)</td>
                </tr>
                <tr>
                    <td width="79%">&nbsp;Eth 100BASE-T 1 port</td>
                </tr>
                <tr>
                    <td width="79%">&nbsp;External RTC with backup battery</td>
                </tr>
              </table>
            </center>

            <br/>
            <h1 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "16px",fontStyle: 'italic', marginTop: '5px' }}>{prod_title_specs[1]}</h1>
            <br/>
            <center> 
            <table border="1" cellpadding="0" cellspacing="0" width ="60%">
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;<b>Name</b></td>
                    <td width="79%" bgcolor="#c8bfe">&nbsp;<b>Description</b></td>
                </tr>
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;OS</td>
                    <td width="79%">&nbsp;Windows CE 6.0/ Linux</td>
                </tr>
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;SDK</td>
                    <td width="79%">&nbsp; Custom SDK Support</td>
                </tr>
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;Upgrade</td>
                    <td width="79%">&nbsp; OS Upgrade</td>
                </tr>
                <tr>
                    <td width="21%" bgcolor="#c8bfe" rowspan="2">&nbsp;Device&nbsp;
                    <p/>&nbsp;</td>
                    <td width="79%">&nbsp; Display/Audio/Touch/Serial/Eth/</td>
                </tr>
                <tr>
                    <td width="79%">&nbsp; USB/SD/RTC/</td>
                </tr>
                <tr>
                    <td width="21%" bgcolor="#c8bfe" rowspan="5">&nbsp;Cutomizing</td>
                    <td width="79%">&nbsp; Boot Logo </td>
                </tr>
                <tr>
                    <td width="79%">&nbsp; Display</td>
                </tr>
                <tr>
                    <td width="79%">&nbsp; Application Auto Lauching</td>
                </tr>
              </table>
            </center>
          </div>
          <div className="col4">
            
          </div>
          <div className="col5">
            
          </div>
          <div className="col6">
           
          </div>
          <div className="col7">
            
          </div>
        </div>
      </div>
    );
  }
}

export default EMB_NXC9G45;
