import React, { Component } from 'react';



class FormOdmBrief extends Component {

    constructor(props) {
        super(props);
    }

    
    render() {

        const {formContent} = this.props;
  
      
        const istyle = {
            backgroundColor: formContent.bgcolor[0],
            padding : "5px"
        }
        
        const tstyle = {
            color: "black",
            backgroundColor: formContent.bgcolor[1],
            fontFamily: "Arial",
            fontSize : "14px",
            paddingLeft: "20px",
            border : "0" 
        
        }

        const cstyle = {
            color: "purple",
            backgroundColor: formContent.bgcolor[1],
            fontFamily: "Arial",
            fontSize : "16px",
            paddingLeft: "10px",
            border : "0" 
        
        }

     
        const captionstyle = {
            color: "purple",
            backgroundColor: formContent.bgcolor[1],
            fontFamily: "Arial",
            fontSize : "16px",
          }
     

        return (
            <div>
               
               <center>
  
                <table  border="1" cellpadding="0" cellspacing="0"   width ="80%">
                    <tr>
                        <td  width={formContent.iWidth} height={formContent.iHeight}  rowspan="11" >
                        <img  style={istyle} border="0" src={formContent.image}  width={formContent.iWidth}  height={formContent.iHeight} align="center" />
                        {/* <img  style={istyle} border="0" src={formContent.stamp}  width={formContent.iWidth}  height={formContent.iHeight} align="center" /> */}
                        </td>
                    </tr>
                    {/* 리눅스    */}
                    <tr>
                        <td style={captionstyle}  width="25%" > 
                            <tr><td style={cstyle}   >  { formContent.titles[0]}</td></tr>
                            <tr><td style={tstyle}   >  { formContent.desc[0]}</td></tr>
                            <tr><td style={tstyle}   >  { formContent.desc[1]}</td></tr>
                            <tr><td style={tstyle}   >  { formContent.desc[2]}</td></tr>
                            <br/>   
                            <tr><td style={cstyle}   >  { formContent.titles[1]}</td></tr>
                            <tr><td style={tstyle}   >  { formContent.apply[0]}</td></tr>
                            <tr><td style={tstyle}   >  { formContent.apply[1]}</td></tr>
                            <tr><td style={tstyle}   >  { formContent.apply[2]}</td></tr>
                         </td>
                         <td style={captionstyle}   >                        
                            <tr><td style={cstyle}   >  { formContent.titles[2]}</td></tr>
                            <tr><td style={tstyle}   >  { formContent.spec[0]}</td></tr>
                            <tr><td style={tstyle}   >  { formContent.spec[1]}</td></tr>
                            <tr><td style={tstyle}   >  { formContent.spec[2]}</td></tr>
                            <br/>   
                            <tr><td style={cstyle}   >  { formContent.titles[3]}</td></tr>
                            <tr><td style={tstyle}   >  { formContent.cap[0]}</td></tr>
                            <tr><td style={tstyle}   >  { formContent.cap[1]}</td></tr>
                            <tr><td style={tstyle}   >  { formContent.cap[2]}</td></tr>
                          </td>
                

                        {/* 참조 사진이나 동영상 */}
                        <td width="20%" rowspan="11" >
                            {/* <img  style={istyle} border="0" src={formContent.image}  width={formContent.iWidth}  height={formContent.iHeight} align="center" /> */}
                            {(formContent.movie)? 
                                <video width={formContent.iWidth} height={formContent.iHeight} controls="controls" class="video-js vjs-default-skin" data-setup="{}" poster={formContent.poster} >
                                    <source src={formContent.movie}  type="video/mp4" />
                                </video>:null
                            }
                        </td>
                    </tr>

                  


                </table>
             </center>
            </div>
        );
    }
}


export default FormOdmBrief;