import React, { Component } from 'react';
import '../../public/css/product.css'

// 언어설정을 props.lang 과 같이 바로 전달받지 못한다.
// 예전에는 NavLink를 통해서 props도 바로 전달 받았었는데... 이상하네.. 일단 주소줄에서 파싱해서 언어를 설정한다 . 

class EMB_NXSTMDCM extends React.Component {
  constructor(props) {
    super(props);
  }
    
  render() {
    const dstyle = {
      color: "white",
      border: '2px solid #3d52ad',
      backgroundColor: '#eee',
      // height: "1040px"  // 헤더의 높이가 차감된듯 
    }

    const hstyle = {
      color: "black",
      fontFamily: "Arial"
     
    }

    //주소줄로 부터 언어설정을 파싱..
    var language = this.props.location.pathname.split('/')[1];

    var prod_title = (language=='ko')? "DC 모터컨트롤 보드/마이컴 보드/ 펌웨어 보드" : "DC Motor controller" ;
    var prod_subtitles = (language=='ko')? ["전면사진", "데모 영상"] : ["Front view", "Demo movie"]  ;
    var prod_use = (language=='ko')? 
    {
      title: "적용분야",
      desc: ["• 스마트팜용 모드버스기반 DC모터 원격 제어 컨트롤 보드", "• DC 모터 활용 제품"]
    }:{
      title: "Application",
      desc: ["• Window control board", "• DC Motor Control board"]
    }
    var prod_feature = (language=='ko')? 
    {
      title: "특장점",
      desc: ["• DC모터제어 보드", "• 방향 제어 및 이동 거리 제어", "• 부하초과시 긴급 멈춤"]
    }:{
      title: "Key Features",
      desc: ["• DC Motor control board ", "• Motor direction & distance control", "• Quict stop on overloaded condition"]
    }
    var prod_title_specs = (language=='ko')? ["제품 사양(NXSTMDCM)", ""] : ["Specification(NXSTMDCM)", ""]  ;

    return (
   
      <div className="prod_contents"  style={dstyle} >
        <br/>
        <div className="column">
          <div className="col_tab">
            <div className="col1">
                {/* 사진 위의 덧글 */}
                <h1 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "16px",fontStyle: 'italic', marginTop: '5px' }}>{prod_title}</h1>
                <hr/>
                <h2 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "14px"}}>{prod_subtitles[0]}</h2>
                <br/>
                {/* <img border="0" src="/img/product/emb/nxstm-dcm.jpg"  width="50%" /> */}
                <img border="0" src="/img/product/emb/nxstm-dcm2.png"  width="50%" />
              </div>

              <div className="col2">
                <h1 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "16px",fontStyle: 'italic', marginTop: '5px' }}>{prod_title}</h1>
                <hr/>
                <h2 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "14px"}}>{prod_subtitles[1]}</h2>
                <br/>
                {/* <img border="0" src="/img/product/emb/NXSTMDCM_2.jpg"  width="70%" /> */}
            
                {/* <video width="480" height="300" controls="controls" class="video-js vjs-default-skin" data-setup="{}" poster="mov/nxstm-dcm_demo.jpg"> */}
                <video width="480" height="300" controls="controls" class="video-js vjs-default-skin" data-setup="{}" poster="mov/nxstm-dcm2_demo.png">
       ?
                <source src="/mov/nxstm-dcm2_demo.mp4"  type="video/mp4" />
                </video>
            </div>
          </div>


          <div className="col3">
            <br/>

            <h1 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "16px",fontStyle: 'italic', marginTop: '5px' }}>{prod_use.title}</h1>
            <br/>
            <center>
            <table border="0" cellpadding="0" cellspacing="0"   width ="60%">
                <tr bgcolor="#c8bfe">
                    <ul>
                    <li  style={{fontSize:"18px"}}>{prod_use.desc[0]}</li>
                    <li  style={{fontSize:"18px"}}>{prod_use.desc[1]}</li>
                    <li  style={{fontSize:"18px"}}>{prod_use.desc[2]} </li>
                    </ul>
                 </tr>
                
            </table>
            <br/>    
            <h1 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "16px",fontStyle: 'italic', marginTop: '5px' }}>{prod_feature.title}</h1>
            <br/>    
            <table border="0" cellpadding="0" cellspacing="0"   width ="60%">
              <tr bgcolor="#c8bfe">
                  <ul>
                  <li  style={{fontSize:"18px"}}>{prod_feature.desc[0]}</li>
                  <li  style={{fontSize:"18px"}}>{prod_feature.desc[1]}</li>
                  <li  style={{fontSize:"18px"}}>{prod_feature.desc[2]} </li>
                  </ul>
                </tr>
              
            </table>
            <br/>    
     
            </center>
            <h1 style={hstyle, { paddingLeft : "0px", textAlign: 'center', fontSize: "16px",fontStyle: 'italic', marginTop: '5px' }}>{prod_title_specs[0]}</h1>
            <br/>
            <center>
        
              <table border="1" cellpadding="0" cellspacing="0"   width ="60%">
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;<b>Name</b></td>
                    <td width="79%" bgcolor="#c8bfe">&nbsp;<b>Description</b></td>
                </tr>
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;CPU</td>
                    <td width="79%">&nbsp;STM8</td>
                </tr>
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;Motor driver IC</td>
                    <td width="79%">&nbsp;ROHM DC motor control IC </td>
                </tr>
                <tr>
                    <td width="21%" bgcolor="#c8bfe" >&nbsp;ETC</td>
                    <td width="79%">
                      &nbsp;Direction control /
                      &nbsp;Quick stop on overloaded condition
                    </td>
                </tr>
                 </table>
            </center>

   
        
          </div>
          <div className="col4">
            
          </div>
          <div className="col5">
            
          </div>
          <div className="col6">
           
          </div>
          <div className="col7">
            
          </div>
        </div>
      </div>
    );
  }
}

export default EMB_NXSTMDCM;
