import React, { Component } from 'react';
import '../../App.css';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ABoardAdd from '../board/ABoardAdd';
import ABoardModify from '../board/ABoardModify';
import ABoardDelete from '../board/ABoardDelete';
import shortid from 'shortid';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CustomUploadAdapterPlugin from '../board/CustomUploaderAdapterPlugin';
import '../common/board.css';

const styles = theme => ({
  content_root: {
    width: "100%",
    // minWidth: 1080
  },
  
  paper: {
    marginLeft: 18,
    marginRight: 18
  },
  progress: {
    margin: theme.spacing.unit * 2
  },
  grow: {
    flexGrow: 1,
  },
  tableHead: {
    fontSize: '1.0rem'
  }
  
  
});

class BoardContent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      boardContents: '',

      searchKeyword: '',
      lang: '',
      boardMode:'',
      boardName:'',
      index: '',
      subindex: '',
      num: '',
      edit: 0,
      user_passwd: '',
      reply: 0,
  
     }

     this.stateRefresh = this.stateRefresh.bind(this);
     this.handleValueChange = this.handleValueChange.bind(this);
     this.handleToList = this.handleToList.bind(this);
     this.handleEdit = this.handleEdit.bind(this);
     this.handleReply = this.handleReply.bind(this);
    
    // if(this.props.location.query)
    //  this.state.num = this.props.location.query.num;  // 현재의 라우터가 reload 될때에는... undefined 된다...
    // else 
    this.state.lang= props.location.pathname.split('/')[1];
   
    // this.state.boardMode= props.history.location.pathname.split('/')[2]; //게시판의 (실행)모드 
    this.state.boardMode=props.match.params.board; //게시판의 (실행)모드 

    this.state.index= props.location.pathname.split('/')[3];
    this.state.subindex= props.location.pathname.split('/')[4];
    this.state.num= props.location.pathname.split('/')[5];
    this.state.boardName= 'support';
    if(this.state.boardMode=='admin') 
    {
      this.state.boardName= 'admin';
         // /admin 라우터에서... 3,4번째 파라키터가 모두 '3' 이면, 관리자모드에서 기술지원 게시판을 접근한다는 의미다 
      if((this.state.index=='3') && (this.state.subindex=='3'))
      {
        this.state.index= props.location.pathname.split('/')[5];
        this.state.subindex= props.location.pathname.split('/')[6];
        this.state.num= props.location.pathname.split('/')[7];
        this.state.boardName= 'support';
      }
    }
 
  }
  isMobile = () =>  { return ((window.innerWidth <800)) ?true:false;}

  stateRefresh() {
    this.setState({
      boardContents: '',
      completed: 0,
      searchKeyword: '',
      user_passwd : '',
      reply: 0,
  
    });
    this.callBoardContent()
      .then(res => this.setState({boardContents: res}))
      .catch(err => console.log(err));
  }

  componentDidMount() {
   // this.timer = setInterval(this.progress, 20);
    this.callBoardContent()
      .then(res => this.setState({boardContents: res}))
      .catch(err => console.log(err));
  }

  componentWillUnmount() {
   // clearInterval(this.timer);
  }

  //특정 아티클만 가져옴  : 서버 라우터로 전달할때는 문자열로 전달해야한다. 
  // index : 게시판 인덱스
  // num   : 게시글 인덱스 
  callBoardContent = async () => {
    const response = await fetch(`/api/${this.state.boardName}/content/`+ '/' + this.state.index + '/' + this.state.subIndex + '/' + this.state.num);
    const body = await response.json();
    return body;
  }

  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  };

  handleValueChange(e) {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }
 
  handleToList  = () => {
    var lang = this.state.lang;
    var index = this.state.index; //게시판 종류 
    var subIndex = this.state.subIndex; //게시판 종류 

    this.props.history.push(`/${lang}/${this.state.boardName}/${index}/${subIndex}`);
  }

  handleEdit = () => {
    this.setState((prevState, prevProps) => {
      return {edit: 1};
    }, 
    () =>{ 
      this.forceUpdate();
      // console.log(this.state.edit);
    });
  }

  handleReply = () => {
    this.setState((prevState, prevProps) => {
      return {edit:1, reply: 1};
    }, 
    () =>{ 
      this.forceUpdate();
      // console.log(this.state.edit);
      // console.log(this.state.reply);
    });
  }
 
  render() {
    var content = null ;
    var c_content = null ;
    var c_image = null;
   
    const { classes } = this.props;
    const filteredComponents = (data) => {
      data = data.filter((c) => {
       
          return c.writer.indexOf(this.state.searchKeyword) > -1;
      });

      // 디테일 화면을 보여준다...
      return data.map((c) => {
        if(this.state.num==c.num)
        {
          var lang = this.state.lang;
          var nIndex = parseInt(this.state.index)-1; //게시판 종류 
          var num=parseInt(c.num); //게시글 번호 
          c_content = c.content ;
          var c_contents = c;
          var c_writer = c.writer;
          var c_passwd = c.passwd;
          c_image = c.image;
          var c_ref = c.ref;

        var titles =  (this.state.lang=='ko')? 
          {
            support: ["견적 및 영업문의","게시판(기술문의)","자료실","뉴스 및 액티비티"] ,
            admin:["일일 업무 내역","사내 자료실","관리/통계"] 
          }:{
            support: ["Quotation/Inquiry","Board(Technical Inquiry)","Refernce Room","News/ Anncouncement"],
            admin: ["Daily works","Refernce Room","Management/Statistics"]
          }    
          
        //언어별 게시판별 타이틀   
        var curTitle =  (this.state.boardName=='admin')? titles.admin[nIndex] : titles.support[nIndex];
   

          var info = (lang='ko')? `게시판 : ${curTitle} `  : `Board : ${curTitle}` ;
          var info2 = (lang='ko')? `글 번호: ${num} | 작성자: ${c.writer} | 작성일: ${c.reg_date} ` 
              : `Articale: ${num} | Writer: ${c.writer}  | Date: ${c.reg_date} ` ;
          var info3 = (lang='ko')? `제목 : ${c.subject} `  : `Subject: ${c.subject}` ;
          var captionReply = (this.state.lang=='ko')?"답글 쓰기"  : "Write reply" ;
          var captionModify = (this.state.lang=='ko')? "수정하기" : "Modify" ;
          var tolist = (lang='ko')? "목록보기"  : "Show list ";
          
          const infostyle = {
              color: "black",
              backgroundColor: '#acc', // 
              fontSize : '14px',
              fontWeight: 'bold'
          }

          const dstyle = {
              color: "black",
              backgroundColor: '#eeeeee', // 
              fontSize : '14px',
              textTransform: 'none' 
          }
          var btnBgColor =  this.state.edit? '#777777' :'#5cbd5d' ;
          var btnDisabled =  this.state.edit? true:false ;
          var editor_width = this.isMobile()?'80%':'100%';

          //이미지가 있으면 이미지를 앞부분에 추가 : CEEditor의 image 기능을 사용하지 않을때 임시로 사용하는 방법 
          content =  c_content ;
          //if((c_image!='')&& (c_image!=null))  content = `<img src=${c_image} alt="profile"/>` +   c_content ;

          return (

          <div className={classes.root} >
       
            <AppBar position="static">
              <Toolbar>
                {/* 게시판 종류 */}
                <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                  {curTitle}
                </Typography>
  
                 <div className={classes.grow} />
                   <div className={classes.menu} style={{width:"14%"}}>
                    <Button variant="contained" disabled={btnDisabled} style={{color: '#ffffff',backgroundColor: `${btnBgColor}` }} onClick={this.handleReply}>{captionReply}</Button>
                  </div>
                  <div className={classes.menu} style={{width:"14%"}}>
                    <Button variant="contained" disabled={btnDisabled} style={{color: '#ffffff',backgroundColor: `${btnBgColor}` }} onClick={this.handleEdit}>{captionModify}</Button>
                  </div>
                  <div className={classes.menu} style={{width:"14%"}}>
                    <Button variant="contained" disabled={btnDisabled} style={{color: '#ffffff',backgroundColor: `${btnBgColor}` }} onClick={this.handleToList}>{tolist}</Button>
                  </div>
              </Toolbar>
            </AppBar>
            { this.state.edit ?
                <>
                 { (this.state.reply==0) ?
                
                    // 수정/편집모드
                    <ABoardModify boardName={this.state.boardName} lang={this.state.lang} reply={this.state.reply}  index={this.state.index}  num={this.state.num}  
                          history={this.props.history}  contents={c_contents}
                          writer={c_writer}  passwd={c_passwd}  image={c_image} 
                          stateRefresh={this.stateRefresh}  form={1} 
                    />
                    :
                    // 새글(회신) 추가  "80%",: 현재는 컨텐츠화면이므로.. 회신글 추가에 속한다 
                    // 글등록시에는 ref도 함께 넘겨준다 
                    <ABoardAdd boardName={this.state.boardName} lang={this.state.lang} num={this.state.num}  ref_num={c_ref}  reply={this.state.reply}  index={this.state.index} history={this.props.history} 
                      stateRefresh={this.stateRefresh}  form={1} 
                    /> 
                  } 
                </> 
                :
                //게시글 보기모드 
                <Table  style={{margin: '0 auto' }}>
                  <TableBody>
                    <TableRow  style={infostyle} >
                      <TableCell  key={shortid.generate}  align="left" style={{width: '70%'}}>
                        {info}<br/>{info2}<br/>{info3}
                      </TableCell>
                      <TableCell  key={shortid.generate} align="left" >
                        <ABoardDelete boardName={this.state.boardName} lang={this.state.lang} history={this.props.history} index={this.state.index} num={this.state.num}
                                      writer={c_writer}  passwd={c_passwd}  stateRefresh={this.stateRefresh}  /> 
                      </TableCell>
                    </TableRow>
                      
                    <TableRow  >
                      <TableCell  key={shortid.generate} colSpan="8" align="left" >

                      <div className="ck-content-wrap"  style={{width:`${editor_width}`, margin: '0 auto'}}> 
                           {/* <CKCustomEditor content={content}/> */}
                          <CKEditor  
                              editor={ClassicEditor}
                              data={content} 
                          
                              onChange={ ( event, editor ) => {
                                  var data = editor.getData();
                                  this.setState({c_content:data});
                                } 
                              }
                              
                              onInit={editor => {
                                editor.isReadOnly = true;    
                                }}
                              config={
                                  {language:'ko'},
                                  {toolbar: 
                                    {
                                    },
                                    image: {
                                        styles: ['alignLeft', 'alignCenter', 'alignRight'],                            
                                      toolbar: [
                                        'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
                                        '|',
                                        'resizeImage',
                                        '|',
                                        'imageTextAlternative'
                                    ]                            
                                  },
                                  heading: {
                                      options: [
                                          { model: 'heading1', view: 'h1', title: '헤더1', class: 'ck-heading_heading1' },
                                          { model: 'heading2', view: 'h2', title: '헤더2', class: 'ck-heading_heading2' },
                                          { model: 'heading3', view: 'h3', title: '헤더3', class: 'ck-heading_heading3' },
                                          { model: 'paragraph', title: '본문', class: 'ck-heading_paragraph' },
                                      ]
                                  },
                                  // ckfinder: {
                                  // uploadUrl: `/api/uploadFile`
                                    // },
            
                                }
                              }
                          />                             
                        </div>

                        </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
               }
              </div>   

           
          )
        }   
      });
    }

    
    return (
      <div className={classes.content_root}>
        {/* 블록 가운데 정렬은 먹는다  */}
        <div style={{ width: '100%', margin: "0 auto"}}>
          <Paper className={classes.paper}>
            <Table>
               <TableBody>
                {this.state.boardContents ?
                  filteredComponents(this.state.boardContents) :
                  <TableRow>
                    <TableCell  key={shortid.generate} colSpan="6" align="center">
                      <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </Paper>
        </div>
      </div>
  );
  }
}

export default withStyles(styles)(BoardContent);
