import React, { Component } from 'react';

import FormBoardInquiry from '../common/FormBoardInquiry';




const OdmContentInquiry = (props) => {
    var lang = props.location.pathname.split('/')[1];
       const dstyle = {
            color: "white",
            border: '2px solid #3d52ad',
            backgroundColor: '#eee',
            // height: "1040px"  // 헤더의 높이가 차감된듯 
          }
    return (
        <div>
          <FormBoardInquiry  lang={lang} boardName={'inquiry'} kindof={'odm'}   />           
        </div>
    );
};

export default OdmContentInquiry;