import React, { useState,}  from 'react';
import {BrowserRouter as Router,Route,Switch,NavLink, Redirect, useParams} from 'react-router-dom';

import GMap from '../../components/GMap'

///////////////////////////////////////////////////
/* 회사소개 : 설정된 언어별로 화면을 보여준다 */
function CompanyGreetings({lang})
{
    var _description ;
    var _description_title;
  
      if(lang=='en') {
      _description_title ="Specialized on industrial Products / Softwares";
    
      return (
        <div id="company_greetings">
          <h2>{_description_title}</h2>
          <div class="company_greetings">
            <h3>
              <em>Nexfield Co./Ltd. </em> has specialty for
              the industrial devices and platforms and various range of softwares.
            </h3>
            <br/>
              The <font color = '#0x55ced'>  &quot; Nexfield &quot; </font> denotes our moto -
               &quot; Just go to the next industrial fields&quot; .
         
               In accordance with the words, We have accumulated rich experiences
               for various industrial terminals, devices, and their customized softwares.<br/>
               <br/>
               We belive we could be your good business partner or co-workers. <br/>
               <br/>
               Just contact us!
               <br/><br/><br/>
               <center>from Nexfield family</center>
          </div>
          // .company_greetings 
        </div>
        // #company_greetings       
      );
    }else{
      _description_title ="산업용 제품 / 소프트웨어 개발 공급 전문";
      return (
        <div id="company_greetings">
          <h2>{_description_title}</h2>
          <div class="company_greetings">
            <h3>
            <em>(주)넥스필드솔루션</em>은 산업용장비 및 임베디드 제품 그리고 각종 소프트웨어를 전문으로 개발 및 공급하는 회사입니다<br/><br/>
            </h3>
            2006년에 설립된 이후로 산업현장에서 필요로 하는 다양한 장비를 개발,생산,공급 및 유지관리하고,<br/>
            필수적인 소프트웨어솔루션도 함께 공급하고 있습니다.<br/>
            <br/>
            산업용제품들에 대한 폭넓은 제품경험과 솔루션들을 보유하고 있으며,
            다양한 임베디드플랫폼들과 각종 소프트웨어도 직접 개발 공급하고 있습니다.<br/>
      
            귀사의 제품과 비지니스에 실질적인 도움을 드리는 파트너로서의 역할을 다하겠습니다.<br/>
            <br/>
            <center>(주)넥스필드솔루션 일동 </center>
          </div>
          {/* .company_greetings  */}
        </div>
        // #company_greetings     
      );

   
    }
  
  }


///////////////////////////////////////////////////
/* 회사연혁 : 코딩만 해둠... 아직 미완 임 */
function CompanyHistory({lang}){

  var comp_history ;
  var company_history_title;

  if(lang=='en') {
    company_history_title =`History`;
    comp_history =[
      "2006-04 : Nexfield Solution Co. is created",
      "2005-09 : Nexfield is established"
      ];
  }else{
   company_history_title =`회사 연혁`;
   comp_history =[
      "2019-09 : 철도공사 교통카드 개집표기 (Linux QT)납품",
      "2017-07 : 에스엔티모티브(주) 협력업체 등록",
      "2016-06 : LS Electric(주) 전기계측기 개발", 
      "2014-01 : LS Electric(주) 산업용HMI 유지보수계약 체결" ,
      "2013-03 : LS Electric(주) 협력업체 등록 ",
      "2011-12 : 안드로이드 산업용핸디터미널 개발",
      "2009-04 : 산업용 핸디터미널(메인보드/모듈) 오디엠 납품",
      "2007-08 : 삼성전자(주) 협력업체 등록 ",
      "2007-04 : 주차관제시스템 컨트롤 보드 오디엠 납품 ",
      "2006-08 : 노틸러스 효성(주) 협력업체 등록 ",
      "2006-04 : (주)넥스필드솔루션 창립 ",
      "2005-09 : 넥스필드 창립."
      ];
    }

    // 백앤드에서 가져온 코드로 수정작업이 필요한다 
    return (
      <div id="s_contents">
        <div class="container">
          <div class="contents_wrap">
            <div id="company_history">
              {/* <H2  class="ir_pm">${company_history_title}</H2> */}
              <div class="company_history">
                {/* ${comp_history} */}
              </div>
            </div>
            {/* <!-- .company_history --> */}
          </div>
          {/* <!-- .contents_wrap --> */}
        </div>
        {/* <!-- .container --> */}
      </div>
      // <!-- #contents -->
      )
}



///////////////////////////////////////////////////
/* 회사비즈 : 설정된 언어별로 화면을 보여준다 */
function CompanyBusiness({lang}){
  var _description ;
  var _description_title = [];

  if(lang=='en') {
    _description_title =`industrial Products / SW - NEXFIELD SOLUTION CO.`;
    _description =[
      {
        tit:"Industrial product ODM",
        sub_tit: "Linux | Android | WinCE | Ubuntu | Firmware based products",
        list: [
          "CPU module (SOM) - CPU modules for PDA /  Handhelds",
          "Vechicle terminal ODM - PND/ MDT / Fleet management / Vechicle diagnostic termial",
          "Mobile/Handhelds terminal ODM - PDA / Barcoder scanner/ Handhelds",
          "Industrial Main board - Main board for parking control system/ ATM / HMI / MMI",
          "Smart grid terminal - MODBUS Electronic measuring instrument",
          "Latent (Automatic Gate or Automatic Gate Machine)"
        ],
        link:"<a href='#'>More about...</a>"
      },

     {
        tit:"Development services",
        sub_tit: "We are doing technical & development services on various regions",
        list: [
          "PND - TCC8970 Quard core / Android Kitkat",
          "HMI - iMX6 DualLite / Linux YOCTO / WinCE7.0",
          "Custom - RK3328 Quard core / Android Kitkat",
          "Industrial MMI - TCC8931 / WinCE7.0",
          "Electronic measuring instrument  - AT91SAM9M10 400MHz /WinCE6.0/Electronic measuring instrument SW",
          "Automatic Gate Machine - TCC8925 / Linux QT /  Operating apps.",
          "Barcoder scanner & PDA - AM3715 / Android",
          "ETC - OMAP3(AM3715)/PXA320/S5PV210"
        ],
        link:"<a href='#'>More about...</a>"
      },      
     {
      tit:"Compose & provide IOT | Smart grid services.",
      sub_tit: "We are supplying edge devices and serving pages for IOT based services",
      list: [
        "IOT Edge/ Gateway / Devices - Nordic, MICOM, iMX6 ",
        "Smart grid instrument & softwares",
        "Compose IOT Pages & Service backend/ Operating custome services",
        "Compose and Serve cloud based services"
      ],
      link:"<a href='#'>More about...</a>"
     }
   ] ;

  }else{
    _description_title =`산업용 제품 / 소프트웨어 전문 - (주)넥스필드솔루션`;
    _description =[
      {
        tit:"산업용 제품 및 각종 소프트웨어를 개발 및 공급합니다",
        sub_tit: "리눅스 | 안드로이드 | 윈CE | 우분투 | 펌웨어 기반 각종 제품 개발 및 공급",
        list: [
            "모바일/핸드헬드 단말기 ODM - PDA / 바코더스캐너/ Handhelds",
            "모바일 CPU모듈 (SOM) - PDA /  Handhelds용 CPU모듈 ",
            "차량용 터미널 ODM - PND/ MDT / 차량진단기 ",
            "산업용 메인보드/ 컨트롤보드 - 주차장 관제시스템 메인보드/ ATM보드 / HMI / MMI",
            "스마트그리드 터미널 - 모드버스 전기전력 계측기 ",
            "기타 산업용 터미널 - 교통카드 개집표기 "
        ],
        link:"더 알아보기..."
      },
      {
        tit:"다양한 협력과제 및 용역개발을 수행하고 있습니다",
        sub_tit: "하드웨어설계 | 임베디드OS | 각종 소프트웨어 개발 과제를 수행합니다",
        list: [
          "PND - TCC8970 Quard core / 안드로이드 Kitkat",
          "HMI - iMX6 DualLite / 리눅스 YOCTO / 윈CE7.0",
          "커스텀제품 - RK3328 Quard core / 안드로이드 Kitkat",
          "산업용MMI - TCC8931 / 윈CE7.0",
          "전기계측기 - AT91SAM9M10 400MHz /윈CE6.0/모드버스계측기 소프트웨어",
          "개집표기 - TCC8925 / Linux QT / 개집표기 소프트웨어",
          "바코더겸용 PDA - AM3715 / 안드로이드",
          "기타 - OMAP3(AM3715)/PXA320/S5PV210"
        ],
        link:"더 알아보기..."
      },
      {
        tit:"IOT 및 스마트그리드 기반의 서비스를 제공합니다",
        sub_tit: "IOT 에지 / 게이트웨이 / IOT 웹서비스 페이지",
        list: [
          "IOT 에지/ 게이트웨이 장치 개발 공급 - Nordic, MICOM, iMX6 및 각종 AP기반",
          "스마트그리드 장비 및 소프트웨어 개발 공급 ",
          "IOT 웹페이지 및 서비스 백앤드 개발 /구축 / 운용",
          "클라우드기반 서비스 개발/공급"
        ],
        link:"더 알아보기..."
      } 
    ] ;
  }

   var tempHTML=["","",""];
   
    tempHTML[0] = _description[0].list.map((desc) => 
      <li>{desc}</li>
     );
    tempHTML[1] = _description[0].list.map((desc) => 
    <li>{desc}</li>
    );
    tempHTML[2] = _description[0].list.map((desc) => 
    <li>{desc}</li>
   );

  return (
    
          <div id="company_business">
              <h2  >{_description_title}</h2>
              <div class="company_business">
              <h3>{_description[0].tit}</h3>
              <h4>{_description[0].sub_tit}</h4>
              <ul>
                {tempHTML[0]} 
              </ul>
              {/* <a href='#'>{_description[0].link}</a><br/><br/> */}
              <br></br>
              <h3>{_description[1].tit}</h3>
              <h4>{_description[1].sub_tit}</h4>
              <ul>
                {tempHTML[1]} 
              </ul>
              {/* <a href='#'>{_description[1].link}</a><br/><br/> */}
              <br></br>
              <h3>{_description[2].tit}</h3>
              <h4>{_description[2].sub_tit}</h4>
              <ul>
                {tempHTML[2]} 
              </ul>
              {/* <a href='#'>{_description[2].link}</a><br/><br/> */}
              <br></br>
              </div>
              {/* <!-- .company_business --> */}
            </div>
            // {/* <!-- #company_business --> */}

  )
}


///////////////////////////////////////////////////
/* 찾아오시는길 : 설정된 언어별로 화면을 보여준다 */
function CompanyContactUs({lang}){

  var _address ;
  var _description_title = [];
  var _title;
  var _company;

    if(lang=='en') {
      _title =`Location`;
      _company =`Nexfield Solution Co./Ltd.`;
 
      _address =[
        "Address : (Zip) 07003,  4FL. 220-55, SADANG-DONG, DONGJAK-GU, SEOUL, KOREA",     
        "NexFiels Solution Co./Ltd.",
        "TEL. : ", "+82-2057-2422", "FAX. : " ,"+82-2057-2423", "Mail :", "support@nexfield.net"
        // "Directions: Subway line #7 Namsung Subway stop Exit#3, under 3 minuts on foot"
      ]
    }else{
      _title =`찾아오시는 길`;
      _company =`(주)넥스필드솔루션`;
      _address =[
        "도로명 주소 : (우) 07003  서울시 동작구 사당로 189-1 4 층 (주)넥스필드솔루션",
        "사업자등록번호 : 214-87-88697 | 업종: 제조/도소매/서비스 | 업태: 산업용컴퓨터 및 주변기기/소프트웨어",
       
        "문의전화 : ", "(02) 2057-2422", "팩스 : ", "(02) 2057-2423", "문의메일", "support@nexfield.net"
      ]
    }

  return (
  
          <div id="company_contactus">
              <h2  >{_title}</h2>
              <div className="company_contactus">
                {/* 지도표시 */}
                
                <div className="map_canvas" >
                  <div id="map_canvas" >
                    <GMap lang={lang} />
                  </div>
                 </div>
                {/* 주소표시  */}
                <address>
                <strong>{_company}</strong><br/>
                <em>{_address[0]}</em><br/>
                <em>{_address[1]}</em><br/><br/>
                <em>{_address[2]}</em><strong>{_address[3]}</strong>&nbsp;<em>{_address[4]}&nbsp;{_address[5]}&nbsp;{_address[6]}</em>&nbsp;<strong>{_address[7]}</strong><br/>
                </address>
              </div>


            </div>
            

  )
}
function CompanyContent({lang, index})
{
  switch(index){
    case 1: return <CompanyGreetings lang={lang}/>;
    case 2: return <CompanyBusiness lang={lang}/>;
    case 3: return <CompanyContactUs lang={lang}/>;
    defaut: return <CompanyGreetings lang={lang}/>;
 
  }


}

function HTMLContentCompany(props) 
{
  
    var params = useParams();
    // var lang = props.location.pathname.split('/')[1];
    var lang = params.language;
   var index =  parseInt(params.id);

    const dstyle = {
      height: "1200px"
    }

    return (
      
      <div className="contents_cont" id="s_contents" >
        <div className="_container">
          <div className="contents_wrap">
            <CompanyContent lang={lang} index={index} />
          </div>
          {/* <!-- .contents_wrap --> */}
        </div>
        {/* <!-- ._container --> */}
      </div>
      // #contents

    );
 }



export default HTMLContentCompany;