import React from 'react';
import {NavLink,Link} from 'react-router-dom'
import shortid from 'shortid';
//이부분은 (홈화면을 제외한 화면들에서만 적용된다) - 추후 정리한다 

// 라우터를 통해서 넘어오는 props와 Header를 통하는 경우가 서로 다르다
function HTMLCommonNaviContent(props) 
{
  var hgt = '300px';
  var navi_tit =[];
  var navi_contents =[];
  // var params = props.pathname.split('/');
  // var subIndex = Number(params[3]);
   // var pathname =params[2];
  var pathname = props.pathname.split('/')[1];
  var lang = props.lang;
  var index=0;
//debugger
  switch(pathname){
    case 'company': index=1; break;
    case 'product': index=2; break;
    case 'odm':     index=3; break;
    case 'service': index=4; break;
    case 'support': index=5; break;
    default:    break;                
  }
  //debugger;
  if(lang==='en') {
  
    navi_tit = 
    [
      'ODM Productions',
      'Technical service(Technology)',
      'Inquiry/Q&A/Reference room'
    ]
     
    
    navi_contents = 
    { 
      odm: [
        { title:'ODM reference design', link:`/${lang}/odm/2/1`, level:1},
        { title:'ODM procedures',       link:`/${lang}/odm/3/1`},
        { title:'ODM production',       link:`/${lang}/odm/1/1`},
        { title:'ODM movie clip',       link:`/${lang}/odm/2/1`}, //임시로 사례보기에 연결 
        { title:'ODM FAQ',              link:'#'},
      ],
      service:[
        { title: 'Industrial Android product ',link:`/${lang}/product/2/1`},
        { title: 'Fleet management termial Design',link:`/${lang}/service/1/1`},
        { title: 'Industrial HMI/MMI Design',link:`/${lang}/product/1/1`},
        { title: 'Embedded Product Design ',link:`/${lang}/product/2/1`},
        { title: 'Embedded CPU module design & supply ',link:`/${lang}/product/2/1`},        
        { title: 'Linux kernel porting',link:`/${lang}/service/1/1`},
        { title: 'Android Framework porting',link:`/${lang}/service/2/1`},
      //  { title: 'ETC: Ubuntu/ Win10 IOT porting',link:'#'},
        { title: 'Embedded s/w - QT/QML',link:'#'},
        { title: 'Embedded s/w - Android / C#',link:`/${lang}/service/2/1`},
        { title: 'MICOM/ Firmware(Arduino)',link:`/${lang}/product/1/4`},
        { title: 'Phone app / Desktop s/w',link:'#'},
        { title: 'IOT Edge device design',link:`/${lang}/product/1/4`},
        { title: 'IOT Service page',link:'#'},
        { title: 'Smart grid devices',link:`/${lang}/service/2/1`},
        { title: 'Smart grid device s/w',link:`/${lang}/service/2/1`},
        { title: 'Development: Success stry',link:`/${lang}/service/1/1`},
        { title: 'Development: Movie clips',link:`/${lang}/service/2/1`},
      ],
      inquiry:[
        { title: 'Inquiry for PO',link:`/${lang}/support/1/1`},
        { title: 'Inquiry to request Technical service',link:`/${lang}/support/1/1`},
        { title: 'Q&A(Technical Questions)',link:`/${lang}/support/2/1`},
        { title: 'Refernce room',link:`/${lang}/support/3/1`},
        { title: 'Contact US',link:`/${lang}/company/1/3`},
      ]
    };
    
  }else
  {
    navi_tit = 
    [
      'ODM생산 공급',
      '기술용역(보유기술)',
      '문의/게시판'
    ]
     
    navi_contents = 
    {   
      odm: [
        { title:'ODM 레퍼런트 설계/플랫폼', link:`/${lang}/odm/2/1`, level:1},
        { title:'ODM 절차',  link:`/${lang}/odm/3/1` ,level:1},
        { title:'ODM 제품 사례보기', link:`/${lang}/odm/1/1`,level:1},
        { title:'ODM 동영상 클립', link:`/${lang}/odm/2/1`}, //임시로 사례보기에 연결 
        { title:'ODM 자주 묻는 질문(FAQ)', link:'#'},
      ],
      service:[
        { title:'산업용 안드로이드/ AOSP안드로이드 제품',link:`/${lang}/product/1/1`,level:1},
        { title:'차량용 데이터 터미널(MDT)설계/공급 ',link:`/${lang}/service/1/1`},
        { title:'산업용 HMI 설계/공급 ',link:`/${lang}/product/1/1`,level:1},
        { title:'각종 임베디드 제품 설계/공급 ',link:`/${lang}/product/1/1`,level:1},
        { title:'임베디드 모듈 설계/공급 ',link:`/${lang}/product/2/1`},
        { title:'리눅스 커널 보드포팅',link:`/${lang}/service/1/1`,level:1},
        { title:'안드로이드 프레임워크 보드포팅',link:`/${lang}/service/2/1`},
       // { title:'기타: 우분투/ Win10 IOT 포팅',link:'#'},
        { title:'임베디드 소프트웨어 - QT/QML',link:'#',level:1},
        { title:'임베디드 소프트웨어 - Android / C#',link:`/${lang}/service/2/1`,level:1},
        { title:'마이컴/펌웨어(아두이노 호환 포함)',link:`/${lang}/product/1/4`},
        { title:'농기계자율주행', link:`/${lang}/product/1/1`,level:1},
        { title:'IOT Edge 디바이스',link:`/${lang}/product/1/4`},
        { title:'IOT 서비스 페이지',link:'#'},
        { title:'스마트그리드 디바이스',link:`/${lang}/service/2/1`},
        { title:'스마트그리드 모드버스 계측기',link:'#'},
        { title:'용역개발 사례',link:`/${lang}/service/1/1`,level:1},,
        { title:'용역개발 동영상클립',link:`/${lang}/service/2/1`},
      ],
      inquiry:[
        { title:'구매문의',link:`/${lang}/support/1/1`},
        { title:'개발의뢰',link:`/${lang}/support/1/1`,level:1},
        { title:'문의답변(기술문의)',link:`/${lang}/support/2/1`,level:1},
        { title:'자료실',link:`/${lang}/support/3/1`},
        { title:'찾아오시는 길',link:`/${lang}/company/3/1`},
      ]
    };
  };

  const oemList     = navi_contents.odm.map((item) => {
    return item.level?  <li key={shortid.generate()} style={{ fontWeight: 'bold' }}  ><Link to={item.link}  >{item.title}</Link></li> :
                        <li key={shortid.generate()} style={{ fontWeight: 'normal' }}  ><Link to={item.link}  >{item.title}</Link></li>
    });

    const serviceList     = navi_contents.service.map((item) => {
      return item.level?  <li key={shortid.generate()} style={{ fontWeight: 'bold' }}  ><Link to={item.link}  >{item.title}</Link></li> :
                          <li key={shortid.generate()} style={{ fontWeight: 'normal' }}  ><Link to={item.link}  >{item.title}</Link></li>
      });
  
      const inquiryList     = navi_contents.inquiry.map((item) => {
        return item.level?  <li key={shortid.generate()} style={{ fontWeight: 'bold' }}  ><Link to={item.link}  >{item.title}</Link></li> :
                            <li key={shortid.generate()} style={{ fontWeight: 'normal' }}  ><Link to={item.link}  >{item.title}</Link></li>
        });
    

   //debugger;

  return (    
  //  베너에 대한 디스크립션
    <div className="nav"> 
      <div>
        <h3>{navi_tit[0]}</h3>
        <ol>
          {oemList}
        </ol>
      </div> 
      <div> 
        <h3>{navi_tit[1]}</h3>
        <ol>
          {serviceList}
        </ol>
      </div>  
      <div> 
        <h3>{navi_tit[2]}</h3>
        <ol>
          {inquiryList}
        </ol>
      </div>
    </div>
  );
}
export default HTMLCommonNaviContent;