import React, {useState }from 'react';
import Dialog from '@material-ui/core/Dialog';
//import { Grid, Row, Cell } from 'react-inline-grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import TextField from '@material-ui/core/TextField';
import DlgCommon from './DlgCommon';
import { RadioGroup,Radio,FormControl,Checkbox,FormLabel,FormControlLabel,FormHelperText,Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { post } from 'axios';

const DlgLogin = ({lang,open,caption,desc, isValid, isDeleting, writer, passwd, cbHandleSubmit, cbHandleOk,cbHandleCancel, cbHandleChange}) => {

    var dlgOk = (lang=='ko') ? "확인" : "Yes";
    var dlgCancel = (lang=='ko') ? "취소" : "Cancle";
    var captionWriter = (lang=='ko')? "작성자" :  "Writer" ;
    var captionPasswd =  (lang=='ko')? "비밀번호":  "Password";
  


    var dlgErrCaption = (lang=='ko') ? "입력 오류" : "ERROR ";
     var dlgDescInvalid =  (lang=='ko')? "입력정보룰 확인하세요!" :  "Ensure input fields";
                      

    const [user_id, setId] = useState('');
    const [user_pwd, setPasswd] = useState('');
    const [userErr, setUserError] = useState(0);


    React.useEffect(() => {
        if (userErr ) {
          console.log('errors on user input.');
        } else {
          console.log('No errors');
        }
      }, [userErr]);
      
      

    var checkIdPasswd = () => {
    
        if((user_id !='') && (user_pwd!=''))
        {
            return 1;
        }
        return 0;
    }
   

    const  handleIdChange = (e) =>{
         setId(e.target.value);
        // cbHandleChange(e);
    }

    const  handlePasswdChange = (e) =>{
        setPasswd(e.target.value);
        //cbHandleChange(e);
    }
            
    const  handleSubmit = (e) =>{
        var error = (user_id && user_pwd)? 0 : 1;
        //입력 정보에 에러가 있을때는 에러상태만 변경하고 리턴한다 : 나머지는 랜더링하면서 처리한다   
        if(error){
            setUserError( error);
            return;
        }
        //입력정보에 에러가 없고 정보가 올바를 때에는 DB를 갱신 또는 삭제한다. 
        if(checkIdPasswd())
        {
   
            ///서버응답 타임아웃에 대한 처리 필요 
            login()
                .then((response) => {
                console.log(response.data);
                //    this.props.stateRefresh();
                //    this.handleToList();
                //    this.setState({ form: true });
                })
            
            // this.setState({ form: true });


            return;
        }
        //입력정보가 올바르지 않을때
         setUserError(2); // 0 : 에러없슴, 1: 입력오류, 2 : 입력정보오류 
     }
   
     const  handleOk= (e) =>{
        setUserError( 0);   //에러창 닫음 
    }
   
    const  handleCancel= (e) =>{
        
    }
    

     const   login = () =>{
        var url = `/api/auth/login/${user_id}/${user_pwd}`;
        const formData = new FormData();
        formData.append('writer', user_id);
        formData.append('passwd', user_pwd);
     
        const config = {
          headers: { 'content-type': 'multipart/form-data' }
        }
        return post(url, formData, config)
      }


      
     

    var isCertified = isDeleting ? checkIdPasswd() : isValid;
    const [checked, setChecked] = React.useState(false);

    const handleCheck = (event) => {
      setChecked(event.target.checked);
    };

    var captionNotRobot  = (lang=='ko')?"로봇이 아닙니다" : "I'm not robot";

    // const useStyles = makeStyles((theme) => ({
    //     button: {
    //       margin: theme.spacing(1),
    //     },
    //   }));

    // const classes = useStyles();
    const style = {
        buttonStyle: {
         minWidth: 400,
         margin:"10px 0 0 0"
        }  
    }
    
    return (
    <Dialog onClose={handleOk} open={open}>
        <DialogTitle onClose={handleOk}>
            {caption  }
        </DialogTitle>

                    
        {isValid?
            // 정상일때
            <DialogContent>
                <Typography gutterBottom>
                {desc  }
                </Typography>
                {/* 등록시 사용자/비밀번호를 점검한다 */}
                 <TextField label={captionWriter} type="text" name="user_writer"   onChange={(e)=>handleIdChange(e)} /><br/>
                {/* 상위로 값전달 */}
                 <TextField label={captionPasswd} type="password" name="user_passwd"  onChange={(e)=>handlePasswdChange(e)}  /><br/>
                <br/>
                <FormControlLabel
                    control={<Checkbox checked={checked} onChange={handleCheck} name="checked" inputProps={{ 'aria-label': 'secondary checkbox' }} />}
                    label={captionNotRobot}
                />
               <br/>
                <div >
                    <Button
                            variant="outlined"
                            color="primary"
                        style={style.buttonStyle}
                        // className={classes.button}
                            // startIcon={<DeleteIcon />}
                        >
                        Facebook
                    </Button>
                    <br/>
                    <Button disabled
                            variant="outlined"
                            color="secondary"
                            style={style.buttonStyle}
                            // className={classes.button}
                            // startIcon={<DeleteIcon />}
                        >
                        Gmail
                    </Button>
                </div>
            </DialogContent>
            :
            // 오류메시지
            <DialogContent>
                <Typography gutterBottom>
                {desc}
                </Typography>
            </DialogContent>
            }

            <DialogActions>
                <div>
                    {/* 확인 */}
                    <Button variant="contained" color="primary" onClick={(e)=>{handleSubmit(e); }} >{dlgOk}</Button>  
                    {/* 취소 */}
                    <Button variant="outlined" color="primary"  onClick={cbHandleCancel}>{dlgCancel}</Button>
                </div>

                <>
                        {userErr?
                    
                        <DlgCommon lang={lang} open={1} caption={dlgErrCaption} desc={dlgDescInvalid} 
                        handleOk={()=>{handleOk()}  } handleCancel={null  }    /> 
                        :
                        null
                        } 
                    
                </>



            </DialogActions>
    </Dialog>

    );
};

export default DlgLogin;


