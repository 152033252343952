import React from 'react';

function HTMLFooter({lang}) 
{
  var ft_info=[];
  var ft_nav =[];
  var ft_address=[];

  if(lang==='en') {
    ft_info =['Phone call:','+82-2057-2422','8:00 AM ~ 6:00 PM','E-mail: support@nexfield.net'];
    ft_nav =[
      {title:'Quotation | Inquiry', link:`/${lang}/support/4`},
      {title:'Board(Q&A)', link:`/${lang}/support/1`},
      {title:'Refernce Room', link:`/${lang}/support/2`},
      {title:'Customer support', link:`/${lang}/support/4`},
      {title:'Privacy statements', link:'#' },
      {title:'Location', link:`/${lang}/company/3`},
    ];
    ft_address=['Nexfield Solution Co./Ltd.','Address : (Zip) 07003,  4FL. 220-55, SADANG-DONG, DONGJAK-GU, SEOUL, KOREA','TEL. : 02-2057-2422. FAX. : 02-2057-2423','Mail : support@nexfield.net'];
  }else{
    ft_info =['문의전화:','(02) 2057-2422','평일: 8:00 AM ~ 6:00 PM','E-문의메일: support@nexfield.net'];
    ft_nav =[
      {title: '견적 요청', link:`/${lang}/support/4`},
      {title: '게시판(Q&A)', link:`/${lang}/support/1`},
      {title: '자료실', link:`/${lang}/support/2`},
      {title: '고객센터', link:`/${lang}/support/4`},
      {title: '개인정보취급방침', link:'#' },
      {title: '찾아오시는 길', link:`/${lang}/company/3`},
    ];
    ft_address=['도로명 주소 : (우) 07003  서울시 동작구 사당로 189-1 4 층 (주)넥스필드솔루션','사업자등록번호 : 214-87-88697 | 업종: 제조/도소매/서비스 | 업태: 산업용컴퓨터 및 주변기기/소프트웨어','문의전화. : 02-2057-2422. 팩스. : 02-2057-2423','문의메일 : support@nexfield.net'];
   }
 //푸터
  return (  
    <div id="footer" role="footer">
      <div className="_container">
        <h2 className="ir_su">footer</h2>
        <div className="footer">
          <div className="footer_info">
            <span>{ft_info[0]}</span>
            <strong>{ft_info[1]}</strong>
            <em>{ft_info[2]}</em>
            <a href="mailto:support@nexfield.net">{ft_info[3]}</a>
          </div>

          <div className="footer_nav">
            <ul>
              <li><a href={ft_nav[0].link}><strong>{ft_nav[0].title}</strong></a></li>
              <li><a href={ft_nav[1].link}>{ft_nav[1].title}</a></li>
              <li><a href={ft_nav[2].link}>{ft_nav[2].title}</a></li>
              <li><a href={ft_nav[3].link}>{ft_nav[3].title}</a></li>
              <li><a href={ft_nav[4].link}><strong>{ft_nav[4].title}</strong></a></li>
              <li><a href={ft_nav[5].link}><strong>{ft_nav[5].title}</strong></a></li>
            </ul>
          </div>
          <div className="footer_address" style={{float:"left", width:"70%"}}>   
            <address>
              <em>{ft_address[0]}</em><br/>
              <em>{ft_address[1]}</em><br/><br/>
              <em>{ft_address[2]}</em><br/>
              <i><a href="mailto:support@nexfield.net">{ft_address[3]}</a>&nbsp;&nbsp;&nbsp;</i><br/><br/>
            </address>
          </div>
          {/* <div className="w3c">
            <a href="http://jigsaw.w3.org/css-validator/check/referer">
                <img style="border:0;width:88px;height:31px" src="http://jigsaw.w3.org/css-validator/images/vcss-blue" alt="올바른 CSS입니다!" />
            </a>
            <a href="http://validator.w3.org/check?uri=referer">
                <img src="http://www.w3.org/Icons/valid-xhtml10" alt="Valid XHTML 1.0 Transitional" height="31" width="88" />
            </a>
          </div> */}
        
        </div>
        {/* .footer */}
      </div>      
    </div>      
  );

};

export default HTMLFooter;