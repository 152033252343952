import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import DlgCertify from '../common/DlgCertify';
import DlgCommon from '../common/DlgCommon';

class ABoardDelete extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            boardName:'',
            open: false,
            lang: '',
            index: '',
            num: '',
            writer: '',
            passwd: ''
           
        }

        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleOK = this.handleOK.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleToList = this.handleToList.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);

        this.state.boardName = props.boardName;
        this.state.lang = props.lang;
        this.state.index = props.index;
        this.state.num = props.num;
        //지울때 사용자 이름과 비번을 확인하기 위함 
        this.state.writer = props.writer;
        this.state.passwd = props.passwd;

        
    }

    componentDidMount()
    {
    
        this.setState({writer: this.props.writer });
        this.setState({passwd: this.props.passwd });

    }


    handleFileChange(e) {
        this.setState({
          file: e.target.files[0],
          upload_file: e.target.value
        });
      }

    handleValueChange(e) {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
    }
    
    handleClickOpen() {
        this.setState({
            open: true
        });
    }
        
    //글 등록에 대해 /성공 실패를 구분해야 한다 
    handleOK() {

        this.props.stateRefresh();  
        this.setState({form: false })
    }
    handleCancel() {

        this.setState({
        open: false
        });
        
    }
   
    //해당게시판의 목록으로 이동 
    handleToList() {
        var lang = this.state.lang;
        var index = this.state.index; //게시판 종류 
        var subIndex = 1; //게시판 종류 
        // history.push 를 사용하기 위해 편법적으로 상위 컴포넌트의 props를 전달 받는다.  
        this.props.history.push(`/${lang}/${this.state.boardName}/${index}/${subIndex}`);
   
    }
    
    handleDelete()
    {
        this.deleteABoard(this.state.index,this.state.num )
       
    }
    //글 삭제시에는 이름과 비번만 확인한다 
    isValidContent(){
     // var valid = (this.state.writer && this.state.email && this.state.subject &&  this.state.content )? true : false;
      var valid = (this.state.writer && this.state.passwd )? true : false;
      return valid;
    }
    
    deleteABoard(index, id){

        const url = `/api/${this.state.boardName}/${index}/${id}`;
        fetch(url, {
           method: 'DELETE' 
        });
        this.props.stateRefresh();
        this.handleToList();
    }

    render() {
        var buttonCaption = (this.state.lang=='ko') ? "삭제하기" : "Delete";
        var dlgCaption = (this.state.lang=='ko') ? "삭제 경고" : "Delete ";
        var dlgDesc= (this.state.lang=='ko') ? "선택한 글이 삭제됩니다." : "Current article is being deleted! ";
        var dlgErrCaption = (this.state.lang=='ko') ? "입력 오류" : "ERROR ";
        var dlgErrDesc= (this.state.lang=='ko') ? "이름/ 이메일/ 내용을 입력해주세요!" : "Fill Name/ Email/ Content ";
        var captionPasswd =  (this.state.lang=='ko')? "비밀번호":  "Password";
        var dlgDescPasswdInvalid =  (this.state.lang=='ko')? "비밀번호가 일치하지 않습니다" :  "Incorrect password";
                      
        return (
            <div>
                <Button variant="contained" color="secondary" onClick={this.handleClickOpen}>
                    {buttonCaption}
                </Button>
                {/* <Dialog onClose={this.handleCancle} open={this.state.open}>
                    <DialogTitle onClose={this.handleCancle}>
                        {dlgCaption}
                    </DialogTitle>
                    <DialogContent>
                        <Typography gutterBottom>
                         {dlgDesc}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={(e) => {this.deleteABoard(this.state.index,this.state.num)}}>삭제</Button>
                        <Button variant="outlined" color="primary" onClick={this.handleCancle}>닫기</Button>
                    </DialogActions>
                </Dialog> */}
                 {(this.state.open )  ?
                    
                    < DlgCertify  lang={this.state.lang} open={this.state.open} 
                        caption={dlgCaption } 
                        desc={dlgDesc } 
                        isDeleting={1}
                        isValid={this.isValidContent() } writer={this.state.writer} passwd={this.state.passwd}  
                        handleSubmit={this.handleDelete} handleOk={this.handleOK} handleCancel={this.handleCancel} handleChange={this.handleValueChange} 
                    />
                 :
                    // 비번 틀림  : 입력은 취소시키고... 이 다이얼로그(오류표시 다이얼로그)는 닫는다
                    //              여기서 this.state.result 플래그는 다이얼로그 표시 플래그로 사용했다..
                    <div>
                    { (this.state.result==0) ?
                      <DlgCommon lang={this.state.lang} open={!this.state.result} caption={dlgErrCaption} desc={dlgDescPasswdInvalid} 
                      handleOk={()=>{this.handleCancel(); this.setState({result: true})}  } handleCancel={null}   
                      />
                      :
                      null
                    }
                    </div>
                }
            </div>
        )
    }
}

export default ABoardDelete;