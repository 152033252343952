import React, { Component } from 'react';
import { post } from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {withStyles } from '@material-ui/core/styles';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CustomUploadAdapterPlugin from '../board/CustomUploaderAdapterPlugin';
import CKCustomEditor from '../common/CKCustomEditor';
import DlgCertify from '../common/DlgCertify';
import DlgCommon from '../common/DlgCommon';
import '../common/board.css'

const styles = theme => ({
    hidden: {
      display: 'none'
    }
  });

class FormBoardContent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        lang: '',
        index: '',
        num: '',
        ref_num: '',
        modify: '',    
       
        boardName: '',  //게시판명 
        file: null,  //파일
        writer: '',
        email: '',
        subject: '',
        passwd: '',
        reg_date: '',
        content: '',
        content_type: '',      
        upload_file: '', //파일명 
        form: 0,
        open:false,
        
        result:1 , //submit 결과 
        user_writer:'',
        user_passwd:'',
        reply: 0,
        // re_step:0,
        // re_level:0
       }

      this.state.boardName = props.boardName;

      this.state.lang = props.lang;
      this.state.index = props.index;
      this.state.num = props.num;
      this.state.ref_num = props.ref_num;
      this.state.modify = props.modify;
      this.state.form = props.form ? 1 : 0; //글쓰기 폼을 보임 
      this.state.reply = props.reply ? 1 : 0; //글쓰기 폼을 보임 
   
      //원본 글을 표시 해준다 
      if(props.contents){
        this.state.file=props.contents.file;
        this.state.writer=props.contents.writer;
        this.state.email=props.contents.email;
        this.state.subject=props.contents.subject;
        this.state.passwd=props.contents.passwd;
        this.state.reg_date=props.contents.reg_date;
        this.state.content=props.contents.content;
        this.state.content_type=props.contents.content_type;
        this.state.upload_file=props.contents.upload_file;
        this.state.file=props.contents.image;
      }

      this.state.user_writer = '';
      this.state.user_passwd = '';
      
      this.handleFormSubmit = this.handleFormSubmit.bind(this)
      this.handleSubmit = this.handleSubmit.bind(this)
      this.handleFileChange = this.handleFileChange.bind(this)
      this.handleValueChange = this.handleValueChange.bind(this)
      this.addABoard = this.addABoard.bind(this)
      this.handleClickOpen= this.handleClickOpen.bind(this)
      this.handleOK = this.handleOK.bind(this);
      this.handleCancel = this.handleCancel.bind(this);
      this.handleToList = this.handleToList.bind(this);
      
      this.onEditorChange = this.onEditorChange.bind( this );
    }

    componentDidMount()
    {
      var show = this.props.form ?1 :0;
      if(show){
    
        this.setState({form: 1 });
      }
    }

    handleSubmit()
    {
      var  modify = this.state.modify;

      if(modify){
        this.modifyABoard(this.state.index,this.state.num )
        .then((response) => {
          console.log(response.data);
          this.props.stateRefresh();

          this.handleToList();
          this.setState({ form: true });
        })
      }else { this.addABoard(this.state.index )
        .then((response) => {
          console.log(response.data);
          this.props.stateRefresh();
          this.handleToList();
          this.setState({ form: true });
        })
      }
      // this.setState({ form: true });

    }

    isValidContent(){
     // var valid = (this.state.writer && this.state.email && this.state.subject &&  this.state.content )? true : false;
      // var valid = (this.state.writer && this.state.email &&  this.state.content )? true : false;
      var valid = true;
      return valid;
    }
    //입력모드에서 유효한 입력값인지를 체크 
    isValidUser(){
      //return this.state.passwd  ? true : false;
      return (this.state.writer && this.state.user_passwd)  ? true : false;
    }
   //수정모드에서 일치하는 비번값인지를체크
    isSameUser(){
      return ((this.state.writer==this.state.user_writer)&&(this.state.passwd==this.state.user_passwd));
    }
   
    //핸들러에서는 상태변화를 담보할수 없다..
    handleFormSubmit(e) {
      var valid = true;
      e.preventDefault()

      var  modify = this.state.modify;

      if(modify)
        valid = this.isValidContent() &&  this.isSameUser(); //비밀번호도 체크 
      else
        valid = this.isValidContent() &&  this.isValidUser(); //비밀번호도 체크 

      this.setState({ result: valid},
        ()=>{          
          if(valid==true){
              //제목 입력을 안했을때 
              if(!this.state.subject){
                var subj = (this.state.lang=='ko')? "제목없슴":"Unknown" 
                this.setState({ subject: subj},
                  ()=>{
                    this.setState({ passwd: this.state.user_passwd},
                      ()=>{this.handleSubmit();}
                    )
                })
              }else{
                this.setState({ passwd: this.state.user_passwd},
                    ()=>{this.handleSubmit();
                })
              }
          }else{
           // this.handleOK();
            this.handleCancel();
          }
      })
    }
   //해당게시판의 목록으로 이동 
   handleToList() {
    var lang = this.state.lang;
    var index = this.state.index; //게시판 종류 
    var subIndex = 1;

    var pathList = (this.state.boardName=='admin') ? `/${lang}/${this.state.boardName}/${index}` : `/${lang}/${this.state.boardName}/${index}/${subIndex}` ;

    if(this.props.history)
      this.props.history.push(pathList);
    else{
      //댓글의 경우 history가 없다 
      // 화면전체를 지운다... history props를 활용하는게 좋다...
      window.location.href = pathList;  
    }
  }
   
  handleClickOpen() {
      this.setState({ open: true });
  }

  //글 등록에 대해 /성공 실패를 구분해야 한다 
    handleOK() {
      this.props.stateRefresh();  
      this.setState({form: false })
    }
    handleCancel() {
      this.setState({open: false });
    }

    handleFileChange(e) {
      this.setState({
        file: e.target.files[0],
        upload_file: e.target.value
      });
    }

    handleValueChange(e) {
      let nextState = {};
       nextState[e.target.name] = e.target.value;
      this.setState(nextState);
 
      }

    addABoard(index){
      var subIndex=1;
      var url = `/api/${this.state.boardName}/${index}/${subIndex}`;
      const formData = new FormData();
      formData.append('writer', this.state.writer);
      formData.append('email', this.state.email);
      formData.append('subject', this.state.subject);
      formData.append('passwd', this.state.passwd);
      formData.append('reg_date', this.state.reg_date);
      formData.append('content', this.state.content);
      formData.append('content_type', this.state.content_type);
      formData.append('upload_file', this.state.upload_file);
      formData.append('image', this.state.file);
      formData.append('reply', this.state.reply); //댓글인지/ 새글인지 
      //댓글의 경우애는 ref가 원글의 num이다..
      //formData.append('ref', this.state.num); // num은 원 게시글의 번호(자동할당)이므로, post는 의미상 ref (정렬번호) 값이다..
      formData.append('ref', this.state.ref_num); // num은 원 게시글의 번호(자동할당)이므로, post는 의미상 ref (정렬번호) 값이다..
      formData.append('num', this.state.num); // 원글 번호 
   
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      return post(url, formData, config)
    }

  
  
    modifyABoard(index,num){
      var subIndex=1;
      var url = `/api/${this.state.boardName}/${index}/${subIndex}/${num}`;
      
      const formData = new FormData();
      formData.append('writer', this.state.writer);
      formData.append('email', this.state.email);
      formData.append('subject', this.state.subject);
      formData.append('passwd', this.state.passwd);
      formData.append('reg_date', this.state.reg_date);
      formData.append('content', this.state.content);
      formData.append('content_type', this.state.content_type);
      formData.append('upload_file', this.state.upload_file);
      formData.append('image', this.state.file);
      formData.append('reply', this.state.reply); //원게시글 수정인지/ 댓글 수정수정인지 
      formData.append('ref', this.state.num); // num은 원 게시글의 번호(자동할당)이므로, post는 의미상 ref (정렬번호) 값이다..
 
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      return post(url, formData, config)
    }
     onEditorChange( evt ) {
        this.setState( {
          content: evt.editor.getData()
        } );
    }

    render() {
        const { classes } = this.props;

        const port = process.env.REACT_APP_BACKEND_HOST_PORT;


        var addCaition = (this.state.lang=='ko')? "추가": "Register";
        // addCaition = (this.state.reply)?   "추가": "Register";

        var attachLabel = (this.state.lang=='ko')? "첨부 파일": "Attach File";
        var attachCaption = (this.state.lang=='ko')? "첨부 파일 선택": "Attach File";
        var listCaition = (this.state.lang=='ko')? "리스트로 돌아가기": "Show List";
        var dlgOk = (this.state.lang=='ko') ? "확인" : "Yes";
        var dlgCancel = (this.state.lang=='ko') ? "취소" : "Cancel";
        
        var dlgCaption = (this.state.lang=='ko') ? "새 글 등록" : "INFO ";
        var dlgDesc= (this.state.lang=='ko') ? "비밀번호를 입력해주세요" : "Confirm to register ";
        var dlgErrCaption = (this.state.lang=='ko') ? "입력 오류" : "ERROR ";
        var dlgErrDesc= (this.state.lang=='ko') ? "이름/ 이메일/ 내용을 입력해주세요!" : "Fill Name/ Email/ Content ";
                      
        const tstyle = {
          color: "#888",
          fontWeight : "700",
          fontSize : 16,
          marginRight : 50,
          marginBottom : 5
         }
  
         const bstyle = {
            paddingTop:5,  
         }
         const cellStyle = {
          border: "none",
          boxShadow: "none"
        };

       var labels = (this.state.lang=='ko')? ["작성자", "이메일" , "글 제목" , "분류" ] :  ["Writer", "Email" , "Subject" , "Description" ] ;
       var captionPasswd =  (this.state.lang=='ko')? "비밀번호":  "Password";
       var dlgDescPasswdInvalid =  (this.state.lang=='ko')? "사용자명 또는 비밀번호를 확인해주세요" :  "Incorrect user or password";

       labels[2] += this.state.reply ? ( (this.state.lang=='ko')? " [답글]":" [Re]") :"";

       var content = '';
       //content =  this.state.file ?     `<img src=${this.state.file } alt="profile"/>` :'';
       content +=  this.state.content;  
       var valid = (this.state.writer && this.state.email &&  this.state.content )? true : false;

      // var kindOf= this.state.reply ? ( (this.state.lang=='ko')? "[답글] ":"[Re] ") :"";

       return (
        <div> 
          {/* //편집모드  */}
          <div style={{textAlign: 'left', marginLeft: '50px', marginRight: '50px'}} >
              <br/>
              <Table   >
                <TableBody >
                  <TableRow >
                    <TableCell style={cellStyle}><TextField style={{ height: 30}} label={labels[0]} type="text" name="writer"  variant="standard" value={this.state.writer} onChange={this.handleValueChange} /></TableCell>
                    <TableCell style={cellStyle}><TextField style={{ height: 30}} label={labels[1]} type="text" name="email"  variant="standard" value={this.state.email} onChange={this.handleValueChange} /></TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell style={cellStyle}><TextField style={{ height: 30,width:"80%"}}  label={labels[2]}  type="text" name="subject"  variant="standard" value={this.state.subject} onChange={this.handleValueChange} /></TableCell>
                    <TableCell style={cellStyle}><TextField style={{ height: 30}} label={labels[3]} type="text" name="content_type"  variant="standard" value={this.state.content_type} onChange={this.handleValueChange} /></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <br/>

              {/* 이부분이 문제다 : 수정모드에서는 내용물을 보여주지 못한다...  */}
              
              <div className="ck-content-wrap"> 

              {/* <CKCustomEditor content={content}/> */}
               <CKEditor  
                    editor={ClassicEditor}
                    data={content} 
                
                    onChange={ ( event, editor ) => {
                        var data = editor.getData();
                        var temp = data ;
   
                        // temp = temp.replace(/<figure(.*?)>/gi,'').replace( /<img(.*?)>/gi,'').replace(/<\/figure>/gi,''); //파일명은 따로 분리해야한다 
                       this.setState({content:temp});  //텍스트만 분리 한다
                        
                       //console.log( { event, editor, data } );
                    } }
                    
                    onInit={editor => {
                      editor.editing.view.change(writer => {
                          writer.setStyle(
                            "height",
                            "600px",
                            editor.editing.view.document.getRoot()
                          );
                        });
                    }}
                    config={
                          {language:'ko'},
                             {toolbar: {
                            },
                          image: {
                               styles: [
                              'alignLeft', 'alignCenter', 'alignRight'
                            ],                            
                            toolbar: [
                              'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
                              '|',
                              'resizeImage',
                              '|',
                              'imageTextAlternative'
                          ]                            
                          },
                          heading: {
                              options: [
                                  { model: 'heading1', view: 'h1', title: '헤더1', class: 'ck-heading_heading1' },
                                  { model: 'heading2', view: 'h2', title: '헤더2', class: 'ck-heading_heading2' },
                                  { model: 'heading3', view: 'h3', title: '헤더3', class: 'ck-heading_heading3' },
                                  { model: 'paragraph', title: '본문', class: 'ck-heading_paragraph' },
                              ]
                          },
                          ckfinder: {
                              uploadUrl: `/api/uploadFile`
                          },
                         }
                      }
            
                /> 
               
              </div>

              <br></br>
                  
              <div>
                {/*추가버튼 | 리스트로 돌아가기 버튼*/}
                <label htmlFor="raised-button-file"> 
                  <Button variant="contained" color="primary" onClick={()=>{this.handleClickOpen()}}>{addCaition}</Button> 
                  &nbsp;&nbsp;&nbsp;
                  <Button variant="outlined" color="primary" onClick={this.handleOK}>{listCaition}</Button>
                </label>                  

                    {this.state.open ?
                  // 다이얼로그가 항상 초기화 된다 : "추가" 버튼을 눌렀을때만 뜨면 되는데... => open을 체크해서 띄운다
                  // 입력모드에서는 비번이 유효하기만 하면되지만 수정모드에서는 비번이 일치해야한다 
                  < DlgCertify  lang={this.state.lang} open={this.state.open} 
                    caption={(this.isValidContent()==false) ?dlgErrCaption : dlgCaption } 
                    desc={(this.isValidContent()==false) ?dlgErrDesc : dlgDesc } 
                    isValid={this.isValidContent()} writer={this.state.user_writer} passwd={this.state.user_passwd}  
                     handleSubmit={this.handleFormSubmit} 
                    handleOk={this.handleOK} handleCancel={this.handleCancel} handleChange={this.handleValueChange} 
                    />
                :
                  // 비번 틀림  : 입력은 취소시키고... 이 다이얼로그(오류표시 다이얼로그)는 닫는다
                  //              여기서 this.state.result 플래그는 다이얼로그 표시 플래그로 사용했다..
                  <div>
                  { (this.state.result==0) ?
                    <DlgCommon lang={this.state.lang} open={!this.state.result} caption={dlgErrCaption} desc={dlgDescPasswdInvalid} 
                    handleOk={()=>{this.handleCancel(); this.setState({result: true})}  } handleCancel={null}   
                    />
                    :
                    null
                  }
                </div>
                }
                </div>
              </div>        
            </div> 
      )
    }
}


export default withStyles(styles)(FormBoardContent)
