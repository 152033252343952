import React, {useState }from 'react';
import Dialog from '@material-ui/core/Dialog';
//import { Grid, Row, Cell } from 'react-inline-grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DlgCommon from './DlgCommon';

const DlgCertify = ({lang,open,caption,desc, isValid, isDeleting, writer, passwd, handleSubmit, handleOk,handleCancel, handleChange}) => {

    var dlgOk = (lang=='ko') ? "확인" : "Yes";
    var dlgCancel = (lang=='ko') ? "취소" : "Cancle";
    var captionWriter = (lang=='ko')? "작성자" :  "Writer" ;
    var captionPasswd =  (lang=='ko')? "비밀번호":  "Password";
  


    var dlgErrCaption = (lang=='ko') ? "입력 오류" : "ERROR ";
     var dlgDescInvalid =  (lang=='ko')? "입력정보룰 확인하세요!" :  "Ensure input fields";
                      

    const [user_id, setId] = useState('');
    const [user_pwd, setPasswd] = useState('');
    const [userErr, setUserError] = useState(0);


    React.useEffect(() => {
        if (userErr ) {
          console.log('errors on user input.');
        } else {
          console.log('No errors');
        }
      }, [userErr]);
      
      

    var checkIdPasswd = () => {
    
        if((user_id == writer) && (user_pwd==passwd))
        {
            return 1;
        }
        return 0;
    }
   

    const  handleIdChange = (e) =>{
         setId(e.target.value);
        handleChange(e);
    }

    const  handlePasswdChange = (e) =>{
        setPasswd(e.target.value);
        handleChange(e);
    }
            
    const  handleError = (e) =>{
        var error = (user_id && user_pwd)? 0 : 1;
        //입력 정보에 에러가 있을때는 에러상태만 변경하고 리턴한다 : 나머지는 랜더링하면서 처리한다   
        if(error){
            setUserError( error);
            return;
        }
        //입력정보에 에러가 없고 정보가 올바를 때에는 DB를 갱신 또는 삭제한다. 
        if(checkIdPasswd())
        {
            handleSubmit(e);
            return;
        }
        //입력정보가 올바르지 않을때
         setUserError(2); // 0 : 에러없슴, 1: 입력오류, 2 : 입력정보오류 
     }
   

    var isCertified = isDeleting ? checkIdPasswd() : isValid;
  
   // userPasswd:'' //사용자 입력 비번 
 
    return (
    <Dialog onClose={handleOk} open={open}>
        <DialogTitle onClose={handleOk}>
            {caption  }
        </DialogTitle>

                    
        {isValid?
            // 정상일때
            <DialogContent>
                <Typography gutterBottom>
                {desc  }
                </Typography>
                {/* 등록시 사용자/비밀번호를 점검한다 */}
                 <TextField label={captionWriter} type="text" name="user_writer"   onChange={(e)=>handleIdChange(e)} /><br/>
                {/* 상위로 값전달 */}
                 <TextField label={captionPasswd} type="password" name="user_passwd"  onChange={(e)=>handlePasswdChange(e)}  /><br/>
            </DialogContent>
            :
            // 오류메시지
            <DialogContent>
                <Typography gutterBottom>
                {desc}
                </Typography>
            </DialogContent>
            }

            <DialogActions>
                <div>
                    {/* 확인 */}
                    <Button variant="contained" color="primary" onClick={(e)=>{handleError(e)}} >{dlgOk}</Button>  
                    {/* 취소 */}
                    <Button variant="outlined" color="primary"  onClick={handleCancel}>{dlgCancel}</Button>
                </div>

                <>
                        {userErr?
                    
                        <DlgCommon lang={lang} open={!isCertified} caption={dlgErrCaption} desc={dlgDescInvalid} 
                        handleOk={()=>{handleCancel(1);}  } handleCancel={null}    /> 
                        :
                        null
                        } 
                    
                </>



            </DialogActions>
    </Dialog>

    );
};

export default DlgCertify;


