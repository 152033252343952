import React, { Component } from 'react';

import FormServiceBrief from '../common/FormServiceBrief';


class ServiceContentHistory extends Component {
    constructor(props) {
        super(props);
      }

    render() {
     var lang = this.props.location.pathname.split('/')[1];
   
        var formContents = 
        [
    // 2024: ANdroid AI  
    {
        ko: {
            bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
            year : "2023",   
            image : "/img/service/nxd3588_android_ai_adv.png",   
            iWidth: "160px", iHeight:"140px",
            prod: ["차량용(자율주행용)/인공지능 대형(28') 패널",""],
                spec:[
                    ["Core : ", "RK3588 2GHz 옥타코어"],
                    ["Screen : ", "와이드 고해상도 디스플레이 (2890x320) "],
                    ["I/O : ", "1 CANFD, 2.5Gbit 차량용이더넷(AEC-Q100호환), 1 이더넷, 1 USB host"],
                    ["Features : 인공지능 / 안드로이이드 13.0"],
                    ["Desc :  안드로이이드 13.0 인공지능 고사양 패널 ", ""],
                    ["Etc : ", ""],
                ]     
            ,
            title :   ["적용분야"],
            apply :   ["Gateway 보드", "데이터 수집장치",""]

        },

    en: {
            bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
            year : "2024",   
            image : "/img/service/nxd3588_android_ai_adv.png",   
            iWidth: "160px", iHeight:"140px",
            prod: ["AI/ Vehicle Large sceen panel", "(Model: NXDIMX6S)"],
            spec:[
                ["Core : ", "STM32MPU153 800MHz Hz dual core"],
                ["Screen : ", "Linux 2K(upto 8K) High resolutiuon LVDS panel 2890x320"],
                ["I/O : ", "1 CANFD, Gbit automotive Ethernet`, 1 ethernet, 1 USB host"],
                ["Features : ", "Android 13.0"],
                ["Desc : Android 13.0 AI High resolution panel ", ""],
                ["Etc : ", ""],
            ]     
            ,
            title :   ["Application"],
            apply :   ["Gateway board","Data Gatherer"]
            
        }
    },            
      // 2023: Linux DAQ  
            {
            ko: {
                bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                year : "2022",   
                image : "/img/service/nxcstm32mp157_linux_gw.png",   
                iWidth: "160px", iHeight:"140px",
                prod: ["리눅스 게이트웨이 보드",""],
                    spec:[
                        ["Core : ", "STM32MPU153 800MHz Hz dual core"],
                        ["Screen : ", "없슴"],
                        ["I/O : ", "8 아날로그 입력, 8 DIO 입출력, 2 CANFD, Gbit 이더넷, 3 rs485, 1 USB host"],
                        ["Features : ", "저가형 리눅스 보드 / QT5.15.2"],
                        ["Desc : Linux QT/QML Gateway board ", ""],
                        ["Etc : ", ""],
                    ]     
                ,
                title :   ["적용분야"],
                apply :   ["Gateway 보드", "데이터 수집장치",""]
       
            },

           en: {
                bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                year : "2022",   
                image : "/img/service/nxcstm32mp157_linux_gw.png",   
                iWidth: "160px", iHeight:"140px",
                prod: ["Linux Gateway board", "(Model: NXDIMX6S)"],
                spec:[
                    ["Core : ", "STM32MPU153 800MHz Hz dual core"],
                    ["Screen : ", "N/A"],
                    ["I/O : ", "8 AIO, 8 DIO, 2 CANFD, Gbit 이더넷, 3 rs485, 1 USB host"],
                    ["Features : ", "Linux 6.10 / QT5.15.2(YOCTO mickledore/ Devian)"],
                    ["Desc : Linux QT/QML Gateway board ", ""],
                    ["Etc : ", ""],
                ]     
                ,
                title :   ["Application"],
                apply :   ["Gateway board","Data Gatherer"]
                
            }
        },
      // 2023: Linux DAQ  
    
           {
            ko: {
                bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                year : "2022",   
                image : "/img/service/nxdimx6_linuxdaq_yocto.jpg",   
                iWidth: "160px", iHeight:"140px",
                prod: ["리눅스 DAQ 보드",""],
                    spec:[
                        ["Core : ", "iMX6 1.0GHz Solo/DualLite"],
                        ["Screen : ", "800x480 Color TFT"],
                        ["I/O : ", "32 아날로그 입력, 32 DIO 입출력, 온도센서, 2 이더넷, 3 rs485, 2 USB host"],
                        ["Features : ", "Linux 4.9.88 / QT5.9.4(YOCTO rocko)"],
                        ["Desc : Linux QT/QML Terminal ", ""],
                        ["Etc : ", ""],
                    ]     
                ,
                title :   ["적용분야"],
                apply :   ["DAQ메인보드/계측기", "데이터 수집장치",""]
       
            },

           en: {
                bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                year : "2022",   
                image : "/img/service/nxdimx6_linuxdaq_yocto.jpg",   
                iWidth: "160px", iHeight:"140px",
                prod: ["Linux DAQ board", "(Model: NXDIMX6S)"],
                spec:[
                    ["Core : ", "iMX6 1.0GHz Solo/DualLite"],
                    ["Screen : ", "800x480 Color TFT"],
                    ["I/O : ", "32 AIO, 32 DIO, thermo meter, 2 Ethernet, 2 USB host"],
                    ["Features : ", "Linux 4.9.88 / QT5.9.4(YOCTO rocko)"],
                    ["Desc : Linux QT/QML Terminal ", ""],
                    ["Etc : ", ""],
                ]     
                ,
                title :   ["Application"],
                apply :   ["DAQ/ Measurement Equipment","Data Gatherer"]
                
            }
        },

          // 2021: 자율주행  
          {
            ko: {
                bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                year : "2021",   
                image : "/img/service/nxd3568_android_vehicle.png",   
                iWidth: "160px", iHeight:"140px",
                prod: ["안드로이드 농기계 자율주행 HMI"," (모델명: NXD3568A)"],
                    spec:[
                        ["Core : ", "RK3568 2.0GHz Cortex-A55 Quadcore"],
                        ["Screen : ", "1280x800 10인치 Color TFT"],
                        ["I/O : ", "CAN(GNSS), 1 DBG uart, SD, USB"],
                        ["Features : ", "Android 11.0 / 자율주행"],
                        ["Desc : Android Terminal ", ""],
                        ["Etc : ", ""],
                    ]     
                ,
                title :   ["적용분야"],
                apply :   ["차량용 AVN", "차량관제용 터미널",""]
       
            },

           en: {
                bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                year : "2021",   
                image : "/img/service/nxd3568_android_vehicle.png",   
                iWidth: "160px", iHeight:"140px",
                prod: ["Android Farming auto driving AVN", "(Model: NXD3568A)"],
                    spec:[
                        ["Core : ", "RK3568 2.0GHz Cortex-A55 Quadcore"],
                        ["Screen : ", "1280x800 10 inch Color TFT"],
                        ["I/O : ", "CAN(GNSS), 1 DBG uart, SD, USB"],
                        ["Features : : ", "Android 11.0 "],
                        ["Desc : Android Terminal ", ""],
                        ["Etc : ", ""],
                    ]     
                ,
                title :   ["Application"],
                apply :   ["Vecicle AVN","Vehicle Data Terminal"]
                
            }
        },

           // 2020: AVN 
           {
            ko: {
                bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                year : "2020",   
                image : "/img/service/nxd3568_android_odm.jpg",   
                iWidth: "160px", iHeight:"140px",
                prod: ["안드로이드 차량용 AVN"," (모델명: NXD3568)"],
                    spec:[
                        ["Core : ", "RK3568 2.0GHz Cortex-A55 Quadcore"],
                        ["Screen : ", "1280x800 12 inch Color TFT"],
                        ["I/O : ", "WiFi/BT5, RS-485, 1 DBG uart, Ethernet, SD, USB"],
                        ["Features : ", "Android 11.0 / 멀티미디어(유투브/라디오/미러링/테더링)"],
                        ["Desc : Android Terminal ", ""],
                        ["Etc : ", ""],
                    ]     
                ,
                title :   ["적용분야"],
                apply :   ["차량용 AVN", "차량관제용 터미널",""]
       
            },

           en: {
                bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                year : "2020",   
                image : "/img/service/nxd3568_android_odm.jpg",   
                iWidth: "160px", iHeight:"140px",
                prod: ["Android Vehicle AVN", "(Model: NXD3568)"],
                    spec:[
                        ["Core : ", "RK3568 2.0GHz Cortex-A55 Quadcore"],
                        ["Screen : ", "1280x800 Color TFT"],
                        ["I/O : ", "1 DBG uart, Ethernet, SD, USB"],
                        ["Features : : ", "Android 11.0 Multimedia(Youtube/Radio/Mirroring"],
                        ["Desc : Android Terminal ", ""],
                        ["Etc : ", ""],
                    ]     
                ,
                title :   ["Application"],
                apply :   ["Vecicle AVN","Vehicle Data Terminal"]
                
            }
        },
        // 2020: QT/QML 
         {
            ko: {
                bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                year : "2020",   
                image : "/img/service/nxdimx6_linux_yocto.jpg",   
                iWidth: "160px", iHeight:"140px",
                prod: ["리눅스QT/QML 공장용 터미널"," (모델명: NXDIMX6S)"],
                    spec:[
                        ["Core : ", "iMX6 1.0GHz Solo/DualLite"],
                        ["Screen : ", "1280x800 Color TFT"],
                        ["I/O : ", "1 DBG uart, Ethernet, SD, USB"],
                        ["Features : ", "Linux 4.9.88 / QT5.9.4(YOCTO rocko)"],
                        ["Desc : Linux QT/QML Terminal ", ""],
                        ["Etc : ", ""],
                    ]     
                ,
                title :   ["적용분야"],
                apply :   ["차량용 AVN", "차량관제용 터미널",""]
       
            },

           en: {
                bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                year : "2020",   
                image : "/img/service/nxdimx6_linux_yocto.jpg",   
                iWidth: "160px", iHeight:"140px",
                prod: ["Linux QT/QML Termial", "(Model: NXDIMX6S)"],
                spec:[
                    ["Core : ", "iMX6 1.0GHz Solo/DualLite"],
                    ["Screen : ", "1280x800 Color TFT"],
                    ["I/O : ", "1 DBG uart, Ethernet, SD, USB"],
                    ["Features : ", "Linux 4.9.88 / QT5.9.4(YOCTO rocko)"],
                    ["Desc : Linux QT/QML Terminal ", ""],
                    ["Etc : ", ""],
                ]     
                ,
                title :   ["Application"],
                apply :   ["Vecicle AVN","Vehicle Data Terminal"]
                
            }
        },

            // 2019: 레이턴트 
            {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2019",   
                    image : "/img/service/nx8925-oem.png",   
                    iWidth: "160px", iHeight:"120px",
                    prod: ["교통카드 개집표기"," (모델명: NX8925)"],
                        spec:[
                            ["Core : ", "TCC8925 816MHz"],
                            ["Screen : ", "480x272 Color TFT"],
                            ["I/O : ", "NFC controller, 2 RS-232, 1 DBG uart, Ethernet, SD, USB"],
                            ["Features : : ", "Linux QT 5.3.1 / 13.56MHz NFC"],
                            ["Desc : ", "KC인증 / RF인증"],
                            ["Etc : ", ""],
                        ]     
                    ,
                    title :   ["적용분야"],
                    apply :   ["교통카드 개집표기 / 지하철 게이트 ","NFC리더"]
           
                },

               en: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2019",   
                    image : "/img/service/nx8925-oem.png",   
                    iWidth: "160px", iHeight:"120px",
                    prod: ["Transportation card reader", "(Model: NX8925)"],
                        spec:[
                            ["Core : ", "TCC8925 816MHz"],
                            ["Screen : ", "480x272 Color TFT"],
                            ["I/O : ", "NFC controller, 2 RS-232, 1 DBG uart, Ethernet, SD, USB"],
                            ["Features : : ", "Linux QT 5.3.1 / 13.56MHz NFC"],
                            ["Desc : ", ""],
                            ["Etc : ", ""],
                        ]     
                    ,
                    title :   ["Application"],
                    apply :   ["Transportation card reader","NFC Card reader"]
                    
                }
            },

            
            // 2018: Linux HMI 
            {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2018",   
                    image : "/img/service/hmi6dl-yocto.png",   
                    iWidth: "160px", iHeight:"120px",
                    prod: ["산업용HMI"," (모델명: IMXP2)"],
                        spec:[
                            ["Core : ", "IMX6DL 1GHz DualLight"],
                            ["Screen : ", "1024x768 Color TFT"],
                            ["I/O : ", "RS-485/RS-422, RS-232, 1Gb Ethernet PHY, 100T Ethernet Phy, SD, USB"],
                            ["Features : : ", "Linux QT 5.6.1 / Linux YOCTO Krogoth"],
                            ["Desc : ", ""],
                            ["Etc : ", ""],
                        ]     
                    ,
                    title :   ["적용분야"],
                    apply :   ["산업용HMI ","작화기"]
        
                },

            en: {
                bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                year : "2018",   
                image : "/img/service/hmi6dl-yocto.png",   
                iWidth: "160px", iHeight:"120px",
                prod: ["Industrial HMI"," (Model: IMXP2)"],
                    spec:[
                        ["Core : ", "IMX6DL 1GHz DualLight"],
                        ["Screen : ", "1024x768 Color TFT"],
                        ["I/O : ", "RS-485/RS-422, RS-232, 1Gb Ethernet PHY, 100T Ethernet Phy, SD, USB"],
                        ["Features : : ", "Linux QT 5.6.1 / Linux YOCTO Krogoth"],
                        ["Desc : ", ""],
                        ["Etc : ", ""],
                    ]     
                ,
                title :   ["Application"],
                apply :   ["Industrial HMI ",""]
    
                    
                }
            },
        
         
            // 2017: Wince HMI 
            {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2017",   
                    image : "/img/service/hmi6dl-wce.png",   
                    iWidth: "160px", iHeight:"120px",
                    prod: ["산업용HMI"," (모델명: IMXP2)"],
                        spec:[
                            ["Core : ", "IMX6DL 1GHz DualLight"],
                            ["Screen : ", "1024x768 Color TFT"],
                            ["I/O : ", "RS-485/RS-422, RS-232, 1Gb Ethernet PHY, 100T Ethernet Phy, SD, USB"],
                            ["Features : : ", "Windows CE 7.0"],
                            ["Desc : ", ""],
                            ["Etc : ", ""],
                        ]     
                    ,
                    title :   ["적용분야"],
                    apply :   ["산업용HMI ","작화기"]
        
                },

            en: {
                bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                year : "2017",   
                image : "/img/service/hmi6dl-wce.png",   
                iWidth: "160px", iHeight:"120px",
                prod: ["Industrial HMI"," (Model: IMXP2)"],
                    spec:[
                        ["Core : ", "IMX6DL 1GHz DualLight"],
                        ["Screen : ", "1024x768 Color TFT"],
                        ["I/O : ", "RS-485/RS-422, RS-232, 1Gb Ethernet PHY, 100T Ethernet Phy, SD, USB"],
                        ["Features : : ", "Windows CE 7.0"],
                        ["Desc : ", ""],
                        ["Etc : ", ""],
                    ]     
                ,
                title :   ["Application"],
                apply :   ["Industrial HMI ",""]
    
                    
                }
            },
        
   
            // 2016: Wince 계측기  
            {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2016",   
                    image : "/img/service/nx9g45-ls.png",   
                    iWidth: "160px", iHeight:"120px",
                    prod: ["산업용HMI"," (모델명:  GIMAC-B)"],
                        spec:[
                            ["Core : ", "AT91SAM9G45 400MHz"],
                            ["Screen : ", "800x480 Color TFT"],
                            ["I/O : ", "RS-485/RS-422, RS-232, 100T 3-Port Ethernet Phy, SD, USB"],
                            ["Features : : ", "Windows CE 6.0"],
                            ["Desc : ", ""],
                            ["Etc : ", ""],
                        ]     
                    ,
                    title :   ["적용분야"],
                    apply :   ["전기계측기 ",""]
        
                },

            en: {
                bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                year : "2016",   
                image : "/img/service/nx9g45-ls.png",   
                iWidth: "160px", iHeight:"120px",
                prod: ["Industrial HMI"," (Model: GIMAC-B)"],
                spec:[
                        ["Core : ", "AT91SAM9G45 400MHz"],
                        ["Screen : ", "800x480 Color TFT"],
                        ["I/O : ", "RS-485/RS-422, RS-232, 100T 3-Port Ethernet Phy, SD, USB"],
                        ["Features : : ", "Windows CE 6.0"],
                        ["Desc : ", ""],
                        ["Etc : ", ""],
                    ]     
                ,
                title :   ["Application"],
                apply :   ["Electric measuring equipment",""]
    
                    
                }
            },

   
            // 2015: Wince 계수기  
            {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2015",   
                    image : "/img/service/ee7.jpg",   
                    iWidth: "160px", iHeight:"120px",
                    prod: ["산업용HMI"," (모델명:  EagleEye7)"],
                        spec:[
                            ["Core : ", "AT91SAM9G45 400MHz"],
                            ["Screen : ", "480x272 Color TFT"],
                            ["I/O : ", "RS-422, RS-232, 100T Ethernet Phy, HPI I/F with DSP, SD, USB"],
                            ["Features : : ", "Windows CE 6.0"],
                            ["Desc : ", ""],
                            ["Etc : ", ""],
                        ]     
                    ,
                    title :   ["적용분야"],
                    apply :   ["전자계수기 ",""]
        
                },

            en: {
                bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                year : "2015",   
                image : "/img/service/ee7.jpg",   
                iWidth: "160px", iHeight:"120px",
                prod: ["Industrial HMI"," (Model: EagleEye7)"],
                spec:[
                        ["Core : ", "AT91SAM9G45 400MHz"],
                        ["Screen : ", "480x272 Color TFT"],
                        ["I/O : ", "RS-422, RS-232, 100T Ethernet Phy, HPI I/F with DSP, SD, USB"],
                        ["Features : : ", "Windows CE 6.0"],
                        ["Desc : ", ""],
                        ["Etc : ", ""],
                    ]     
                ,
                title :   ["Application"],
                apply :   ["Counterfelt Detector",""]
    
                    
                }
            },            

  
            // 2013: 전기충전기  
            {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2013",   
                    image : "/img/service/nx320-m.jpg",   
                    iWidth: "160px", iHeight:"120px",
                    prod: ["전기차 충전기 메인보드"," (모델명: NX320-L)"],
                        spec:[
                            ["Core : ", "PXA320 806MHz"],
                            ["Screen : ", "480x272 Color TFT"],
                            ["I/O : ", "RS-485 2 RS232 2 ports, SD, USB"],
                            ["Features : : ", "Windows CE 6.0"],
                            ["Desc : ", ""],
                            ["Etc : ", ""],
                        ]     
                    ,
                    title :   ["적용분야"],
                    apply :   ["차량 진단기","진단기"]
           
                },

               en: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2013",   
                    image : "/img/service/nx320-m.jpg",   
                    iWidth: "160px", iHeight:"120px",
                    prod: ["Transportation card reader", "(Model: NX8925)"],
                    spec:[
                        ["Core : ", "PXA320 806MHz"],
                        ["Screen : ", "480x272 Color TFT"],
                        ["I/O : ", "RS-485 2 RS232 2 ports, SD, USB"],
                        ["Features : : ", "Windows CE 6.0"],
                        ["Desc : ", ""],
                        ["Etc : ", ""],
                    ]     
                 ,
                    title :   ["Application"],
                    apply :   ["Electric car charger","VData display/ terminal"]
                    
                }
            },        
            // 2012: 안드로이드 PDA  
            {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2013",   
                    image : "/img/service/pm260-ics.png",   
                    iWidth: "160px", iHeight:"120px",
                    prod: ["안드로이드 PDA",""],
                        spec:[
                            ["Core : ", "AM2715 1GHz"],
                            ["Screen : ", "240x320 Color TFT"],
                            ["I/O : ", "CDMA/ WiFI/ BT/ Uart/ HW KeyPAD, SD, USB"],
                            ["Features : : ", "Aondroid.4.0"],
                            ["Desc : ", ""],
                            ["Etc : ", ""],
                        ]     
                    ,
                    title :   ["적용분야"],
                    apply :   ["안드로이드 PDA","모바일 바코더스캐너"]
        
                },

            en: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2013",   
                    image : "/img/service/pm260-ics.png",   
                    iWidth: "160px", iHeight:"120px",
                    prod: ["Android PDA",""],
                    spec:[
                        ["Core : ", "AM2715 1GHz"],
                        ["Screen : ", "240x320 Color TFT"],
                        ["I/O : ", "CDMA/ WiFI/ BT/ Uart/ HW KeyPAD, SD, USB"],
                        ["Features : : ", "Aondroid.4.0"],
                        ["Desc : ", ""],
                        ["Etc : ", ""],
                    ]     
                ,
                    title :   ["Application"],
                    apply :   ["Android PDA","Handheld Barcode scanenr"]
                    
                }
            },                          
            // 2012: MDT 
            {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2012",   
                    image : "/img/service/nx2450-mdt.jpg",   
                    iWidth: "160px", iHeight:"120px",
                    prod: ["차량용 데이터 단말기"," (모델명: NX2450)"],
                        spec:[
                            ["Core : ", "S3C2450 533MHz"],
                            ["Screen : ", "800x480 Color TFT"],
                            ["I/O : ", "WiFi/ GPRS / CDMA / GPS, 2 RS-232 4 port, Dual CAN, SD, USB"],
                            ["Features : : ", "Windows CE 6.0"],
                            ["Desc : ", ""],
                            ["Etc : ", ""],
                        ]     
                    ,
                    title :   ["적용분야"],
                    apply :   ["차량용 데이터 터미널 / 차량관제용 단말기 ","모바일데이터터미널(MDT)"]
           
                },

               en: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2012",   
                    image : "/img/service/nx2450-mdt.jpg",    
                    iWidth: "160px", iHeight:"120px",
                    prod: ["Transportation card reader", "(Model: NX8925)"],
                    spec:[
                        ["Core : ", "S3C2450 533MHz"],
                        ["Screen : ", "800x480 Color TFT"],
                        ["I/O : ", "WiFi/ GPRS / CDMA / GPS, 2 RS-232 4 port, Dual CAN, SD, USB"],
                        ["Features : : ", "Windows CE 6.0"],
                        ["Desc : ", ""],
                        ["Etc : ", ""],
                    ]     
                 ,
                    title :   ["Application"],
                    apply :   ["Vechicle Fleet management terminal","MDT"]
                    
                }
            },                         
            // 2011: 진단기  
            {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2011",   
                    image : "/img/service/nx320-wl.png",   
                    iWidth: "160px", iHeight:"120px",
                    prod: ["차량 진단기"," (모델명: NX320-W)"],
                        spec:[
                            ["Core : ", "PXA320 806MHz"],
                            ["Screen : ", "640x480 Color TFT"],
                            ["I/O : ", "USB IF with scope, 2 RS-232 4 port, SD, USB"],
                            ["Features : : ", "Windows CE 6.0"],
                            ["Desc : ", ""],
                            ["Etc : ", ""],
                        ]     
                    ,
                    title :   ["적용분야"],
                    apply :   ["차량 진단기","진단기"]
           
                },

               en: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2011",   
                    image : "/img/service/nx320-wl.png",   
                    iWidth: "160px", iHeight:"120px",
                    prod: ["Transportation card reader", "(Model: NX8925)"],
                    spec:[
                        ["Core : ", "PXA320 806MHz"],
                        ["Screen : ", "640x480 Color TFT"],
                        ["I/O : ", "USB IF with scope, 2 RS-232 4 port, SD, USB"],
                        ["Features : : ", "Windows CE 6.0"],
                        ["Desc : ", ""],
                        ["Etc : ", ""],
                    ]     
                 ,
                    title :   ["Application"],
                    apply :   ["Vechicle scanner","Vechicle Analyzer"]
                    
                }
            },
 
           // 2009: 핸드헬드 바코더스캐너  
           {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2009",   
                    image : "/img/service/nx320-mi.jpg",   
                    iWidth: "160px", iHeight:"120px",
                    prod: ["바코더스캐너"," (모델명: NX320-MI)"],
                        spec:[
                            ["Core : ", "PXA320 806MHz"],
                            ["Screen : ", "240x320 Color TFT"],
                            ["I/O : ", "WiFi/ CDMA / 1D Scaner, SD, USB"],
                            ["Features : : ", "Windows CE 6.0"],
                            ["Desc : ", ""],
                            ["Etc : ", ""],
                        ]     
                    ,
                    title :   ["적용분야"],
                    apply :   ["바코더스캐너","핸디 데이터 터미널"]
        
                },

            en: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2009",   
                    image : "/img/service/nx320-mi.jpg",   
                    iWidth: "160px", iHeight:"120px",
                    prod: ["Handheld Barcode scanner", "(Model:  NX320-MI)"],
                    spec:[
                        ["Core : ", "PXA320 806MHz"],
                        ["Screen : ", "240x320 Color TFT"],
                        ["I/O : ", "WiFi/ CDMA / 1D Scaner, SD, USB"],
                        ["Features : : ", "Windows CE 6.0"],
                        ["Desc : ", ""],
                        ["Etc : ", ""],
                    ]     
                ,
                    title :   ["Application"],
                    apply :   ["Handheld Barcode scanner","Handy data terminal"]
                    
                }
            },

           // 2008: 핸드헬드 결재기  
           {
            ko: {
                bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                year : "2008",   
                image : "/img/service/itw320.png",   
                iWidth: "160px", iHeight:"120px",
                prod: ["산업용PDA"," (모델명: NX320-ITW)"],
                    spec:[
                        ["Core : ", "PXA320 806MHz"],
                        ["Screen : ", "240x320 Color TFT"],
                        ["I/O : ", "WiFi/ CDMA / 1D Scaner, SD, USB"],
                        ["Features : : ", "Windows CE 6.0"],
                        ["Desc : ", ""],
                        ["Etc : ", ""],
                    ]     
                ,
                title :   ["적용분야"],
                apply :   ["바코더스캐너","핸디 데이터 터미널"]
    
            },

            en: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2008",   
                    iimage : "/img/service/itw320.png",     
                    iWidth: "160px", iHeight:"120px",
                    prod: ["Ibnsudtrial PDA", "(Model:  NX320-ITW)"],
                    spec:[
                        ["Core : ", "PXA320 806MHz"],
                        ["Screen : ", "240x320 Color TFT"],
                        ["I/O : ", "WiFi/ CDMA / 1D Scaner, SD, USB"],
                        ["Features : : ", "Windows CE 6.0"],
                        ["Desc : ", ""],
                        ["Etc : ", ""],
                    ]     
                ,
                    title :   ["Application"],
                    apply :   ["Handheld Barcode scanner","Handy data terminal"]
                    
                }
            },

            // 2007: ATM메인보드  
            {
                ko: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2007",   
                    image : "/img/service/nxc2440.jpg",    
                    iWidth: "160px", iHeight:"120px",
                    prod: ["주차관제용 ATM보드"," (모델명: NX2440)"],
                        spec:[
                            ["Core : ", "S3C2440 400MHz"],
                            ["Screen : ", "800x480 Color TFT"],
                            ["I/O : ", "RS-232 8 ports (Opt. 3 ports), Ethernet 10T, SD, USB"],
                            ["Features : : ", "Windows CE 5.0"],
                            ["Desc : ", ""],
                            ["Etc : ", ""],
                        ]     
                    ,
                    title :   ["적용분야"],
                    apply :   ["ATM보드/ 관제용 메인보드","데이터 수집용 메인보드"]
            
                },

                en: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2007",   
                    image : "/img/service/nxc2440.jpg",   
                    iWidth: "160px", iHeight:"120px",
                    prod: ["Industrial ATM", "(Model: NX2440)"],
                    spec:[
                        ["Core : ", "S3C2440 400MHz"],
                        ["Screen : ", "800x480 Color TFT"],
                        ["I/O : ", "RS-232 8 ports (Opt. 3 ports), Ethernet 10T, SD, USB"],
                        ["Features : : ", "Windows CE 5.0"],
                        ["Desc : ", ""],
                        ["Etc : ", ""],
                    ]     
                    ,
                    title :   ["Application"],
                    apply :   ["Industrial ATM","Data gatherer"]
                    
                }
            },
 
           // 2005: SDMB 
           {
            ko: {
                bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                year : "2005",   
                image : "/img/service/cb270.png",    
                iWidth: "160px", iHeight:"120px",
                prod: ["위성DMB 폰",""],
                    spec:[
                        ["Core : ", "PXA270 624MHz"],
                        ["Screen : ", "240x320 Color TFT"],
                        ["I/O : ", "SDMB/ CDMA / SD, USB"],
                        ["Features : : ", "Windows Mobile 4.0"],
                        ["Desc : ", ""],
                        ["Etc : ", ""],
                    ]     
                ,
                title :   ["적용분야"],
                apply :   ["DMB Phone","핸디 데이터 터미널"]
    
            },

            en: {
                    bgcolor : [ "#ddeeee","#ddeeee","#eeeedd" ],
                    year : "2005",   
                    image : "/img/service/cb270.png",    
                    iWidth: "160px", iHeight:"120px",
                    prod: ["SDMB Phone", ""],
                    spec:[
                        ["Core : ", "PXA270 624MHz"],
                        ["Screen : ", "240x320 Color TFT"],
                        ["I/O : ", "SDMB/ CDMA / SD, USB"],
                        ["Features : : ", "Windows Mobile 4.0"],
                        ["Desc : ", ""],
                        ["Etc : ", ""],
                    ]     
                ,
                    title :   ["Application"],
                    apply :   ["DMB Phone","Handy data terminal"]
                    
                }
            },
                                    
        ] ;

        const dstyle = {
            color: "white",
            border: '2px solid #3d52ad',
            backgroundColor: '#eee',
           //  height: "1640px"  // 헤더의 높이가 차감된듯 
          }
        return (
          
        <div className="service_contents"  style={dstyle} >
            <br/>
            { (lang=='ko')? <FormServiceBrief formContent={formContents[0].ko}/> : <FormServiceBrief formContent={formContents[0].en}/>}  
            <br/>
            { (lang=='ko')? <FormServiceBrief formContent={formContents[1].ko}/> : <FormServiceBrief formContent={formContents[1].en}/>}  
            <br/>
            { (lang=='ko')? <FormServiceBrief formContent={formContents[2].ko}/> : <FormServiceBrief formContent={formContents[2].en}/>}  
            <br/>
            { (lang=='ko')? <FormServiceBrief formContent={formContents[3].ko}/> : <FormServiceBrief formContent={formContents[3].en}/>}  
            <br/>
              
            { (lang=='ko')? <FormServiceBrief formContent={formContents[4].ko}/> : <FormServiceBrief formContent={formContents[4].en}/>}  
            <br/>
            { (lang=='ko')? <FormServiceBrief formContent={formContents[5].ko}/> : <FormServiceBrief formContent={formContents[5].en}/>}  
            <br/>
            { (lang=='ko')? <FormServiceBrief formContent={formContents[6].ko}/> : <FormServiceBrief formContent={formContents[6].en}/>}  
            <br/>
            { (lang=='ko')? <FormServiceBrief formContent={formContents[7].ko}/> : <FormServiceBrief formContent={formContents[7].en}/>}  
            <br/>
            { (lang=='ko')? <FormServiceBrief formContent={formContents[8].ko}/> : <FormServiceBrief formContent={formContents[8].en}/>}  
            <br/>
            { (lang=='ko')? <FormServiceBrief formContent={formContents[9].ko}/> : <FormServiceBrief formContent={formContents[9].en}/>}  
            <br/>
            { (lang=='ko')? <FormServiceBrief formContent={formContents[10].ko}/> : <FormServiceBrief formContent={formContents[10].en}/>}  
            <br/>
            { (lang=='ko')? <FormServiceBrief formContent={formContents[11].ko}/> : <FormServiceBrief formContent={formContents[11].en}/>}  
            <br/>
            { (lang=='ko')? <FormServiceBrief formContent={formContents[12].ko}/> : <FormServiceBrief formContent={formContents[12].en}/>}  
            <br/>

        </div>
      );
    }
}

export default ServiceContentHistory;
