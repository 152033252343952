import React from 'react';
import {NavLink,Link} from 'react-router-dom'

//이부분은 (홈화면을 제외한 화면들에서만 적용된다) - 추후 정리한다 
function HTMLHeaderSimpleBanner({lang,sel}) 
{
  var banner_desc =[];
  var pathname = sel.split('/')[1];
  var subIndex = Number(sel.split('/')[3]);
  var index=0;
  
  switch(pathname){
    case 'company': index=1; break;
    case 'product': index=2; break;
    case 'odm':     index=3; break;
    case 'service': index=4; break;
    case 'support': index=5; break;
    default:    break;                
  }
  //debugger;
  if(lang==='en') {
    
     banner_desc =[
      [ //company
        ['"Next Field Solution"', 'Leading company with next field solution ','NEXFIELD SOLUTION CO. Since 2006'],
        ['"Next Field Solution"', 'ODM Manufacturing / SW development / Service ','NEXFIELD SOLUTION CO. Since 2006'],
        ['"Next Field Solution"', 'Welcome to Nexfield ','NEXFIELD SOLUTION CO. Since 2006']
      ],
      [ //product
        ['"Next Field Solution"', 'Custome HMI manufacturing','NEXFIELD SOLUTION CO. Since 2006'],
        ['"Next Field Solution"', 'Embedded products & software Provider','NEXFIELD SOLUTION CO. Since 2006'],
        ['"Next Field Solution"', 'ODM Manufacturing / SW development / Service ','NEXFIELD SOLUTION CO. Since 2006']
      ],banner_desc
      [ //service
        ['"Next Field Solution"', 'Leading company with next field solution ','NEXFIELD SOLUTION CO. Since 2006'],
        ['"Next Field Solution"', 'Linux YOCTO / Android/ Windows CE / Firmwares','NEXFIELD SOLUTION CO. Since 2006'],
        ['"Next Field Solution"', 'Softwares/ Embeded/ Web Service','NEXFIELD SOLUTION CO. Since 2006']
      ],
      [ //support
        ['"Next Field Solution"', 'Leading company with next field solution ','NEXFIELD SOLUTION CO. Since 2006'],
        ['"Next Field Solution"', 'ODM Manufacturing / SW development / Service ','NEXFIELD SOLUTION CO. Since 2006'],
        ['"Next Field Solution"', 'Hardware/Embedded OS/ Applications / IOT & Web service','NEXFIELD SOLUTION CO. Since 2006'],
        ['"Next Field Solution"', 'Inquiry (02) 2057-2422','NEXFIELD SOLUTION CO. Since 2006']
      ]
    ];
  }else{
    
    banner_desc =  [
      [ //company
        ['"Next Field Solution"','차세대 산업용 필드 솔루션의 선두주자 ', '(주)넥스필드솔루션 Since 2006'],
        ['"Next Field Solution"','ODM생산납품 / 소프트웨어개발 / 서비스구축 및 운용', '(주)넥스필드솔루션 Since 2006'],
        ['"Next Field Solution"','환영합니다 ', '(주)넥스필드솔루션 Since 2006']
      ],
      [ //product
        ['"Next Field Solution"','차세대 산업용 필드 솔루션의 선두주자 ', '(주)넥스필드솔루션 Since 2006'],
        ['"Next Field Solution"','ODM생산납품 / 소프트웨어개발 / 서비스구축 및 운용', '(주)넥스필드솔루션 Since 2006'],
        ['"Next Field Solution"','OEM ODM 커스텀 제품 전문 ', '(주)넥스필드솔루션 Since 2006']
      ],
      [ //odm
        ['"Next Field Solution"','차세대 산업용 필드 솔루션의 선두주자 ', '(주)넥스필드솔루션 Since 2006'],
        ['"Next Field Solution"','ODM생산납품 / 소프트웨어개발 / 서비스구축 및 운용', '(주)넥스필드솔루션 Since 2006'],
        ['"Next Field Solution"','OEM ODM 커스텀 제품 전문  ', '(주)넥스필드솔루션 Since 2006']
      ],
      [ // service
        ['"Next Field Solution"','차세대 산업용 필드 솔루션의 선두주자 ', '(주)넥스필드솔루션 Since 2006'],
        ['"Next Field Solution"','리눅스/ 안드로이드/ 윈CE / RTOS/ 펌웨어', '(주)넥스필드솔루션 Since 2006'],
        ['"Next Field Solution"','OEM ODM 커스텀 제품 전문  ', '(주)넥스필드솔루션 Since 2006']
      ],
      [ // support
        ['"Next Field Solution"','차세대 산업용 필드 솔루션의 선두주자 ', '(주)넥스필드솔루션 Since 2006'],
        ['"Next Field Solution"','ODM생산납품 / 소프트웨어개발 / 서비스구축 및 운용', '(주)넥스필드솔루션 Since 2006'],
        ['"Next Field Solution"', '하드웨어/임베디드 OS/ 각종 응용 / IOT 웹서비스 구축','NEXFIELD SOLUTION CO. Since 2006'],
        ['"Next Field Solution"', '기술문의 (02) 2057-2422','NEXFIELD SOLUTION CO. Since 2006']
      ]
    ];
   }
   //debugger;

  return (    
  //  슬릭베너에 대한 디스크립션
    <div className="banner_desc" >
        <em>{banner_desc[index-1][subIndex-1][0]}</em>
        <strong>{banner_desc[index-1][subIndex-1][1]}</strong>
        <span>{banner_desc[index-1][subIndex-1][2]}</span>
    </div>
  );
}
export default HTMLHeaderSimpleBanner;